import logoIncluia from '../../../../assets/img/prosperia_4.png'
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Typography
} from '@mui/material';

export const MuiDivPaper = styled('div')(({ theme }) => ({
  width:'70%',
  margin: theme.spacing(10, 14.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const MuiDivLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  backgroundImage: `url(${logoIncluia})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
}));

export const MuiTypographyForgot = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontSize: '26px',
  fontWeight: 'bold',
  textAlign: 'start'
}));

export const MuiLoginText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: '45px',
  color: 'rgba(0, 187, 212, 1)',
  fontFamily: 'Raleway',
  fontWeight:600,
  textTransform:'uppercase'
}));

export const MuiLoginSubtitle = styled(Typography)(({ theme }) => ({
  maxWidth:'62%',
  fontSize: '21px',
  fontFamily: 'Raleway',
  fontWeight:500,
  width: '62%',
  textAlign: 'center',
  lineHeight: '20px',
  marginBottom: '38px'
}));

export const MuiForgotContent = styled(Typography)(({ theme }) => ({
  width: '312px',
  fontSize:'12px',
  fontFamily: 'Montserrat',
  textAlign:'justify'
}));

export const MuiBox = styled(Box)(({ theme }) => ({
  display:'flex',
  justifyContent: 'flex-start',
  width: '100%',
  marginBottom: '16px'
}));

export const MuiButtonSubmit = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isValid'
})(({ theme,isValid }) => ({
  backgroundColor: !isValid ? 'rgba(0, 187, 212, .5)':'rgba(0, 187, 212, 1)',
  borderRadius: '20px',
  width: '312px',
  height: '40px',
  color: 'white !important',
  fontSize: '14px',
  fontFamily:'Montserrat',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: 'rgba(0, 150, 170, 1)',
  },
}));

export const MuiButtonForgot = styled(Button)(({ theme }) => ({
  color: 'rgba(103, 147, 165, 1)',
  fontSize: '12px',
  fontFamily:'Montserrat',
  cursor: 'pointer',
  marginLeft: '4px',
  padding: theme.spacing(0),
  textTransform: 'none',
  fontWeight: 400
}));

export const MuiGridEmail = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom:'0px !important',
  paddingTop:'12px !important'
}));

export const MuiLoginGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const MuiGridLayout = styled(Grid)(({ theme }) => ({
  height: '100vh',
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  bottom: '45px',
  left: '85%',
  color: '#6793A5'
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  right: '10%',
  top: '28px'
}));

export const orStyle = {
  marginTop:'3px',
  marginBottom:'12px',
};

export const orTextStyle = {
  fontFamily: 'Raleway',
  fontSize: '16px'
};

export const containerButtonsStyle = {
  display: 'flex',
  gap: '12px',
  marginTop: '6px'
};

export const socialIcon = {
  position: 'absolute',
  left: '12px',
  top: '8px'
};

export const loginField = {
  border: '5px',
  width: '312px',
  height: '56px',
  '& .MuiInputLabel-root':{
    fontFamily:'Montserrat'
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Montserrat',
  },
  '& .MuiFormHelperText-root': {
    fontFamily: 'Montserrat',
  },
};

export const containerButtonsError = {
  display: 'flex',
  gap: '12px',
  marginTop: '36px',
};

export const socialButton = {
    textTransform: 'none',
    color: '#212121',
    border: '1px solid #6793A5',
    "&:hover": {
      backgroundColor: '#F6FEFF',
      border: '1px solid #6793A5',
    },
    paddingY: '12px 64px',
    marginBottom: '9px',
    display: 'flex',
    borderRadius: '20.5px',
    width: '312px',
    height: '40px',
    fontSize: '13px',
    fontFamily: 'Montserrat',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  };

