import { styled, Typography, Button } from '@mui/material';

export const MuiCommonButtonAdd = styled(Button)(({ theme }) => ({
  margin: '8px 0px',
  borderColor:'#6793A5',
  borderRadius:15,
  height:'30px',
  width:'147px',
  padding:'4px 12px 4px 8px',
  '& .MuiButton-startIcon':{
    marginRight:'4px !important'
  }
}));

export const MuiCommonTypographyAdd = styled(Typography)(({ theme }) => ({
  color: '#005F64',
  textAlign:'center',
  fontFamily: 'Montserrat',
  fontSize: 11,
  fontWeight: 500,
  letterSpacing: 0.25
}));

export const iconCommonAddStyles = {
  color:'#00828F',
  height:'18px',
  width:'18px',
}
