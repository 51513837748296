import { ThemeProvider, ToggleButton} from '@mui/material';

import themeIncluia from 'theme/incluia'
import {
  groupedHorizontalStyle, MuiToggleButton,
  MuiToggleButtonGroup, selectedStyle, toggleButtonStyle
} from './styles/customToggleButton';

const CustomToggleButton = ({ handleValues, values, toggleItems, exclusive=false  }) => {
  return (
    <ThemeProvider theme={themeIncluia}>
      <MuiToggleButtonGroup
      value={values}
      onChange={handleValues}
      exclusive={exclusive}
      >
          { toggleItems.map( btn => (
              <MuiToggleButton value={btn.value}>
                  { btn.icon }
              </MuiToggleButton>
          )) }
      </MuiToggleButtonGroup>
    </ThemeProvider>
  );
};

export default CustomToggleButton;
