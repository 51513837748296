import { IconButton, styled } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GLOBAL } from '../../../../utils/constants';

export const MuiIconButton = styled(IconButton,{
  shouldForwardProp: (prop) => prop !== 'enabled'
})(({ theme,enabled }) => ({
  color: enabled ? GLOBAL.COLOR_THEME.PRIMARY : GLOBAL.COLOR_THEME.SECONDARY,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  padding: '0px !important'
}));

export const MuiArrowDropDownIcon = styled(ArrowDropDownIcon,{
  shouldForwardProp: (prop) => prop !== 'enabled'
})(({ theme,enabled }) => ({
  color: enabled ? GLOBAL.COLOR_THEME.PRIMARY : GLOBAL.COLOR_THEME.SECONDARY,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  padding: '0px !important'
}));
