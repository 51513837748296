import { Button, Container, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../../../utils/constants';
import { generateDynamicShades } from '../../../../../utils/colors';

export const MuiContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: 10
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  alignSelf: 'center'
}));

export const MuiVariableName = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  margin: 'auto',
  marginLeft: 4,
  width: 70,
  overflow: 'hidden',
  wordWrap: 'break-word'
}));

export const MuiSwitchTypography = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  margin: 'auto',
}));

export const MuiBtnCancel = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  color: generateDynamicShades('#005F64')[900]
}));

export const MuiBtnAccept = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  color: generateDynamicShades('#005F64')[900],
  border: `1px solid ${generateDynamicShades('#005F64')[400]}`,
  '&:hover': {
    backgroundColor: generateDynamicShades('#005F64')[50],
  },
}));

export const customSwitchLabelStyle = {
  marginRight: '10px !important',
  '& .MuiTypography-root': {
    fontFamily: 'Montserrat !important',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19.5px',
    letterSpacing: '0.5px',
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
  }
};

export const marginStyle = {
  margin: 'auto',
};

export const generateFormModalStyle = {
  '& .Component-root': {
    paddingLeft: '24px !important',
  }
};
