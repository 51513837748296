import * as React from 'react';
import { Button } from '@mui/material';
import MuiCircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'components/providers/TranslationProvider';
import PublicIcon from '@mui/icons-material/Public';
import {
  iconCommonStyles,
  MuiActionTypography, MuiGrid,
  MuiSaveButton,
  saveAndViewButtonStyles
} from './styles/actions';
import Loader from "../../common/Loader";

const Actions = ({ isLoading, handleSave, handleSaveAndView, isValid, isDirty }) => {
  const { t } = useTranslation();

  return (
    <MuiGrid item xs={12} >
      <Loader
        loading={isLoading}
        loaderType={'infinite'}
        notificationMessage={t('saving_map_info')}
        titleMessage={t('saving_map')}
      />
      <MuiSaveButton
        onClick={handleSave}
        variant='contained'
        startIcon={isLoading ? <MuiCircularProgress size={20} /> : <SaveIcon style={iconCommonStyles} />}
        disabled={!isValid || isLoading || !isDirty}
        data-cy = {'button_save_map'}
      >
        <MuiActionTypography> {t('save_changes')} </MuiActionTypography>
      </MuiSaveButton>
      <Button
        onClick={handleSaveAndView}
        style={{
          ...saveAndViewButtonStyles,
          width: !isDirty ? '155px' : '195px',
        }}
        variant='contained'
        color='primary'
        startIcon={isLoading ? <MuiCircularProgress size={20} /> : <PublicIcon style={iconCommonStyles} />}
        disabled={!isValid || isLoading}
      >
        {isDirty ? (<MuiActionTypography> {t('save_and_view')} </MuiActionTypography>) :
                   (<MuiActionTypography> {t('view_map')} </MuiActionTypography>)}
      </Button>
    </MuiGrid>
  );
};

export default Actions;
