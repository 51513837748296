import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'components/providers/TranslationProvider';
// eslint-disable-next-line import/no-cycle
import TokenInterceptor from 'components/common/TokenInterceptor';
import axios from '../../api/axios/axiosInstance';
import apiPaths from '../../api/apiPaths';
import { status200 } from '../../api/status.utils';
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { GLOBAL, providerCodes } from '../../utils/constants';
import CartoPiaAuthProvider from "./CartoPiaAuthProvider";
const auth = getAuth();

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  auth.useDeviceLanguage();
  const [isPending, setIsPending] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isPendingAuth, setIsPendingAuth] = React.useState(true);
  const [accessToken, setAccessToken] = React.useState(
    localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : ''
  );

  const providerMicrosoft = new OAuthProvider('microsoft.com');
  const providerGoogle = new GoogleAuthProvider();
  providerGoogle.setCustomParameters({
    prompt: 'select_account'
  });
  providerMicrosoft.setCustomParameters({
    prompt: 'select_account'
  })

  const navigate = useNavigate();
  const { setForceFetch } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);

  const addUser = (user) => {
    const { _id: value, ...restUser } = user;

    const userNewReplacedId = { ...restUser, id: value };
    localStorage.setItem('user', JSON.stringify(userNewReplacedId));
  };

  const getUser = React.useCallback(() => JSON.parse(localStorage.getItem('user')), []);

  const removeUser = () => localStorage.removeItem('user');

  const addTokens = (accToken, refreshToken) => {
    localStorage.setItem('accessToken', accToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  const getAccessToken = React.useCallback(() => localStorage.getItem('accessToken'), []);

  const getRefreshToken = React.useCallback(() => localStorage.getItem('refreshToken'), []);

  const removeTokensInLocalStorage = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  const login = React.useCallback(
    (data, redirectUrl, onError) => {
      removeTokensInLocalStorage();
      setIsPending(true);
      axios
        .post(apiPaths.login, data, status200)
        .then((response) => {
          const { accessToken: accToken, refreshToken, user } = response.data;
          if (user.added_by_admin) {
            setIsPending(false);
            return navigate('/reset', { state: { accessToken: accToken, user } });
          }
          addUser(user);
          addTokens(accToken, refreshToken);
          setAccessToken(accToken);
          setIsAuthenticated(true);
          setIsPending(false);
          navigate(user.type === 'admin' || user.type == 'super_admin' ||
                  user.non_admin_users_orgs_ids_access_permissions.length !== 0 ? '/orgs' :
                  user.type === 'level_0' ? redirectUrl : `/orgs/${user.org_id}/maps`);
          setForceFetch();
          return null;
        })
        .catch((e) => {
          // eslint-disable-next-line no-unused-expressions
          onError && onError(e?.data?.error || 'error');
          setIsPending(false);
        });
    },
    [navigate, setForceFetch, removeTokensInLocalStorage]
  );

  const updateTokens = React.useCallback(async () => {
    return axios
      .post(apiPaths.refreshToken, { refreshToken: getRefreshToken() })
      .then((resp) => {
        removeTokensInLocalStorage();
        addTokens(resp.data.accessToken, resp.data.refreshToken);
        return true;
      })
      .catch(() => {
        removeTokensInLocalStorage();
        removeUser();
        return false;
      });
  }, [getRefreshToken]);

  const logout = React.useCallback(() => {
    setIsAuthenticated(false);
    removeTokensInLocalStorage();
    setAccessToken(null);
    removeUser();
    navigate('/login');
  }, [navigate]);

  const forgotPassword = React.useCallback((data, onError, onSuccess) => {
    setIsPendingAuth(true);
    axios
      .post(apiPaths.forgotPassword, data, status200)
      .then((resp) => {
        setIsPendingAuth(false);
        onSuccess();
        return resp.data;
      })
      .catch((e) => {
        setIsPendingAuth(false);
        onError(e.data.error);
      });
  }, []);

  const loginWithSocial = React.useCallback(
    (provider, redirectUrl, onError) => {
      setIsPending(true);
      let signInWith = null

      if (provider === providerCodes.MICROSOFT) {
        signInWith = signInWithPopup(auth, providerMicrosoft)
      } else {
        signInWith = signInWithPopup(auth, providerGoogle)
      }

      signInWith.then((result) => {
          let credential = null;

          if (provider === providerCodes.MICROSOFT) {
            credential = OAuthProvider.credentialFromResult(result)
          } else {
            credential = GoogleAuthProvider.credentialFromResult(result)
          }

          const token = credential.accessToken;
          const data = { token, user: result.user, provider };
          axios
            .post(apiPaths.loginSocial, data, status200)
            .then((response) => {
              const { accessToken: accToken, refreshToken, user } = response.data;
              addUser(user);
              addTokens(accToken, refreshToken);
              setAccessToken(accToken);
              setIsAuthenticated(true);
              setIsPending(false);
              navigate(user.type === 'admin' || user.type == 'super_admin' ? '/orgs' : `/orgs/${user.org_id}/maps`);
              setForceFetch();
              return null;
            })
            .catch((e) => {
              if (e.data.error === 'login_user_not_allowed') {
                setOpenDialog(true)
              } else {
                onError && onError(e?.data?.error || 'error');
              }

              setIsPending(false);
            });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          let credential = null;

          if (provider === providerCodes.MICROSOFT) {
            credential = OAuthProvider.credentialFromResult(error)
          } else {
            GoogleAuthProvider.credentialFromError(error);
          }

          setIsPending(false);
          onError(error.message);
        });
    },
    [navigate, setForceFetch]
  );

  React.useEffect(() => {
    setIsAuthenticated(Boolean(getAccessToken()));
    setIsPendingAuth(false);
    setIsPending(false);
  }, [getAccessToken]);

  const value = React.useMemo(
    () => ({
      login,
      logout,
      forgotPassword,
      isAuthenticated,
      isPending,
      isPendingAuth,
      getAccessToken,
      getRefreshToken,
      updateTokens,
      user: getUser(),
      loginWithSocial,
      openDialog,
      setOpenDialog
    }),
    [
      login,
      logout,
      isAuthenticated,
      isPending,
      isPendingAuth,
      forgotPassword,
      getAccessToken,
      getRefreshToken,
      updateTokens,
      getUser,
      loginWithSocial,
      openDialog,
      setOpenDialog,
    ]
  );

  return (
    <AuthContext.Provider value={value}>
      <CartoPiaAuthProvider>
      <TokenInterceptor accessToken={accessToken}>{children}</TokenInterceptor>
      </CartoPiaAuthProvider>
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthContext, useAuth };
export default AuthProvider;
