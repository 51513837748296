/* eslint-disable react/require-default-props */
import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setViewState, selectSourceById } from 'lib/react-geo-tool/packages/react-redux/src/';

import ListWidgetUI from './ListWidgetUI';
import  WrapperWidgetUI  from '../WrapperWidgetUI';
import WidgetWithAlert from '../sources-carto/WidgetWithAlert';
import {BASEMAPS} from "lib/react-geo-tool/packages/react-basemaps/src/basemaps/basemaps";
import { getList } from 'lib/react-geo-tool/packages/react-widgets/src/models';
import useWidgetFetch from 'lib/react-geo-tool/packages/react-widgets/src/hooks/useWidgetFetch';

/**
 * Renders a <ListWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {Object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default)
 */

function ListWidget(props) {
  const {
    id,
    title,
    datasetName,
    wrapperProps,
    dataSource,
    columns,
    global,
    noDataAlertProps,
    droppingFeaturesAlertProps,
  } = props;
  const source = useSelector((state) => selectSourceById(state, dataSource) || {});
  const { filters } = source;
  const dispatch = useDispatch();
  const basemap = useSelector((state) => BASEMAPS[state.carto.basemap]);
  const activeWidget = useSelector((state) => state.app.selectWidgetId);
  const spatialFilters = useSelector((state) => state.app.listFilterPolygonData);
  const widgets = useSelector((state)=> state.app.widgets);
  const sources = useSelector((state)=> state.carto.dataSources);
  const [operator, setOperator] = useState('and');
  const [spatialFilter , setSpatialFilter] = useState();
  const parametersCatalog = useSelector((state) => state.app.parametersCatalog);

  let localParametersCatalog = null;
  if (parametersCatalog?.length > 0) {
    localParametersCatalog = {};
    parametersCatalog.forEach(p => {
      columns.forEach(col => {
        if (col.field.includes(p.propName) || (filters && Object.keys(filters).length >0)) {
          localParametersCatalog[p.propName] = p.value
        }
      })
    });
  }

  const transformedFilters = useMemo(() => {
    if (filters) {
      const newFilters = { ...filters };
      Object.keys(filters).forEach(keyProp => {
        if (newFilters[keyProp].between || newFilters[keyProp].in) {
          const type = newFilters[keyProp].between ? 'between' : 'in';
          const column = widgets.find((w) => w.id === newFilters[keyProp][type].owner).params.field;
          if (keyProp !== column){
            newFilters[column] = newFilters[keyProp];
            delete newFilters[keyProp];
          }
        }
      });
      return newFilters;
    }
    return filters;
  }, [source, widgets]);

  const columnsToFetch = columns.map(col => {
    if (col.calculator) {
      return { ...col, formula: col.field };
    } else if (!col.calculator) {
      return { ...col, alias: col.uniqueName==undefined? col.field : col.uniqueName };
    } else {return { ...col, alias: col.field };}
  });

  const {
      data: listData,
      isLoading,
    } = useWidgetFetch(getList, {
      id,
      dataSource,
      spatialFilter,
      params: {
        columns: [
            ...columnsToFetch,
            ...['geom'].map((c) => ({
              name: c,
              visible: false,
            })),
          ],
        parametersCatalog: localParametersCatalog
      },
      global,
      attemptRemoteCalculation: global,
      enabled: wrapperProps.expand
    });

  const zoomLevel = useSelector((state) => Math.floor(state.carto.viewState.zoom));

  const handleSelect = React.useCallback(
    ({ lat: latitude, lon: longitude }) => {

      if (basemap === 'positron' || basemap === 'voyager' || basemap === 'dark-matter') {
        dispatch(
          setViewState({
            zoom: 13,
            latitude,
            longitude,
          })
        )
      } else {
        let counter = 1;

        if (zoomLevel > 13) {
          dispatch(
            setViewState({
              zoom: 13,
            })
          );
        }

        if (zoomLevel > 10) {
          setTimeout(() => {
            dispatch(
              setViewState({
                zoom: 10
              })
            );
          }, counter * 450);
          counter += 1;
        }

        if (zoomLevel > 7) {
          setTimeout(() => {
            dispatch(
              setViewState({
                zoom: 7
              })
            );
          }, counter * 480);
          counter += 1;
        }

        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 9,
              latitude,
              longitude,
            })
          );
        }, counter * 510);
        counter += 1;

        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 11,
              latitude,
              longitude,
            })
          );
        }, counter * 540);
        counter += 1;

        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 13,
              latitude,
              longitude,
            })
          );
        }, counter * 575);
        counter += 1;

        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 16,
              latitude,
              longitude,
            })
          );
        }, counter * 610);
      }
    },
    [dispatch, zoomLevel]
  );

  React.useEffect(() => {
    if (activeWidget) {
      const key = `${activeWidget}-${(location.pathname.split('/'))[6]}`;
      if (spatialFilters && key in spatialFilters) {
        if(widgets.find(w => w.id === activeWidget).type=== 'recommendations'){
          setSpatialFilter({...spatialFilters[key], filterType : 'recommendation'});
        }else if ((widgets.find(w => w.id === activeWidget).type=== 'polygon')){
          setSpatialFilter({...spatialFilters[key], filterType : 'polygon'});
        }
      }
      else{
        setSpatialFilter();
      }
    }else{
      setSpatialFilter();
    }
  }, [activeWidget, spatialFilters]);

  React.useEffect(() => {
    widgets?.forEach((w) => {
      if(w.id === id) {
        if (sources[w.layer]?.filtersLogicalOperator) {
          setOperator(sources[w.layer].filtersLogicalOperator);
        }
      }
    });
  },[sources]);

  return (
  <WrapperWidgetUI title={title} widgetId={id} isLoading={isLoading} {...wrapperProps}>
    <WidgetWithAlert
      dataSource={dataSource}
      global={global}
      droppingFeaturesAlertProps={droppingFeaturesAlertProps}
      noDataAlertProps={noDataAlertProps}
    >
      <ListWidgetUI data={listData} id={id} onSelect={handleSelect} />
    </WidgetWithAlert>
  </WrapperWidgetUI>
  );
}

ListWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string.isRequired,
  column: PropTypes.string,
  onError: PropTypes.func,
  wrapperProps: PropTypes.object,
  global: PropTypes.bool,
  noDataAlertProps: PropTypes.object,
  droppingFeaturesAlertProps: PropTypes.object,
};

ListWidget.defaultProps = {
  tooltip: true,
  wrapperProps: {},
  global: false,
  noDataAlertProps: {}
};

export default ListWidget;
