import { CssBaseline, Grid, makeStyles } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {useLocation} from "react-router-dom";

import Header from 'components/common/Header';
import TranslationProvider from 'components/providers/TranslationProvider';
import CustomThemeProvider from 'components/providers/CustomThemeProvider';
import LayersProvider from 'components/providers/LayersProvider';
import AuthProvider from 'components/providers/AuthProvider';
import Views from 'components/common/Views';
import './index.css';
import CartoPiaAuthProvider from 'components/providers/CartoPiaAuthProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex = 3) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF'
  },
}));

const App = () => {
  const location = useLocation();
  const NoHeaderRoutes = ["/login", "/reset", "/forgot-password", "/"];
  const showHeader = !NoHeaderRoutes.includes(location.pathname);

  const classes = useStyles();
  return (
    <CustomThemeProvider>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <TranslationProvider>
          <AuthProvider>
              <Grid container direction='column' className={classes.root}>
                {showHeader && <Header />}
                <LayersProvider>
                  <Views />
                </LayersProvider>
              </Grid>
              <ReactQueryDevtools initialIsOpen />
          </AuthProvider>
        </TranslationProvider>
      </QueryClientProvider>
    </CustomThemeProvider>
  );
};

export default App;
