import { GLOBAL } from 'utils/constants';

const styles = (theme) => {
    return ({
    elementContainer: {
        padding: theme.spacing(0, 1.5),
    },
    removeGridPadding: {
        paddingLeft: 0,
    },
    displayFlex: {
        display: 'flex'
    },
    typographyWithInfoIconTooltipStyles: {
        paddingTop: 5,
        paddingBottom: 23,
        margin: 'auto',
    },
    selectSkeleton: {
        marginTop: -16,
    },
    sectionTitle: {
        fontFamily: 'Raleway',
        fontWeight: 400,
        fontSize: 20,
    },
    priorityVariable: {
        marginRight: 8,
        marginLeft: 24,
    },
    root: {
        width: 153,
        height: 32,
        marginLeft:8,
        padding: 0,
        display: 'flex',
        '&:active $thumb': {
          width: 67,
        },
        '&:active $switchBaseChecked': {
          transform: 'translateX(9px)',
        },
      },
      switchBase: {
        color:theme.palette.primary.main,
        padding: 2,
        '&$checked': {
          transform: 'translateX(74px)',
          color: theme.palette.primary.main,
          '&$checked + $track': {
            border: '1px solid',
            background: '#DFF2FC',
            borderColor: theme.palette.primary.main,
          },
        },
      },
      thumbIsFormulaFieldSelected: {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 67,
        height: 24,
        borderRadius: 12,
        marginTop:2,
        marginLeft:4,
        transition: theme.transitions.create(['width'], {
          duration: 200,
        }),
        '&:before': {
          content: ({ isFormulaFieldSelected, t }) =>  `"${!isFormulaFieldSelected ? t('columns') : t('formules')}"`,
          color: 'white',
          position: 'absolute',
          left: ({ isFormulaFieldSelected }) => !isFormulaFieldSelected ? 12 : 15,
          top: '50%',
          transform: 'translateY(-50%)',
          fontSize: 10,
        },
      },
      track: {
        background: '#DFF2FC',
        opacity: 1,
        borderRadius: '20px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        boxSizing: 'border-box',
        position: 'relative',
        '&:before': {
          content: ({ t }) => `"${t('columns')}"`,
          color:theme.palette.primary.main,
          position: 'absolute',
          left: 12,
          top: '50%',
          transform: 'translateY(-50%)',
          fontSize: 10,
        },
        '&:after': {
          content: ({ t }) => `"${t('formules')}"`,
          position: 'absolute',
          right: 15,
          top: '50%',
          transform: 'translateY(-50%)',
          fontSize: 10,
          color:theme.palette.primary.main,
        },
    },
    checked: {},
    marginAuto: {
        margin: 'auto'
    },
    boxSizingContentBox: {
        boxSizing: 'content-box',
    },
    sliderControl: {
      display: ({ flexbox }) => flexbox,
      justifyContent: 'space-between',
    },
    itemTopSpacer30: {
      marginTop: 30
    },
    currentOffer: {
      marginBottom: 42,
    },
    offerSelectorContainer: {
      marginBottom: 15,
      display: 'flex',
    },
    textFieldSeparator: {
      marginBottom: 24,
    },
    labelSeparator: {
      marginRight: 12,
    },
    removePaddingsX: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    layerName: {
      width: 541,
      height: 56,
    },
    itemsSeparator: {
      marginLeft: 16,
      marginRight: 16,
    },
    colorSystem: {
      fontSize: 16,
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
    },
    recommendedAreaLayerLabel: {
      marginRight: 34
    },
    visibilityIconSize: {
      width: 16,
      height: 16,
    },
    offerSubHeader: {
      fontFamily: 'Raleway',
      fontWeight: 400,
      fontSize: 20,
    },
    currentOfferAreaLayerLabel: {
      marginRight: 60
    },
    layerOpacity: {
      width: 123,
      height: 56,
      borderRadius: '4px 4px 0px 0px',
      marginRight: 16,
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '43%',
      }
    },
    isGlobalSwitchSpacing: {
      marginTop: 10
    },
    allowsRegenerateInput: {
      marginLeft: 39,
      marginRight: 8,
    },
    isGlobalSwitch: {
      marginLeft: '1rem'
    },
})};
export default styles;