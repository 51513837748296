import * as React from 'react';
import { IconButton, Button } from '@mui/material';
import Modal from 'components/common/Modal';
import { useQueryClient } from 'react-query';
import InfoIcon from '@material-ui/icons/Info';
import { useLocation } from 'react-router-dom';
import useOrganization from 'api/hooks/useOrganization';
import { GLOBAL } from 'utils/constants';

const OrgDescription = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const hashOrgAndScenario = Boolean(
    location.pathname.split('/')[1] === 'orgs' && location.pathname.split('/')[2]
  );

  const hashPublic = location.pathname.split('/')[1] === 'public';
  const id = location.pathname.split('/')[2];

  const { refetch } = useOrganization(id, hashOrgAndScenario);

  const queryClient = useQueryClient();

  const schemeData = queryClient.getQueryData(['scheme', location.pathname.split('/')[6]]);

  React.useEffect(() => {
    if (schemeData && !hashPublic) refetch();
  }, [schemeData, refetch, hashPublic]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const actions = (
    <>
      <Button
        onClick={handleClose}
        color={GLOBAL.COLOR.PRIMARY}
        variant={GLOBAL.VARIANTS.TEXT}
        focusRipple
      >
        Cancel
      </Button>
    </>
  );

  function createMarkup() {
    return { __html: schemeData?.scheme?.richDescription };
  }

  function MyComponent() {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={createMarkup(schemeData?.scheme?.richDescription)} />;
  }

  const contentModal = (richDescription) => <>{MyComponent(richDescription)}</>;

  return (
    <>
      {schemeData?.scheme?.richDescription && (
        <>
          <IconButton color={GLOBAL.COLOR.INHERIT} aria-label={GLOBAL.ARIALABEL.MENU} onClick={handleOpen}>
            <InfoIcon />
          </IconButton>
          <Modal open={open} onClose={handleClose} title={GLOBAL.TITLE.MAPINFO} isDelete actions={actions}>
            {contentModal()}
          </Modal>
        </>
      )}
    </>
  );
};

export default OrgDescription;
