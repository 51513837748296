import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import themeIncluia from 'theme/incluia'
import { useTheme } from 'components/providers/CustomThemeProvider';

const DialogTitle = (
  ({ children, classes, onClose, isDelete, ...props }) => (
    <MuiDialogTitle disableTypography {...props}>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <Typography
          align='left'
          variant='h5'
          style={{marginTop:'5px'}}
        >
          {children}
        </Typography>
        {onClose ? (
          <IconButton aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  )
);

const Modal = ({ open, onClose, title, children, actions, isDelete,
                 isWidgetForm = false,width = 'xs',
                 widthInPixels=null, heightInPixels= null }) => {
  const { theme } = useTheme();
  return (
    <ThemeProvider theme={themeIncluia}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={width}
        fullWidth
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        PaperProps={{
          style: {
            borderRadius: 28,
            width: widthInPixels,
            height: heightInPixels !== null ? heightInPixels : 'auto',
            maxHeight: heightInPixels !== null ? heightInPixels : 'auto',
            maxWidth: widthInPixels,
          },
        }}
      >
        <DialogTitle isDelete={isDelete} onClose={isWidgetForm ? onClose : false} id='scroll-dialog-title'>
          {title}
        </DialogTitle>
        <ThemeProvider theme={theme}>
          <DialogContent dividers style={{ maxHeight: 580, padding: '0px 16px 0px 16px', borderTop: 'none', borderBottom: 'none' }}>
            {children}
          </DialogContent>
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <DialogActions style={{padding: '16px 16px'}}>{actions}</DialogActions>
        </ThemeProvider>
      </Dialog>
    </ThemeProvider>
  );
};

export default Modal;
