import { Grid, IconButton, styled, Switch, Typography } from '@mui/material';
import {GLOBAL} from "../../../../../../../../utils/constants";
import { lighten } from '@mui/material/styles';


export const MuiSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'translation' && prop !== 'index'
})(({ theme, translation, index  }) => ({
  width: 88,
  height: 32,
  marginLeft: 8,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 12,
  },
  '&:active .Mui-checked': {
    transform: 'translateX(9px)',
  },
  '.MuiSwitch-switchBase': {
    color: GLOBAL.COLOR_THEME.PRIMARY,
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(40px)',
      color: GLOBAL.COLOR_THEME.PRIMARY,
      '& + .MuiSwitch-track': {
        border: '1px solid',
        backgroundColor: lighten( GLOBAL.COLOR_THEME.PRIMARY, 0.9),
        borderColor: GLOBAL.COLOR_THEME.PRIMARY,
      },
    },
  },
  '.MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 36,
    height: 24,
    borderRadius: 12,
    marginTop: 2,
    marginLeft: 4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&:before': {
      content: `"${index === 1 ? translation('key_yes') : translation('key_no')}"`,
      color: 'white',
      position: 'absolute',
      left: 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
  },
  '.MuiSwitch-track': {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: 20,
    border: '1px solid',
    borderColor: GLOBAL.COLOR_THEME.PRIMARY,
    backgroundColor: lighten( GLOBAL.COLOR_THEME.PRIMARY, 0.9),
    boxSizing: 'border-box',
    position: 'relative',
    '&:before': {
      content: `"${translation('key_no')}"`,
      color: GLOBAL.COLOR_THEME.PRIMARY,
      position: 'absolute',
      left: 12,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
    '&:after': {
      content: `"${translation('key_yes')}"`,
      position: 'absolute',
      right: 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
      color: GLOBAL.COLOR_THEME.PRIMARY,
    },
  },
  '.MuiSwitch-thumbChecked': {
    color: '#FFFFFF',
  },
}));

export const MuiSwitchType = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'type'
})(({ theme, type, translation }) => ({
  width: 153,
  height: 32,
  marginLeft: 8,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 12,
  },
  '&:active .Mui-checked': {
    transform: 'translateX(9px)',
  },
  '.MuiSwitch-switchBase': {
    color: GLOBAL.COLOR_THEME.PRIMARY,
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(74px)',
      color: GLOBAL.COLOR_THEME.PRIMARY,
      '& + .MuiSwitch-track': {
        border: '1px solid',
        background: lighten( GLOBAL.COLOR_THEME.PRIMARY, 0.9),
        borderColor: GLOBAL.COLOR_THEME.PRIMARY,
      },
    },
  },
  '.MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 67,
    height: 24,
    borderRadius: 12,
    marginTop: 2,
    marginLeft: 4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&:before': {
      content: `"${type === 'Numeric' ? translation('numeric') : translation('boolean')}"`,
      color: 'white',
      position: 'absolute',
      left: type === 'Numeric' ? 12 : 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
  },
  '.MuiSwitch-track': {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: 20,
    border: '1px solid',
    borderColor: GLOBAL.COLOR_THEME.PRIMARY,
    backgroundColor: lighten( GLOBAL.COLOR_THEME.PRIMARY, 0.9),
    boxSizing: 'border-box',
    position: 'relative',
    '&:before': {
      content: `"${translation('numeric')}"`,
      color: GLOBAL.COLOR_THEME.PRIMARY,
      position: 'absolute',
      left: 12,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
    '&:after': {
      content: `"${translation('boolean')}"`,
      position: 'absolute',
      right: 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
      color: GLOBAL.COLOR_THEME.PRIMARY,
    },
  },
  '.MuiSwitch-thumbChecked': {
    color: '#FFFFFF',
  },
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent', // Replace with your desired hover color
  },
}));

export const OutlinedContainer = styled(Grid)(({ theme }) => ({
  height: '88px',
  width: '940px',
  borderRadius: '12px',
  border: '1px dashed #E0E0E0',
  marginTop: '20px',
}));

export const MuiTypographyCommon= styled(Typography)(({ theme }) => ({
  fontSize:12,
  fontFamily:'Montserrat',
  fontWeight:400,
  marginTop:'-20px'
}));

export const MuiNewParameter = styled(Typography)(({ theme }) => ({
  marginLeft:'16px',
  fontFamily:'Raleway',
  fontWeight:500,
  fontSize:21,
  color:'#757575',
  textTransform:'uppercase'
}));

export const proNameFieldStyle = {
  display:'flex'
}

export const nameFieldStyle = {
  width: '204px'
}

export const parameterTypeStyle = {
  width: '175px'
}

export const commonFieldStyle = {
  width: '144px'
}

export const formTypeStyle = {
  width: '100%'
}

export const formValueStyle = {
  width: '416px'
}

export const labelStyle = {
  color: '#424242'
}
export const outlinedStyle = {
  height:'112px',
  width:'940px'
}

export const dragStyles = {
  cursor: 'row-resize',
  color:'#757575',
  marginRight: 8,
  marginTop:2
}

export const styles = (theme) => ({
  root: {
    width: 153,
    height: 32,
    marginLeft:8,
    padding: 0,
    display: 'flex',
    '&:active $thumb': {
      width: 12,
    },
    '&:active $switchBaseChecked': {
      transform: 'translateX(9px)',
    },
  },
  switchBase: {
    color:theme.palette.primary.main,
    padding: 2,
    '&$checked': {
      transform: 'translateX(74px)',
      color: theme.palette.primary.main,
      '&$checked + $track': {
        border: '1px solid',
        background: '#DFF2FC',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 67,
    height: 24,
    borderRadius: 12,
    marginTop:2,
    marginLeft:4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&:before': {
      content: ({ type }) =>  `"${type === 'Numeric' ? 'Numeric' : 'Boolean'}"`,
      color: 'white',
      position: 'absolute',
      left: ({ type }) => type === 'Numeric' ? 12 : 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
  },
  track: {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: '20px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    position: 'relative',
    '&:before': {
      content: `"Numeric"`,
      color:theme.palette.primary.main,
      position: 'absolute',
      left: 12,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
    '&:after': {
      content: `"Boolean"`,
      position: 'absolute',
      right: 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
      color:theme.palette.primary.main,
    },
  },
  thumbChecked: {
    color: '#FFFFFF',
  },
  checked: {},
  switchBaseChecked: {},
  zIndex: {
    zIndex: theme.zIndex.tooltip,
  },
});
