import { Grid, styled, TextField, Typography } from '@mui/material';

export const MuiSubTitle = styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontFamily: 'Raleway',
  fontSize: 21,
  fontWeight: 500,
  letterSpacing: 0.15
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  color: '#212121',
  fontFamily: 'Raleway',
  fontSize: 29,
  fontWeight: 700
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.5, 0.5),
}));

export const MuiGridField = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 0, 1),
}));

export const MuiTextAreaField = styled(TextField)(({ theme }) => ({
  marginTop:'25px',
  marginLeft:'3.6rem !important',
  width:'330px',
  height: '370px',
  '& .MuiInputBase-root': {
    borderRadius: '12px',
    height: '100%',
  },
  '& .MuiOutlinedInput-root': {
    height: '100%',
    '& textarea': {
      height: 'calc(100% - 32px)',
    },
    '& fieldset': {
      borderColor: '#C0DBE5',
    },
    '&:hover fieldset': {
      borderColor: '#C0DBE5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#C0DBE5',
    },
  },
}));

export const iconButtonStyle = {
  marginTop:'-20rem'
};

export const descriptionLabelStyle = {
  color: '#616161',
  marginTop: '5px',
  fontFamily: 'Raleway',
  fontSize: 16,
  fontWeight: 400,
  letterSpacing: 0.15
}
