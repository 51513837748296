import * as React from 'react';
import {
  Grid,
  Tab,
  makeStyles,
  Toolbar,
  ThemeProvider,
  Paper,
  Container
} from '@material-ui/core';
import {TabContext, TabList} from '@material-ui/lab';
import {useSelector} from 'react-redux';

import themeIncluia from 'theme/incluia';

import styles from './styles/widgetsTabs';
import Widgets from './Widgets';

import {GLOBAL} from 'utils/constants';

const useStyles = makeStyles(styles);

const WidgetsTabs = () => {
  const classes = useStyles();

  const tabs = useSelector((state) => state.app.tabs);
  const [activeTab, setActiveTab] = React.useState('');


  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    if (tabs[0]) setActiveTab(tabs[0]?.id);
  }, [tabs]);

  const dinamicStyles = index => (index === activeTab ? {} : classes.nonSelectedTab);

  return (
    <Container disableGutters fixed>
      {activeTab && (
        <TabContext value={activeTab}>
          <ThemeProvider theme={themeIncluia}>
            <Toolbar disableGutters className={classes.toolbar}>
              <Grid container component={Paper} elevation={0} spacing={4}
                    style={{
                      borderRadius: 0,
                      margin: 0,
                      zIndex: 1201,
                      borderBottom: `1px solid ${GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[200]}`,
                      width: '100%',
                      backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50],
                      borderRadius: '20px 0px 0px 0px',
                    }}>
                <TabList
                  onChange={handleChange}
                  className={classes.tabs}
                  textColor='primary'
                  variant='scrollable'
                  scrollButtons='auto'
                  orientation='horizontal'
                  indicatorColor='secondary'
                  TabIndicatorProps={{className: classes.indicator}}
                >
                  {tabs.map((w, index) => (
                    <Tab label={w.name} value={w.id} key={w.id}
                         style={{minWidth: '25%'}}
                         className={`${dinamicStyles(index)} ${classes.onHover}`}
                         classes={{
                           selected: classes.selectedTab,
                           indicator: classes.indicator
                         }}/>
                  ))}
                </TabList>
              </Grid>
            </Toolbar>
          </ThemeProvider>
          <Grid style={{
            maxHeight: 'calc(87vh - 48px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '3px'  // Adjust the width as needed
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'darkgrey',
              outline: '1px solid slategrey'
            }
          }} className={classes.widgetContainer}>
            <Widgets tabId={activeTab}/>
          </Grid>
        </TabContext>
      )}
    </Container>
  );
};

export default WidgetsTabs;
