import {useState} from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from 'components/views/organizations/common/CloseIcon';
import { Typography, Popover, IconButton } from '@mui/material';
import {
  btnStyle,
  popoverContainerStyle,
  typographyStyle
} from './styles/typographyWithInfo';

function TypographyWithInfoIconTooltip( props ) {
  const {
    customBtnStyles,
    className,
    popoverContainerStyles,
    popoverTextStyles,
  } = props

const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={className}>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={ customBtnStyles ? customBtnStyles : btnStyle}
      >
        <InfoIcon sx={{width: '16px', height: '16px', fontSize: '16px'}}/>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={popoverContainerStyles ? popoverContainerStyles : popoverContainerStyle}
      >
      <CloseIcon
          onClose={handleClose}
          style={{
            borderRadius: 25,
            position: 'absolute',
            right: -10,
            top: -10,
            transform: 'scale(0.7)',
          }}
        />
        <Typography style={ popoverTextStyles ? popoverTextStyles : typographyStyle } >{ props.children }</Typography>
      </Popover>
    </div>
  );
}

export default TypographyWithInfoIconTooltip;
