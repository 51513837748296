// External library imports
import { IconButton, styled, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

// Internal component and function imports
import Button from 'components/common/Button';
import {lighten} from "@mui/material/styles";

export const MuiButtonClose = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color:'#005F64',
  borderRadius: '20px',
  width:'105px',
  height:'37px',
  '&:hover':{
    backgroundColor: lighten('#005F64', 0.8)
  }
}));

export const MuiButtonDelete = styled(Button)(({ theme }) => ({
  padding:'10px 16px 10px 16px',
  borderRadius: '20px',
  width:'105px',
  height:'37px',
  borderColor:'#C71A0F',
  color: '#C71A0F',
  '&:hover':{
    backgroundColor: lighten('#F63B2B', 0.8),
    borderColor:'#C71A0F',
  }
}));

export const MuiIconButtonDelete = styled(IconButton)(({ theme }) => ({
  padding: '7px',
  color: '#ff1744',
  cursor:'none',
  backgroundColor: lighten('#ff1744', 0.9),
  '&:hover': {
    backgroundColor: lighten('#ff1744', 0.5),
  }
}));

export const MuiWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: '#f29e02',
  width: '100px !important',
  height: '100px !important',
  padding: '8px !important'
}));

export const MuiTypographyContent = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(1.5, 1),
  fontFamily:'Raleway',
  fontWeight:400,
  fontSize: '25px',
  color:'#212121'
}));

export const MuiTypographyOrgInfo = styled(Typography)(({ theme }) => ({
  textAlign: 'justify',
  margin: theme.spacing(2, 1),
  fontSize: theme.spacing(2.5),
}));
