import * as React from 'react';
import { createTheme, ThemeProvider, colors, responsiveFontSizes } from '@material-ui/core';
import { cartoThemeOptions } from 'lib/react-geo-tool/packages/react-ui/src/';

import { responsive } from 'theme/carto';

import { GLOBAL } from 'utils/constants';
import themeIncluia from 'theme/incluia'

const ThemeContext = React.createContext();

const CustomThemeProvider = ({ children }) => {
  const [isCustomColor, setIsCustomColor] = React.useState(true);
  const [primary, setPrimary] = React.useState( GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[500] );
  const [secondary, setSecondary] = React.useState(colors.blue[500]);

  const customTheme = createTheme({
    ...themeIncluia,
    palette: {
      ...cartoThemeOptions.palette,
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
    // typography: {
    //   fontFamily: '"Montserrat"',
    // },
    // components: {
    //   MuiCssBaseline: {
    //     styleOverrides: `
    //     @font-face {
    //       font-family: 'Montserrat';
    //       font-style: normal;
    //       font-display: swap;
    //       font-weight: 400;
    //     }
    //   `,
    //   },
    // },

  });

  const theme = responsiveFontSizes(customTheme, responsive);
  const setThemeActive = (active) => localStorage.setItem('themeActive', active);

  const setSwitchColor = React.useCallback(() => {
    setThemeActive(!isCustomColor);
    setIsCustomColor(!isCustomColor);
  }, [isCustomColor]);
  const getThemeActive = () => localStorage.getItem('themeActive');

  const setColorsInLocalStorage = React.useCallback((p, s) => {
    localStorage.setItem('colorPrimary', p);
    localStorage.setItem('colorSecondary', s);
  }, []);

  const setCustomColors = React.useCallback(
    (primaryColor, secondaryColor) => {
      if (isCustomColor) {
        setSecondary(secondaryColor);
        setPrimary(primaryColor);
      }
      setColorsInLocalStorage(primaryColor, secondaryColor);
    },
    [setColorsInLocalStorage, isCustomColor]
  );

  const getColorsInLocalStorage = React.useCallback(
    () => [localStorage.getItem('colorPrimary'), localStorage.getItem('colorSecondary')],
    []
  );

  const removeColor = React.useCallback(() => {
    localStorage.removeItem('colorPrimary');
    localStorage.removeItem('colorSecondary');
  }, []);

  React.useEffect(() => {
    const colorsSaved = getColorsInLocalStorage();
    if (isCustomColor && colorsSaved[0]) {
      setPrimary(colorsSaved[0]);
      setSecondary(colorsSaved[1]);
    } else {
      setPrimary(GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[500]);
      setSecondary(colors.blue[500]);
    }
  }, [isCustomColor, getColorsInLocalStorage]);

  React.useEffect(() => {
    setThemeActive(true);
    setIsCustomColor(getThemeActive() === 'true');
    // eslint-disable-next-line
  }, []);

  const value = React.useMemo(
    () => ({
      setColors: setCustomColors,
      setSwitchColor,
      isCustomColor,
      removeColor,
      theme,
    }),
    [setCustomColors, setSwitchColor, isCustomColor, removeColor, theme]
  );

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => React.useContext(ThemeContext);
export { ThemeContext, useTheme };

export default CustomThemeProvider;
