import useCartoPiaToken from 'api/hooks/useCartoPiaToken';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';

const CartoPiaAuthContext = createContext();

const CartoPiaAuthProvider = ({ children }) => {
  const { data: cartoPiaToken, status: cartoPiaTokenLoadingStatus, refetch: refetchCartoPiaToken } = useCartoPiaToken();

  const [cartoPiaAuthToken, setCartoPiaAuthToken] = useState(
    localStorage.getItem('cartoPiaAuthToken') || ''
  );

  useEffect(() => {
    const updateCartoPiaAuthTokenInLocalStorage = (token) => {
      localStorage.setItem('cartoPiaAuthToken', token);
      // console.log('LocalStorage updated with token:', token);
    };

    const handleTokenUpdate = (token) => {
      setCartoPiaAuthToken(token);
      updateCartoPiaAuthTokenInLocalStorage(token);
    };

    if (cartoPiaTokenLoadingStatus === 'success' && cartoPiaToken) {
      // console.log('Setting state with token:', cartoPiaToken);
      handleTokenUpdate(cartoPiaToken);
    } else if (cartoPiaTokenLoadingStatus === 'error') {
      refetchCartoPiaToken().then(({ data, status }) => {
        if (status === 'success'&&data) {
          // console.log('Setting state with refetched token:', data);
          handleTokenUpdate(data);
        }
      });
    }
  }, [cartoPiaToken, cartoPiaTokenLoadingStatus, refetchCartoPiaToken]);

  useEffect(() => {
    if (cartoPiaAuthToken) {
      localStorage.setItem('cartoPiaAuthToken', cartoPiaAuthToken);
    }
  }, [cartoPiaAuthToken]);

  const getCartoPiaAuthenticationToken = () => {
    // console.log("getStateToken",cartoPiaAuthToken)
    const localToken=localStorage.getItem('cartoPiaAuthToken');
    if(!cartoPiaAuthToken&&!!localToken){
      setCartoPiaAuthToken(localToken);
      return localToken;
    }
    return cartoPiaAuthToken;
  }

  // useEffect(() => {
  //   // console.log("stateToken changes",cartoPiaAuthToken)
  // }, [cartoPiaAuthToken]);

  const value = useMemo(
    () => ({
      getCartoPiaAuthenticationToken,
    }),
    [
      cartoPiaAuthToken,
    ]
  );

return <CartoPiaAuthContext.Provider value={value}>{children}</CartoPiaAuthContext.Provider>;

};

const useAuthCartoPia = () => useContext(CartoPiaAuthContext);

export { CartoPiaAuthContext, useAuthCartoPia };
export default CartoPiaAuthProvider;
