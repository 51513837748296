import {
  InvalidColumnError,
  getGeometryToIntersect,
  isGlobalViewport,
  normalizeGeometry
} from 'lib/react-geo-tool/packages/react-core/src/';
import {
  selectAreFeaturesReadyForSource,
  selectSpatialFilter,
  selectViewport
} from 'lib/react-geo-tool/packages/react-redux/src/';
import { dequal } from 'dequal';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_INVALID_COLUMN_ERR } from '../widgets/utils/constants';
import useCustomCompareEffect from './useCustomCompareEffect';
import useWidgetSource from './useWidgetSource';
import { isRemoteCalculationSupported } from '../models/utils';
import React from 'react';

export default function useWidgetFetch(
  modelFn,
  {
    id,
    dataSource,
    params,
    global,
    onError,
    enabled = true,
    attemptRemoteCalculation = false
  }
) {
  // State
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [warning, setWarning] = useState('');

  const source = useWidgetSource({ dataSource, id });
  const remoteCalculation =
    attemptRemoteCalculation && isRemoteCalculationSupported({ source });

  const isSourceReady = useSelector(
    (state) =>
      global || remoteCalculation || selectAreFeaturesReadyForSource(state, dataSource)
  );

  const viewport = useSelector((state) => state.app.initialViewPort);
  const spatialFilter = useSelector((state) => selectSpatialFilter(state, dataSource));
  let geometryToIntersect =
    spatialFilter
      ? global
        ? normalizeGeometry(getGeometryToIntersect(viewport, spatialFilter.geometry))
        : normalizeGeometry(getGeometryToIntersect(viewport, spatialFilter.geometry))
      : null;
  const filtersPolygon = useSelector((state) => state.app.listFilterPolygonData);
  const mapId = (location.pathname.split('/'))[6];

  const getDynamicRecommendationsName = (isWidgetGlobal) => {
    let dynamicRecommendationsName = '';
    const userId = JSON.parse(localStorage.getItem('user')).id;
    const org_id = location.pathname.split('/')[2];

    if (isWidgetGlobal || isWidgetGlobal === null || isWidgetGlobal === undefined) {
      dynamicRecommendationsName = mapId + '_' + org_id;
    } else {
      dynamicRecommendationsName = mapId + '_' + userId;
    }
    return dynamicRecommendationsName;
  }

  const widgetId = useSelector((state) => state.app.selectWidgetId);
  const widgets = useSelector((state) => state.app.widgets);
  const filterWidget = widgets?.find(w => w.id === widgetId);

  const dynamicRecommendationsName = getDynamicRecommendationsName( filterWidget?.params?.isGlobal )

  let spatial_table = null ;

  if(Object.entries(filtersPolygon).length >0 && widgetId){
    geometryToIntersect = filtersPolygon[widgetId + '-'+ mapId]?.polygons;
  }
  if (Object.entries(filtersPolygon).length >0){
    spatial_table = filterWidget?.type === 'polygon' ? !filterWidget.params?.polygonDataset ? '' :  filterWidget.params?.polygonDataset : filterWidget?.type === 'recommendations' ? getDatasetRecommendations(widgetId, dynamicRecommendationsName) : null;
  }

  const transformedSource = useMemo(() => {
    if (source) {
      const newFilters = { ...source.filters };
      Object.keys(source.filters).forEach(keyProp => {
        if (newFilters[keyProp].between || newFilters[keyProp].in) {
          const type = newFilters[keyProp].between ? 'between' : 'in';
          const column = widgets.find((w) => w.id === newFilters[keyProp][type].owner).params.field;
          if (keyProp !== column){
            newFilters[column] = newFilters[keyProp];
            delete newFilters[keyProp];
          }
        }
      });
      return { ...source, filters: newFilters };
    }
    return source;
  }, [source, widgets]);

  useCustomCompareEffect(
    () => {
      if (source && isSourceReady && enabled) {
        setIsLoading(true);
        setWarning('');
        modelFn({
          source: transformedSource,
          ...params,
          global,
          remoteCalculation,
          spatialFilter: geometryToIntersect,
          spatialTable: spatial_table
        })
          .then((data) => {
            if (data !== null && data !== undefined) {
              setData(data);
            }
          })
          .catch((error) => {
            if (InvalidColumnError.is(error)) {
              setWarning(DEFAULT_INVALID_COLUMN_ERR);
            } else if (onError) {
              onError(error);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [
      params,
      source,
      onError,
      isSourceReady,
      global,
      remoteCalculation,
      geometryToIntersect,
      enabled,
      spatial_table
    ],
    dequal
  );

  return { data, isLoading, isSourceReady, source, warning, remoteCalculation };
}
function getDatasetRecommendations(widgetId, mapId) {
  return `incluia-project.incluia_datasets.user_${mapId}_scenario_${widgetId}_recommended_offers`;
}
