import zIndex from '@material-ui/core/styles/zIndex';
import { BorderColor } from '@mui/icons-material';
import { GLOBAL } from 'utils/constants';

const styles = (theme) => ({
  root: {
    height: 'calc(100vh - 64px)',
  },
  drawerContainer: {
    justifyItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
    borderRadius: '20px 0px 0px 20px',
    border: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
    backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50]
  },
  zoomControl: {
    position: 'absolute',
    bottom: theme.spacing(6),
    left: '5%',
    zIndex: 1,
  },
  legendContainer: {
    position: 'relative',
    bottom: '80vh',
    left: theme.spacing(2),
  },
  controlsBottom: {
    position: 'relative',
    bottom: theme.spacing(process.env.NODE_ENV === 'development' ? 12 :7),
    left: '15px',
    width: '50%'
  },
  layerSelect: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
    zIndex: theme.zIndex.drawer,
  },
  mapWrapper: {
    position: 'relative',
    flex: 1,
    overflow: 'hidden',

    // Fix Mapbox attribution button not clickable
    '& #deckgl-wrapper': {
      '& #deckgl-overlay': {
        zIndex: 1,
      },
      '& #view-default-view > div': {
        zIndex: 'auto !important',
      },
    },
  },
  widgetsContainer: {
    width: '434px',
    position: 'absolute',
    top: '0%',
    right: 0,
  },
  collapseButton: {
    width: 24,
    height: 48,
    backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50],
    position: 'absolute',
    borderRadius: 12,
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    top: '50%',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50%',
      height: '50%',
      borderTop: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
      borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
      borderTopLeftRadius: 12,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '50%',
      height: '50%',
      borderBottom: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
      borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
      borderBottomLeftRadius: 12,
    },
  },
  hideWidgetPanelBorderLine: {
    position: 'absolute',
    top: '52.9%',
    width: 10,
    height: 48,
    backgroundColor: 'white',
  }
});

export default styles;
