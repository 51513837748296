import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  styled, Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { GLOBAL } from '../../../../utils/constants';
import { lighten } from '@mui/material/styles';

export const MuiIconAction = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  margin: theme.spacing(-0.75, 0)
}));

export const MuiBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.25)
}));

export const MuiGridActions = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(1),
  height: 24
}));

export const MuiLinearProgress = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: theme.spacing(0.25),
  backgroundColor:`${lighten( GLOBAL.COLOR_THEME.PRIMARY , 0.6)}`,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor:`${GLOBAL.COLOR_THEME.PRIMARY}`,
  },

}));

export const MuiExpandLess = styled(ExpandLess)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.text.secondary,
  maxWidth: '100%',
  maxHeight: '100%',
}));

export const MuiExpandMore = styled(ExpandMore)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.text.secondary,
  maxWidth: '100%',
  maxHeight: '100%',
}));

export const MuiBoxRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'margin'
})(({ theme,margin }) => ({
  position: 'relative',
  maxWidth: '100%',
  padding: margin !== undefined ? margin : theme.spacing(2, 2.5)
}));

export const MuiGridHeader = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'expanded'
})(({ theme,expanded }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  ...(expanded ? { minHeight: theme.spacing(3) } : { height: theme.spacing(3) }),
  padding: 0
}));

export const MuiButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'expanded'
})(({ theme,expandable }) => ({
  flex: 1,
  padding: 0,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  cursor: expandable ? 'pointer' : 'default',
  '& .MuiButton-label': {
    ...theme.typography.body1,

    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(1)
    }
  },
  '&:hover': {
    background: 'none'
  }
}));

export const MuiTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'expanded'
})(({ theme,expanded }) => ({
  fontWeight: 700,
  color:'#424242',
  fontSize: 14,
  fontFamily: 'Raleway',
  wordBreak: 'break-word',
  overflow: 'hidden',
  ...(expanded && {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  }),
  ...(!expanded && {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  })
}));

export const optionsMenuStyle = {
  marginTop: '48px',
  maxHeight: '168px',
  minWidth: '128px'
};

export const displayFlex = {
  display: 'flex'
};

export const popoverContainerStyle = {
  '& .MuiPopover-paper': { display: 'inline-table', padding: 20, maxWidth: '24%' },
};

export const popoverTextStyle = {
  fontSize: 13,
};
