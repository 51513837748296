import {
    makeStyles,
    Box,
  } from '@material-ui/core';
  import { useSelector } from 'react-redux';
  import { useMemo } from 'react';
  import FilterOnMap from './FilterOnMap';
  import CleanFiltersAndColors from "./CleanFiltersAndColors"

import {
  getWidgetsWithFiltersByLayerId,
  getPolygonWidgetsWithFilters,
} from 'utils/supportComponents';

  const useStyles = makeStyles((theme) => ({
        container: {
          width: 559,
          top: 10,
          position: 'absolute',
          zIndex: 100,
          marginTop: 16,
          display: 'flex',
          flexWrap: 'wrap'
        },
  }));

  function AppliedFiltersOnMap( props ) {
      const classes = useStyles();
      const layers = useSelector((state) => state.app.layers);
      const sources = useSelector((state) => state.carto.dataSources);
      const widgets = useSelector((state) => state.app.widgets);
      const listFilterPolygon = useSelector((state) => state.app.listFilterPolygonData);
      const widgetColor = useSelector((state) => state.app.widgetColor ? state.app.widgetColor : {});
      const isWidgetsPanelVisible = useSelector((state) => state.app.isWidgetsPanelVisible);
      
      const filtersOnMap = useMemo(
        () => {
          const appliedFilters = [];
          layers.forEach(layer => {
            appliedFilters.push(
              ...getWidgetsWithFiltersByLayerId(
                layer.id,
                sources,
                layers,
                widgets,
                listFilterPolygon
              )
            );
          });
          appliedFilters.push(
            ...getPolygonWidgetsWithFilters(
              widgets,
              listFilterPolygon
            )
          );
          return appliedFilters;
        },
        [ layers, listFilterPolygon ]
      );

    const formatInformation = ({ widgetName, filter }) => {
      return `${widgetName}${!!filter ? ':' : ''} ${filter || ''}`
    }

      return (
        <Box justifyContent="space-between">
          <Box
              display="flex"
              className={classes.container}
              justifyContent="right"
              style={{ right: isWidgetsPanelVisible ? '548px' : '114px'  }}
          >
            {
              filtersOnMap.map( ( filter ) => {
                return (
                  <FilterOnMap
                    information={ formatInformation({ widgetName: filter.widgetName, filter: filter.filter  }) }
                    layerColor = { filter.layerColor }
                    layerId = { filter.layerId }
                    column = { filter.column }
                    layerIcon = { filter.layerIcon }
                    widgetType = { filter.type }
                    widgetId = { filter.widgetId }
                  />
                )
              })
            }

          </Box>
          { (filtersOnMap.length > 0 || Object.keys(widgetColor).length !== 0 ) && <CleanFiltersAndColors/>}
        </Box>
      );
  }

  export default AppliedFiltersOnMap;
