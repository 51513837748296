import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles, IconButton } from '@material-ui/core';
import {setViewState} from 'lib/react-geo-tool/packages/react-redux/src/';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import {useLoadScript} from "@react-google-maps/api";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {useState} from "react";
import {setLayerMarker} from "store/appSlice";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '-70px',
    transform: 'scale(0.7)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: theme.spacing(0.5),
    position: 'fixed',
    zIndex: '100',
    marginTop: '25px',
  },
  typography: {
    placeSelf: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    minHeight: theme.spacing(4.5),
    margin: '0 1px',
  },
  btn: {
    '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
  },
  searchInput: {
    width: '500px',
    maxWidth: '100%',
    height: '45px',
    outline: 'none',
    fontSize: '20px',
    border: '1px solid lightgray',
    boxShadow: '0px 0px 5px 2px lightgrey',
    paddingLeft: '5px',
    color: 'rgb(81 80 80)'
  },
  searchButton: {
    position: 'fixed',
    zIndex: '100',
    left: '10px',
    marginTop: '25px',
    backgroundColor: '#8b878763',
    height: '45px',
    width:'45px',
    borderRadius: '10px !important'
  },
  closeIconButton : {
    right: '35px',
    zIndex:'1000',
    background: theme.palette.primary.main,
    border: 'solid 1px gray',
    height: '30px',
    width:'30px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  imgContainer: {
    textAlign: 'right',
    padding: '0.25rem 0.5rem'
  },
  imgGoogleMaps: {
    width: 'auto',
    height: '20px'
  }
}));

class Place {
  static places = ['places'];
}

function SearchPlace ({ basemap }) {
  return <SearchPlaceAutocomplete basemap={basemap}/>
}

function SearchPlaceAutocomplete({ basemap }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const zoomLevel = useSelector((state) => Math.floor(state.carto.viewState.zoom));

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (description ) => {
    setValue(description , false);
    clearSuggestions();
    dispatch(setLayerMarker(null));
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      let counter = 1;

      if (zoomLevel > 13) {
        dispatch(
          setViewState({
            zoom: 13,
          })
        );
      }

      if (zoomLevel > 11) {
        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 11
            })
          );
        }, counter * 600);
        counter += 1;
      }

      if (zoomLevel > 9) {
        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 9
            })
          );
        }, counter * 620);
        counter += 1;
      }

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 9,
            latitude: lat,
            longitude: lng,
          })
        );
        dispatch(setLayerMarker({latitude: lat, longitude: lng, place: description }));
      }, counter * 645);
      counter += 1;

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 11,
            latitude: lat,
            longitude: lng,
          })
        );
      }, counter * 665);
      counter += 1;

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 13,
            latitude: lat,
            longitude: lng,
          })
        );
        dispatch(setLayerMarker({latitude: lat, longitude: lng, place: description }));
      }, counter * 690);
      // }
    });
  };

  const cleanInput = () => {
    setValue('');
    setShowSearch(false);
    dispatch(setLayerMarker(null));
  }

  const showSearchHandle = () => {
    setShowSearch(true);
  }

  const renderSuggestions = () => {
    const suggestions = data.map(({ place_id, description }) => (
      <div>
        <Divider />
        {/*{/*children={<RoomIcon/>}*/}
        <ComboboxOption key={place_id} value={description}  />
      </div>
    ));

    return (
      <>
        {suggestions}
        <li className={classes.imgContainer}>
          <img className={classes.imgGoogleMaps}
            src="https://www.google.com/images/branding/lockups/1x/lockup_maps_color_131x24dp.png"
            alt="Powered by Google"
          />
        </li>
      </>
    );
  };

  return (
    <>
    { showSearch ? (
        <div className={classes.root}>
          <Combobox onSelect={handleSelect} aria-labelledby="demo">
            <ComboboxInput
              className={classes.searchInput}
              value={value}
              onChange={handleInput}
              disabled={!ready}
            />
            <ComboboxPopover>
              <ComboboxList>{status === "OK" && renderSuggestions()}</ComboboxList>
            </ComboboxPopover>
          </Combobox>
            <IconButton
              aria-label="close"
              className={classes.closeIconButton}
              size="medium"
              onClick={() => cleanInput()}
              variant='contained'
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
        </div>
      ) :
      (<IconButton
        variant="contained"
        className={classes.searchButton}
        onClick={() => showSearchHandle()}
        size="medium"
      >
        <SearchIcon fontSize="inherit" />
      </IconButton>)
    }
    </>
  );
}

export default SearchPlace;

