/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {selectSourceById} from 'lib/react-geo-tool/packages/react-redux/src';
import {useCartoLayerProps} from 'lib/react-geo-tool/packages/react-api/src';
import {GeoJsonLayer} from '@deck.gl/layers';
import hexToRgb from 'utils/hexToRgb';
import {useLayers} from 'components/providers/LayersProvider';
import {scaleQuantile} from 'd3-scale';
import {
  setOnClick,
  setLayerLoadingState,
  setHasFetchedLayerData
} from 'store/appSlice';
import axios from 'axios';
import {status200} from 'api/status.utils';
import {useAuth} from 'components/providers/AuthProvider';
import {useEffect, useState} from "react";
import {ScatterplotLayer} from "deck.gl";
import {buildQuery} from "../../../../../../utils/supportComponents";

const Layer = ({layer}) => {
  const updatePolygonWidget = useSelector((state) => state.app.updatePolygonWidget);
  const selectWidgetId = useSelector((state) => state.app.selectWidgetId);
  const updateRecommendationLayerFromWidget = useSelector((state) => state.app.updateRecommendationLayerFromWidget);
  const [update, setUpdate] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const {addLayerToMap, updateLayers, forceHide} = useLayers();
  const dispatch = useDispatch();
  const widgets = useSelector((state) => state.app.widgets);

  const [tokenFetch, setTokenFetch] = React.useState(layer.access_token);
  const [queryFetch, setQueryFetch] = React.useState('where ');
  const [loaded, setLoaded] = React.useState(false);
  const [updatePolygonLayer, setUpdatePolygonLayer] = React.useState(false);
  const filters = useSelector((state) => state.carto.dataSources[layer.id]?.filters);
  const parametersCatalog = useSelector((state) => state.app.parametersCatalog);
  const layers = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, layers[layer.id]?.source));
  const cartoLayerProps = useCartoLayerProps({source});
  const credentials = useSelector((state) => state.carto.credentials);
  const widgetColor = useSelector((state) => state.app.widgetColor);
  const accessToken = useSelector((state) => state.app.accessToken);
  const [lastQueryMatch, setLastQueryMatch] = React.useState(null);
  const [mapData, setMapData] = React.useState(null);
  const viewState = useSelector((state) => state.carto.viewState);
  const [operator, setOperator] = React.useState('and');
  const {user} = useAuth();
  const userHiddenLayers = useSelector((state) => state.app.userHiddenLayers);
  const [ localParameters, setLocalParameters ] = useState(parametersCatalog);
  const hasFetchedLayerData = useSelector((state) => state.app.hasFetchedLayerData);
  const layerLoadingState = useSelector((state) => state.app.layerLoadingState);
  const executeQuery = ( queryToFetch) => {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + source?.credentials?.accessToken
      }
    }
    const headers = {
      'Authorization': 'Bearer ' + source?.credentials?.accessToken
    }
    const payload = {
      q: queryToFetch
    }
    return axios({
      url: `${source?.credentials?.apiBaseUrl}sql/map${layer.isRecommendation || layer.isPolygon ? '?nocache=true' : ''}`,
      method: 'POST',
      data: payload,
      headers,
      validateStatus: status200.validateStatus,
      onDownloadProgress: progressEvent => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : parseInt(progressEvent.target.getResponseHeader('content-length'), 10);
        if (totalLength !== null) {
          if (!layerLoadingState[layer.id]) {
            dispatch(setLayerLoadingState({layerId: layer.id, loadingState: true, size: layer.layerSize, downloaded: progressEvent.loaded }))
          }
        }
      }
    }).then(response => {
      dispatch(setLayerLoadingState({layerId: layer.id, loadingState: false, size: layer.layerSize, downloaded: 0 }))
      return response
    });
  }

  const fetchLayerData = (layer , queryToFetch) => {
    if (!hasFetchedLayerData[layer.id]) {
      if (!layer.isPolygon && !layer.isRecommendation) {
        dispatch(setHasFetchedLayerData({layerId: layer.id}));
      }
      return executeQuery(queryToFetch);
    }
    const selectIndex = queryToFetch.indexOf('SELECT', queryToFetch.indexOf('SELECT') + 1);
    const fromIndex = queryToFetch.indexOf('FROM', selectIndex);
    const selectPart = queryToFetch.substring(selectIndex, fromIndex);
    let hasChanged = false;
    let changed = []
    parametersCatalog.forEach((param, index) => {
      if (selectPart.includes(param.propName) && localParameters?.find(lc => lc.propName === param.propName)?.value !== param.value) {
        changed.push(param.propName)
        hasChanged = true;
      }
    });
    if (hasChanged) {
      setLocalParameters(parametersCatalog);
      const queryModified = queryToFetch.replace(/,\s*geom\s*/, ' ')
      return executeQuery(queryModified);
    }else if (mapData === null) {
      return executeQuery(queryToFetch)
    }else {
      return new Promise(function (myResolve, myReject) {
        myResolve({data: mapData});
      });
    }


  }

  const dataPromise = React.useMemo(
    () => {
      setLoaded(false);
      let queryDefault = source?.data?.replace("carto-dw-ac-ze8u4pu1.shared", "incluia-project.incluia_datasets");
      if (!layer.isPolygon && !layer.isRecommendation && parametersCatalog?.length > 0 && typeof queryDefault !== 'undefined') {
        const parameters = parametersCatalog.map(p => `${p.value} AS ${p.propName}`).join(', ');
        if (parameters) {
          queryDefault = `WITH custom_parameters AS (SELECT ${parameters}) ${queryDefault}, custom_parameters `
        }
      }
      let queryToFetch = '';
      if (queryFetch !== 'where ') {
        queryDefault += queryFetch;
      }
      queryToFetch = queryDefault
      const retornoCarto = source?.data &&
        fetchLayerData(layer, queryToFetch);
      const retornoCustom = new Promise(function (myResolve, myReject) {
        try {
          if (mapData != null) {
            const resp = JSON.parse(JSON.stringify(mapData.res));
            if (filters && operator === 'and' && resp) {
              if (Object.keys(filters).length > 0) {
                Object.keys(filters).forEach(keyProp => {
                  if (filters[keyProp].between && filters[keyProp].between.values.length > 0) {
                    const size = filters[keyProp].between.values.length - 1;
                    const values = filters[keyProp].between.values;
                    const lowerValue = values[0][0] != null ? values[0][0] : 0;
                    const higherValue = values[size][1] ? values[size][1] : 100000000;
                    const filteredFeatures = resp.data.features.filter((feat) => feat['properties'][keyProp] >= lowerValue && feat['properties'][keyProp] <= higherValue);
                    resp.data.features = filteredFeatures;
                  }
                  if (filters[keyProp].in && filters[keyProp].in.values.length > 0) {
                    const values = filters[keyProp].in.values;
                    const filteredFeatures = resp.data.features.filter((feat) => values.includes(feat['properties'][keyProp]));
                    resp.data.features = filteredFeatures;
                  }
                });
              }
            }
            if (filters && operator === 'or') {
              const tempArray = resp.data.features.filter((feat) => {
                return Object.keys(filters).some(keyProp => {
                  let matchedFilter = false;
                  if (filters[keyProp].between && filters[keyProp].between.values.length > 0) {
                    const size = filters[keyProp].between.values.length - 1;
                    const values = filters[keyProp].between.values;
                    const lowerValue = values[0][0] != null ? values[0][0] : 0;
                    const higherValue = values[size][1] ? values[size][1] : 100000000;
                    matchedFilter = feat['properties'][keyProp] >= lowerValue && feat['properties'][keyProp] <= higherValue;
                  }
                  if (filters[keyProp].in && filters[keyProp].in.values.length > 0) {
                    const values = filters[keyProp].in.values;
                    matchedFilter = values.includes(feat['properties'][keyProp]);
                  }
                  return matchedFilter;
                });
              });
              resp.data.features = tempArray;
            }
            myResolve(resp)
          }
        } catch (e) {
          myReject(e);
        }
      });
      if (mapData != null) {
        return retornoCustom
      } else {
        return retornoCarto
      }
    },
    [source?.type, source?.data, source?.connections, updatePolygonLayer]
  );

  React.useEffect(() => {
    if ((updatePolygonWidget || updateRecommendationLayerFromWidget) && layer?.id.includes(selectWidgetId)) {
      if (layer?.isRecommendation) {
        if (layer?.id.includes('fixed_true')) {
          let queryUpdate = "select geom from " + '`' + layer.datasetName + '`' + ' where fixed=true';
          fetchLayerData(layer, queryUpdate).then(response => {
            setMapData({res: response});
            setUpdatePolygonLayer(true);
          });
        } else {
          let queryUpdate = "select geom from " + '`' + layer.datasetName + '`' + ' where fixed=false';
          fetchLayerData(layer, queryUpdate).then(response => {
            setMapData({res: response});
            setUpdatePolygonLayer(true);
          });
        }
      } else {
        let queryUpdate = "select geom from " + '`' + layer.datasetName + '`' +
          " WHERE (user_deletions IS NULL OR user_deletions NOT LIKE '%" + user.id + "%') AND (polygon_type = 'from_layer' OR owner_id ='" + user.id + "')";
        setLoaded(false);
        fetchLayerData(layer, queryUpdate).then(response => {
          setMapData({res: response});
          setUpdatePolygonLayer(true);
        });
      }
    }
  }, [updatePolygonWidget, updateRecommendationLayerFromWidget])

  React.useEffect(() => {
    let queryDefault = source?.data?.replace("carto-dw-ac-ze8u4pu1.shared", "incluia-project.incluia_datasets");
    if (!layer.isRecommendation && !layer.isPolygon && parametersCatalog?.length > 0 && typeof queryDefault !== 'undefined') {
      const parameters = parametersCatalog?.map(p => `${p.value} AS ${p.propName}`).join(', ');
      if (parameters) {
        queryDefault = `WITH custom_parameters AS (SELECT ${parameters}) ${queryDefault}, custom_parameters `
      }
      let queryToFetch = '';
      if (queryFetch !== 'where ') {
        queryDefault += queryFetch;
      }
      setLoaded(false);
      dispatch(setLayerLoadingState({layerId: layer.id, loadingState: true, size: layer.layerSize , downloaded: 0}))
      fetchLayerData(layer, queryDefault).then(response => {
        setMapData({res: response});
        setLoaded(true);
        dispatch(setLayerLoadingState({layerId: layer.id, loadingState: false, size: layer.layerSize , downloaded: 0}))
      });
    }
  }, [parametersCatalog])

  React.useEffect(() => {
    if (mapData && layer?.id.includes(selectWidgetId)) {
      setUpdate(true);
    }
    if (mapData && updatePolygonLayer) {
      setUpdatePolygonLayer(false)
    }
  }, [mapData]);


  React.useEffect(() => {
    if (source?.filtersLogicalOperator) {
      setOperator(source.filtersLogicalOperator);
    }
  }, [source?.filtersLogicalOperator]);

  React.useEffect(() => {
    const formatNumberHistogram = (value) => {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return value.toFixed(3);
      }
    }
    const createLayer = (cartoLayerProps, source, layer) => {
      const filledColor = (object) => {
        let color = '';
        if (layer.id === widgetColor?.layer) {
          layer = {...layer, widgetColor}
        }
        if (layer.widgetColor) {
          const datasetColumn = layer.widgetColor.column;
          if (layer.widgetColor.type === 'simple') {
            color = layer.widgetColor.simpleColor;
          } else if (layer.widgetColor.widgetType === 'category') {
            if (typeof object.properties[datasetColumn] == 'number') {
              let val;
              layer.widgetColor.colors.length < 10
                ? val = object.properties[datasetColumn] ? object.properties[datasetColumn] : 0
                : val = object.properties[datasetColumn] ? object.properties[datasetColumn].toFixed(3) : 0;
              if (layer.widgetColor.colors.length < 10) {
                layer.widgetColor.colors.forEach((c) => {
                  if (c.value === object.properties[datasetColumn].toString()) {
                    color = c.color;
                  }
                });
              } else {
                const c = layer.widgetColor.colors.find((d) => {
                  const interval = d.value.split(' - ');
                  return interval[0] <= val && interval[1] >= val;
                });
                color = c.color;
              }
            } else if (typeof object.properties[datasetColumn] == 'boolean') {
              layer.widgetColor.colors.forEach((c) => {
                if (c.value === object.properties[datasetColumn].toString()) {
                  console.log( c.color , object.properties[datasetColumn], typeof c.value)
                  color = c.color;
                }
              });
            }
            else if (typeof object.properties[datasetColumn] == 'string') {
              layer.widgetColor.colors.forEach((c) => {
                if (c.value === object.properties[datasetColumn].toString()) {
                  color = c.color;
                }
              });
            }
          } else if (layer.widgetColor.widgetType === 'histogram') {
            const val = object.properties[datasetColumn] ? formatNumberHistogram(object.properties[datasetColumn]) : 0;
            const c = layer.widgetColor.colors.find((d) => {
              return d.start <= val && d.end >= val;
            });
            if (c) {
              const scale = scaleQuantile(
                [c.start, c.end],
                c.stops.map((x) => x.c)
              );
              color = scale(val);
            }
          }
        } else {
          layer.legend?.forEach((l) => {
            if (l.value === object.properties[layer.datasetColumn]) {
              color = l.color;
            }
          });
        }

        if (color && color.startsWith('rgb(')) {
          return color
            .replace('rgb(', '')
            .replace(')', '')
            .split(',')
            .map((d) => +d);
        }

        if (color && color.startsWith('rgba(')) {
          return color
            .replace('rgba(', '')
            .replace(')', '')
            .split(',')
            .map((d) => +d);
        }

        return color ? hexToRgb(color, layer.opacity) : hexToRgb(layer.color, layer.opacity);
      };
      const lineColor = (object) => {
        if (layer.geometry === 'MultiLineString') return hexToRgb(layer.color, layer.opacity);
        const showDefaultBorder = layer.borderWidth > 0;
        let color = '';
        if (layer.id === widgetColor?.layer) {
          layer = {...layer, widgetColor}
        }
        if (layer.widgetColor) {
          const datasetColumn = layer.widgetColor.column;
          if (layer.widgetColor.type === 'simple') {
            color = layer.widgetColor.simpleColor;
          } else if (layer.widgetColor.widgetType === 'category') {
            if (typeof object.properties[datasetColumn] == 'number') {
              let val;
              layer.widgetColor.colors.length < 10
                ? val = object.properties[datasetColumn] ? object.properties[datasetColumn] : 0
                : val = object.properties[datasetColumn] ? object.properties[datasetColumn].toFixed(3) : 0;
              if (layer.widgetColor.colors.length < 10) {
                layer.widgetColor.colors.forEach((c) => {
                  if (c.value === object.properties[datasetColumn].toString()) {
                    color = c.color;
                  }
                });
              } else {
                const c = layer.widgetColor.colors.find((d) => {
                  const interval = d.value.split(' - ');
                  return interval[0] <= val && interval[1] >= val;
                });
                color = c.color;
              }
            } else if (typeof object.properties[datasetColumn] == 'boolean') {
              layer.widgetColor.colors.forEach((c) => {
                if (c.value === object.properties[datasetColumn].toString()) {
                  console.log( c.color , object.properties[datasetColumn], typeof c.value)
                  color = c.color;
                }
              });
            }
            else if (typeof object.properties[datasetColumn] == 'string') {
              layer.widgetColor.colors.forEach((c) => {
                if (c.value === object.properties[datasetColumn].toString()) {
                  color = c.color;
                }
              });
            }
          } else if (layer.widgetColor.widgetType === 'histogram') {
            const val = object.properties[datasetColumn] ? formatNumberHistogram(object.properties[datasetColumn]) : 0;
            const c = layer.widgetColor.colors.find((d) => {
              return d.start <= val && d.end >= val;
            });
            if (c) {
              const scale = scaleQuantile(
                [c.start, c.end],
                c.stops.map((x) => x.c)
              );
              color = scale(val);
            }
          }
        } else {
          layer.legend?.forEach((l) => {
            if (l.value === object.properties[layer.datasetColumn]) {
              color = l.color;
            }
          });
        }

        if (color && color.startsWith('rgb(')) {
          return color
            .replace('rgb(', '')
            .replace(')', '')
            .split(',')
            .map((d) => +d);
        }

        if (color && color.startsWith('rgba(')) {
          return color
            .replace('rgba(', '')
            .replace(')', '')
            .split(',')
            .map((d) => +d);
        }
        const opacity = layer.borderOpacity ? layer.borderOpacity : 0.7
        return color ? showDefaultBorder ? hexToRgb(color, opacity) : hexToRgb(color, opacity) : hexToRgb(layer.borderColor, opacity);
      };
      const getIcon = (object) => {
        let urlIcon = '';
        if (layer.legendType === 'simple') {
          urlIcon = layer.customIcon;
        } else {
          layer.legend?.forEach((l) => {
            if (l.value === object.properties[layer.datasetColumn]) {
              urlIcon = l.icon;
            }
          });
          if (urlIcon === '') {
            urlIcon = layer.legend.find((l) => l.id === 'other').icon;
          }
        }
        return {
          url: process.env.PUBLIC_URL + '/maki-icons/' + urlIcon,
          width: 128,
          height: 128,
          mask: true
        };
      }
      const getPointColor = (object) => {
        if (layer.geometry === 'Point'){
          if (layer.legendType === 'simple' && layer.customIcon === 'circle.svg') {
            return hexToRgb(layer.color, 0);
          } else {
            return [255,255,255];
          }
        }
      }

      const getIconColor = (object) => {
        let iconColor = '';
        if (layer.widgetColor) {
          const datasetColumn = layer.widgetColor.column;
          if (layer.widgetColor.type === 'simple') {
            iconColor = layer.widgetColor.simpleColor;
          } else if (layer.widgetColor.widgetType === 'category') {

            if (typeof object.properties[datasetColumn] == 'number') {
              const val = object.properties[datasetColumn] ? object.properties[datasetColumn].toFixed(3) : 0;
              const c = layer.widgetColor.colors.find((d) => {
                const interval = d.value.split(' - ');
                if (interval.length > 1)
                  return interval[0] <= val && interval[1] >= val;
                else
                  return parseFloat(interval[0]) == val;
              });
              if (c) {
                iconColor = c.color;
              }
            } else if (typeof object.properties[datasetColumn] == 'string') {
              layer.widgetColor.colors.forEach((c) => {
                if (c.value === object.properties[datasetColumn].toString()) {
                  iconColor = c.color;
                }
              });
            }
          } else if (layer.widgetColor.widgetType === 'histogram') {
            const val = object.properties[datasetColumn];
            const c = layer.widgetColor.colors.find((d) => {
              return d.start <= val && d.end >= val;
            });
            if (c) {
              const scale = scaleQuantile(
                [c.start, c.end],
                c.stops.map((x) => x.c)
              );
              iconColor = scale(val);
            }
          }
        } else {
          if (layer.legendType === 'simple') {
            iconColor = layer.color;
          } else {
            layer.legend?.forEach((l) => {
              if (l.value === object.properties[layer.datasetColumn]) {
                iconColor = l.color;
              }
            });
            if (iconColor === '') {
              iconColor = layer.legend.find((l) => l.id === 'other').color;
            }
          }
        }
        return hexToRgb(iconColor);
      }

      const geoJsonLayer = new GeoJsonLayer({
        ...cartoLayerProps,
        connection: 'carto_dw',
        id: layer.id,
        geoColumn: layer.geometryName,
        index: layer.index,
        data: dataPromise,
        dataTransform: (res) => {
          if (mapData == null && res != null) {
            setMapData({
              res
            });
          }
          setLoaded(true);
          return res.data;
        },
        lineWidthMinPixels: layer.borderWidth !== undefined ? layer.borderWidth : 1,
        getFillColor: (obj) => filledColor(obj),
        getLineColor: (obj) => lineColor(obj),
        stroked: true,
        // pointRadiusUnits: 'meters',
        sizeUnits: 'pixels',
        lineWidthScale: layer.borderWidth !== undefined ? layer.borderWidth : 1,
        pointType: 'icon',
        getIconSize: layer.iconSize/1.3 || 7,
        filled: true,
        getIcon: (obj) => getIcon(obj),
        getIconColor: (obj) => getPointColor(obj) ,
        iconSizeScale: 1,
        pickable: layer?.tooltip?.columns?.length > 0,
        onClick: (info) => {
          const newColumns = layer?.tooltip?.columns.map((c) => c.name);
          if (info?.object && layer.tooltip?.columns) {
            info.object = {
              feature: info.object,
              title: layer.tooltip.name,
              label: layer.tooltip.label,
              columns: newColumns,
            };
            dispatch(
              setOnClick({
                coordinate: info.coordinate,
                pixel: info.pixel,
                object: info.object
              })
            );
          }
        },
        onDataError: (data) => {
          // eslint-disable-next-line no-console
        },
      });
      if (layer.geometry === 'Point') {
        const circleLayer = new ScatterplotLayer({
          ...cartoLayerProps,
          connection: 'carto_dw',
          id:  `${layer.id}-pointCircle`,
          data: dataPromise,
          dataTransform: res => res.data.features.map(d => ({
            ...d,
            coordinates: d.geometry.coordinates
          })),
          getPosition: d => d.coordinates,
          getRadius: d => Math.sqrt(d.exits),
          getFillColor: d => getIconColor(d),
          opacity: layer.opacity || 1,
          stroked: false,
          filled: true,
          radiusScale: 0.1,
          radiusMinPixels: layer.iconSize * 0.48 || 7,
          pickable: layer?.tooltip?.columns?.length > 0,
          onClick: (info) => {
            const newColumns = layer?.tooltip?.columns.map((c) => c.name);
            if (info?.object && layer.tooltip?.columns) {
              info.object = {
                feature: info.object,
                title: layer.tooltip.name,
                label: layer.tooltip.label,
                columns: newColumns,
              };
              dispatch(
                setOnClick({
                  coordinate: info.coordinate,
                  pixel: info.pixel,
                  object: info.object
                })
              );
            }
          }
        });
        return [circleLayer,geoJsonLayer];
      }
      return [geoJsonLayer];
    };

    const hashPropsToCreateNewLayer =
      source && layers[layer.id] && layer.visible && layer.hide && !update && tokenFetch !== '';

    if (hashPropsToCreateNewLayer) {
      addLayerToMap(createLayer(cartoLayerProps, source, layer));
    }

    const hashPropsToUpdateLayer =
      source && layers[layer.id] && layer.visible && layer.hide && update && tokenFetch !== '';

    if (hashPropsToUpdateLayer) {
      updateLayers(createLayer(cartoLayerProps, source, layer));
      setUpdate(false);
      setHide(true);
    }
  }, [
    layers,
    source,
    cartoLayerProps,
    layer,
    update,
    credentials,
    addLayerToMap,
    widgetColor,
    updateLayers,
    updatePolygonWidget,
    dispatch,
    updatePolygonLayer
  ]);

  React.useEffect(() => {
    async function fetchData(accessToken) {
      if (filters) {
        setLastQueryMatch(source?.data);
        let queryDefault = source?.data;
        let queryToFetch = '';
        const newQuery = buildQuery(filters, widgets, source?.filtersLogicalOperator);
        if (newQuery !== 'where ') {
          queryDefault += newQuery;
        }
        queryToFetch = queryDefault;
        const dataPost = [{
          "connection_name": "carto_dw",
          "source": layer.datasetName
        },
          {
            "connection_name": "carto_dw",
            "source": "`" + layer.datasetName + "`"
          },
        ];
        if (source?.data) {
          dataPost.push({
            "connection_name": "carto_dw",
            "source": source?.data
          },);
        }
        if (queryToFetch) {
          dataPost.push({
            "connection_name": "carto_dw",
            "source": queryToFetch
          });
        }
        setQueryFetch(newQuery);
        setUpdate(true);
      }
    }

    fetchData(accessToken);
  }, [filters, updatePolygonWidget, source?.filtersLogicalOperator, updatePolygonLayer]);


  React.useEffect(() => {
    async function fetchData(accessToken) {
      if (lastQueryMatch !== source?.data) {
        setLastQueryMatch(source?.data);
        let queryDefault = source?.data;
        let queryToFetch = '';
        const newQuery = buildQuery(filters, widgets, source?.filtersLogicalOperator);
        if (newQuery !== 'where ') {
          queryDefault += newQuery;
        }
        queryToFetch = queryDefault;
        setQueryFetch(newQuery);
        setUpdate(true);
      }
    }

    fetchData(accessToken);
  }, [updatePolygonWidget, source?.filtersLogicalOperator]);

  React.useEffect(() => {
    setUpdate(true);
  }, [widgetColor]);

  React.useEffect(() => {
    const isLayerHiddenByUser = userHiddenLayers[layer.id]?.visible === false;
    if (Array.isArray(layer.visibilityByZoomLevel) &&
      Math.floor(viewState.zoom) >= layer.visibilityByZoomLevel[0]
      && Math.floor(viewState.zoom) <= layer.visibilityByZoomLevel[1]) {
      if (isLayerHiddenByUser) {
        if (layer.geometry === 'Point'){
          forceHide(`${layer.id}-pointCircle`, false);
          forceHide(layer.id, false);}
        else forceHide(layer.id, false);
      } else if (Object.keys(userHiddenLayers).length === 0) {
        if (!layer.visible) {
          if (layer.geometry === 'Point'){
            forceHide(`${layer.id}-pointCircle`, !layer.visible);
            forceHide(layer.id, !layer.visible);}
          else forceHide(layer.id, false);
        }
      }
      else if (!layer.visible) {
        if (layer.geometry === 'Point'){
          forceHide(`${layer.id}-pointCircle`, !layer.visible);
          forceHide(layer.id, !layer.visible);}
        else forceHide(layer.id, false);
      }
    } else {
      forceHide(layer.id, false);
    }

  }, [viewState.zoom]);

  React.useEffect(() => {
    if (hide && layer.hide) {
      forceHide(layer.id, false);
      setTimeout(() => {
        forceHide(layer.id, true);
        setHide(false);
      }, 1);
    }
  }, [layer, hide, forceHide, widgetColor]);

  return null;
};
export default Layer;
