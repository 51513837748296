import {
  Box, Button,
  IconButton,
  MenuItem,
  Select,
  styled,
  Typography
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  OpenInFull,
  PinDrop,
  SortByAlpha,
  ThumbUp
} from '@mui/icons-material';
import { GLOBAL } from '../../../../../utils/constants';
import { generateDynamicShades } from '../../../../../utils/colors';
import { lighten } from '@mui/material/styles';
export const MuiThumbUpIcon = styled(ThumbUp)(({ theme }) => ({
  width: '16px !important',
  height: '16px !important',
}));

export const MuiPinDropIcon = styled(PinDrop)(({ theme }) => ({
  width: '16px !important',
  height: '16px !important',
}));

export const MuiOpenInFullIcon = styled(OpenInFull)(({ theme }) => ({
  width: '16px !important',
  height: '16px !important',
}));

export const MuiSortByAlphaIcon = styled(SortByAlpha)(({ theme }) => ({
  width: '16px !important',
  height: '16px !important',
}));

export const MuiArrowUpwardIcon = styled(ArrowUpward)(({ theme }) => ({
  width: '16px !important',
  height: '16px !important',
}));

export const MuiArrowDownwardIcon = styled(ArrowDownward)(({ theme }) => ({
  width: '16px !important',
  height: '16px !important',
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 32,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]
}));

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  width: 298,
  height: 242,
  backgroundColor: 'white',
  padding: 16
}));

export const MuiBoxFilter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const MuiBoxIdentifier = styled(Box)(({ theme }) => ({
  marginTop: 12,
  marginBottom: 12,
}));

export const MuiBoxButtonsContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
}));

export const MuiButtonCommon = styled(Button)(({ theme }) => ({
  color:'#005F64 !important',
  borderRadius: '20px !important',
  borderColor:'#005F64',
  width:'120px',
  height:'37px',
  '&:hover':{
    backgroundColor: lighten('#005F64', 0.8)
  }
}));

export const MuiSelect = styled(Select)(({ theme }) => ({
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 400,
  fontSize: 12,
  '&.MuiInputBase-root': {
    width: 130,
    height: 32,
  }
}));

export const MuiMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 400,
  fontSize: 12,
}));

export const MuiTypographyHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '17.07px',
  marginBottom: 12,
}));

export const MuiLabelCommons = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[900],
}));

export const toggleButtonLargeStyle = {
  borderRadius: '12px !important',
  width: 48,
  height: 24,
  margin: 'auto',
  padding: '4px 8px 4px 8px',
  border: 0
};

export const selectedStyle = {
  backgroundColor: `${generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY)} !important`,
  color: `${GLOBAL.COLOR.WHITE} !important`,
};

export const popoverStyle = {
  '& .MuiPaper-root': {
    borderRadius: '12px !important',
  }
};
