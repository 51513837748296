import { useQuery } from 'react-query';
import axiosCartoInstance from "../axios/axiosCartoInstance";

const fetchDataSetColumnValues = ({ dataSet, column,parametersCatalog}) => {
  if(column !== 'undefined' && column ) {
    const mappedParameters={}
      parametersCatalog?.forEach(param=> {
        mappedParameters[param.propName] = param.value
      });
    //   return axios
    //     .get(`${apiPaths.dataSetCarto}/${dataSet}/${column}/values`, status200)
    //     .then((resp) => resp.data);
      return axiosCartoInstance.post("manage/column-values",{
        table_name:dataSet,
        column_name:column,
        parametersCatalog:mappedParameters
      }
      ).then(resp=>resp.data?.values||[])
  }
}

const useDataSetColumnValues = (data) =>
  useQuery(
    ['columns', data.column],
    () => (!data.histogramType ? fetchDataSetColumnValues(data) : []),
    {
      enabled: data.enabled,
    }
  );

export default useDataSetColumnValues;
