const styles = (theme) => ({
  root: {
    ...theme.typography.caption,
    padding: theme.spacing(0),
    backgroundColor: theme.palette.common.white,
    maxWidth: 308,
    width: 255,
    borderRadius: 12,
    marginBottom: 6,
    boxShadow: '0px 4px 8px 0px #00000026',
  },
  title: {
    margin: '12px 0 0 12px',
    color: 'black',
    fontSize: 14,
    fontWeight: 600
  },
  btn: {
    marginTop: 0,
    padding: 10,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
    width: '40px',
    height: '40px',
  },
  colorAndText: {
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '170px'
  },
  color: {
    height: 24,
    width: 24,
    borderRadius: 14,
    marginRight: theme.spacing(1),
  },
  legendName: {
    paddingLeft: theme.spacing(1),
    fontSize: 10,
  },
  legendNameCenter: {
    marginTop: theme.spacing(2),
    fontWeight: 800,
    textAlign: 'center',
    width: '100%',
    textTransform: 'capitalize',
  },
  popoverContainer: {},
  container: {
    maxWidth: theme.spacing(38),
    padding: theme.spacing(2),
  },
  divDivider: {
    minWid: '100%',
    backgroundColor: 'gray',
    height: 1,
    margin: theme.spacing(0),
  },
  containerTitle: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    borderRadius: '4px 0 0 0',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1.2),
    paddingLeft: '12px',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  containerLegend: {
    margin: '16px 0px 12px 0px',
    overflow: 'auto',
    minHeight: 50,
    maxHeight: 494,
  },
  itemLegend: {
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0),
    padding: '8px 8px 0px 18px',
  },
  layerName: {
    fontSize: 12,
    marginLeft: 8,
    color: '#424242'
  },
  closeIconButton: {
    color: 'black',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
  },
  controlButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
  },
  visibilityIcon: {
    fontSize: '16px',
    width: '16px',
    height: '16px',
  },
  iconContainerSecondLevel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  }
});

export default styles;
