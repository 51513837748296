// PolygonLogicHook.js
import { useQuery } from 'react-query';
import axiosCarto from 'api/axios/axiosCartoInstance';
import apiPaths from '../apiPaths';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuthCartoPia } from 'components/providers/CartoPiaAuthProvider';

const fetchPolygonDataSetColumns = async (dataSet, isInUpdate) => {
  // If dataSet is an empty string, do nothing and return early.
  if (dataSet === '') {
    return; // Or return null or a default value as needed.
  }
  if(isInUpdate){
    return;
  }

  // If dataSet is not empty, proceed with the axios calls.
  try {

    // Now make the second axios call using the access token.
    const dataResponse = await axiosCarto.get(`${apiPaths.schemeNative}`, {
      params: { table_name: dataSet },
    });

    // Return the response data value.
    return dataResponse.data.scheme;
  } catch (error) {
    // Handle or throw the error.
    console.error('Error fetching polygon dataset columns:', error);
    throw error;
  }
};



const usePolygonDataSetColumns = (dataSet, filter, isInUpdate) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getCartoPiaAuthenticationToken } = useAuthCartoPia();

  return useQuery(['columnsWithType', dataSet], () => fetchPolygonDataSetColumns(dataSet, isInUpdate), {
    onError: (error) => {
      dispatch(setOpenToast(!isInUpdate));
     // dispatch(setMessageToast(isInUpdate ? '': ''));
    },
  });
};
export default usePolygonDataSetColumns;
