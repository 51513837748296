import * as React from 'react';
import swap from 'lodash-move';
import _ from 'lodash';
import {
  Typography,
  Grid,
  Box,
  Checkbox
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useCanManageRecomendationWidgetsFilter from 'api/hooks/useCanManageRecomendationWidgetsFilter';
import { useAuth } from 'components/providers/AuthProvider';

import { setWidgets, setIsValidDescriptiveScenario } from 'store/appSlice';
import {
  MuiDeleteIcon,
  MuiEditIcon,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon
} from '../../layer/styles/layersSort';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ListIcon from '@mui/icons-material/List';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ParametersIcon from "@mui/icons-material/Tune"
import {
  boxStyle,
  commonIconsWidgetStyle, dragHandleStyle, MuiGrid,
  MuiGridContainer, MuiGridSorteableList
} from './styles/widgetsSort';
import {
  customActionsStyle,
  customVisibilityStyle
} from './styles/widgetsTabs';

const DragHandle = SortableHandle(() => (
  <DragHandleIcon color='primary' style={dragHandleStyle} />
));

const SortableItem = SortableElement(({ item, groupId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = (id) =>
    navigate(`${location.pathname}?delete-widget=${id}&group-id=${groupId}`, {
      replace: true,
      state: item,
    });

  const handleEdit = (id) => {
    navigate(`${location.pathname}?edit-widget=${id}&group-id=${groupId}`, {
      replace: true,
      state: item,
    });
  };

  const Controls = () => (
    <MuiGrid item xs={3} >
      <Checkbox
        checked={true}
        name='visibility'
        color='primary'
        style={customVisibilityStyle}
        icon={<MuiVisibilityOffIcon />}
        checkedIcon={<MuiVisibilityIcon />}
      />
      <MuiEditIcon
        style={customActionsStyle}
        onClick={() => handleEdit(item.id)}
      />
      <MuiDeleteIcon
        sx={customActionsStyle}
        onClick={() => handleDelete(item.id)}
      />
    </MuiGrid>
  );

  const IconsByWidgetType = ({ type }) => {
    return (
      <>
        {type === 'statistics' && (
          <ViewStreamIcon
            fontSize='small'
            sx={{ ...commonIconsWidgetStyle, background:'#FF9800'}}
          />
        )}
        {type === 'histogram' && (
          <BarChartOutlinedIcon
            fontSize='small'
            sx={{ ...commonIconsWidgetStyle, background:'#607D8B' }}/>
        )}
        {type === 'category' && (
          <BarChartOutlinedIcon
            fontSize='small'
            sx={{ ...commonIconsWidgetStyle, background:'#FF9800', transform: 'rotate(90deg)'}}
          />
        )}
        {type === 'polygon' && (
          <CategoryIcon
            fontSize='small'
            sx={{ ...commonIconsWidgetStyle, background:'#607D8B'}}
          />
        )}
        {type === 'list' && (
          <ListIcon
            fontSize='small'
            sx={{ ...commonIconsWidgetStyle, background:'#607D8B'}}
          />
        )}
        {type === 'recommendations' && (
          <ThumbUpAltIcon
            fontSize='small'
            sx={{ ...commonIconsWidgetStyle, background:'#607D8B' }}
          />
        )}
        {type === 'parameters' && (
          <ParametersIcon
            fontSize='small'
            sx={{ ...commonIconsWidgetStyle, background:'#607D8B' }}
          />
        )}
      </>
    )
  }

  return (
    <MuiGridContainer container>
      <Grid item style={{ width: '45px' }}>
        <DragHandle />
      </Grid>
      <Grid item xs={7} style={{ display: 'flex' }}>
        <Box
          style={{
            ...boxStyle,
            background: item.color + Math.round(item.opacity * 255).toString(16),
            marginLeft: '-1.8rem'
          }}
        />
        <IconsByWidgetType type={item.type}/>
        <Typography
          variant='caption'
          fontSize={12}
          color={'#212121'}
          letterSpacing={0.4}
          fontWeight={400}
          fontFamily={'Montserrat'}
          noWrap
          title={item.name}
        >
          {item.name}
        </Typography>
      </Grid>
      <Controls/>
    </MuiGridContainer>
  );
});

const SortableList = SortableContainer(({ items, groupId }) => (
  <MuiGridSorteableList container >
    {items?.map((item, index) => (
      <SortableItem key={item.id} index={index} item={item} groupId={groupId} />
    ))}
  </MuiGridSorteableList>
));

const WidgetSort = ({ tabId }) => {
  const { user } = useAuth();
  const widgets = useSelector((state) => state.app.widgets.filter( (w) =>
    ( w.default_tab === tabId ) && (w.type !== 'recommendations' || useCanManageRecomendationWidgetsFilter({ widget: w, user, location }))));
  const allWidgets = useSelector((state) => state.app.widgets);
  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(setIsValidDescriptiveScenario(true));
    dispatch(setWidgets(_.union(swap(widgets, oldIndex, newIndex), allWidgets)));
  };

  return (
    <SortableList items={widgets} onSortEnd={onSortEnd} groupId={tabId} useDragHandle />
  );
};

export default WidgetSort;
