import {
  useState,
  useMemo,
} from 'react';

import {
    makeStyles,
    Box,
    ButtonGroup,
    Button,
    Typography,
    Popover,
    IconButton,
  } from '@material-ui/core';

  import { useSelector, useDispatch } from 'react-redux';

  import {
    ArrowDropDown as ArrowDropDownIcon,
    Close as CloseIcon,
  } from "@material-ui/icons";

  import {
    PushPin as PushPinIcon
  } from '@mui/icons-material';

  import { GLOBAL, notificationsType } from 'utils/constants';

  import { 
    getWidgetsWithFiltersByLayerId,
    getPolygonWidgetsWithFilters,
  } from 'utils/supportComponents';

  import { setActiveNotification, setPointsOfReference } from 'store/appSlice';
  import AppliedFilterDisplay from 'components/common/widgets/recommendations/AppliedFilterDisplay';
  import { useTranslation } from 'components/providers/TranslationProvider';
  import useChangeMapState from 'api/hooks/useChangeMapState';
  import { useLocation } from 'react-router-dom';

  const useStyles = makeStyles((theme) => ({

    referencePointButton: {
      top: 26,
      width: 48,
      height: 40,
      zIndex: 100,
      borderRadius: 12,
      position: 'absolute',
      '& .MuiButtonGroup-grouped:not(:last-of-type)': {
        borderRight: 'none',
      },
      '& .MuiButtonGroup-grouped:not(:first-of-type)': {
          marginLeft: '0',
      },
      '& .MuiButtonGroup-grouped': {
        border: 'none',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'white',
          boxShadow: 'none',
        },
      },
      boxShadow: '0px 4px 8px 0px #00000026',
    },
    commonButtonGroup: {
      backgroundColor: 'white',
      minWidth: 24,
      padding: 0,
      borderRadius: 12,
    },
    referencePointContainer: {
      width: 227,
      maxHeight: 356,
      minHeight: 155,
      backgroundColor: 'white',
      padding: 12,
      paddingLeft: '16px',
      paddingRight: '16px',
      borderRadius: 12,
    },
    referencePointTitle: {
      fontWeight: 600,
      fontSize: 14,
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[900],
      alignSelf: 'center',
    },
    commonSubsectionProperties: {
      marginTop: 16,
    },
    filtersSection: {
      // marginTop: 16,
    },
    filtersLabel: {

    },
    appliedFilterDisplayContainer: {
      marginTop: 8,
      maxWidth: 181,
    },
    icon: {
      fontSize: '24px',
      zIndex: 1,
      left: 9,
      position: 'absolute',
    },
    iconCommon: {
      fill: `${GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]} !important`,
    },
    arrowDropDownIcon: {
      fontSize: '16px'
    },
    noHover: {
      '&:hover': {
        backgroundColor: 'white',
      },
      '& .MuiButton-root': {
        '&:hover': {
          backgroundColor: 'white',
        },
      },
    },
    closeIcon: {
      fontSize: '16px',
      color: '#212121'
    },    
  }));

  function ReferencesPointButton( ) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const handleClickPopover = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const layers = useSelector((state) => state.app.layers);
    const sources = useSelector((state) => state.carto.dataSources);
    const widgets = useSelector((state) => state.app.widgets);
    const listFilterPolygon = useSelector((state) => state.app.listFilterPolygonData);
    const pointsOfReference = useSelector((state) => state.app.pointsOfReference);
    const parametersCatalog = useSelector((state) => state.app.parametersCatalog);
    const isWidgetsPanelVisible = useSelector((state) => state.app.isWidgetsPanelVisible);
    
    const dispatch = useDispatch();
    const mutate = useChangeMapState();
    const location = useLocation();
    
    const splittedPathname = useMemo(() => location.pathname.split('/'), [location.pathname]);
    const orgId = splittedPathname[2] === 'workspace' ? user.id : splittedPathname[2];

    const formatParameterWidgetCatalog = ( parameterWidgetCatalog ) => {
      return parameterWidgetCatalog.map(item => item.propName).join(',')
    }

    const filtersOnMap = useMemo(
      () => {
        const appliedFilters = [];
        layers.forEach(layer => {
          appliedFilters.push(
            ...getWidgetsWithFiltersByLayerId(
                layer.id, 
                sources, 
                layers, 
                widgets,
                listFilterPolygon
              )
          );
        });
        appliedFilters.push(
          ...getPolygonWidgetsWithFilters(
              widgets,
              listFilterPolygon
            )
        );
          const parameterWidget = widgets.find( w => w.type === 'parameters' );
          if (parameterWidget) {
            const parametersCatalogParsed = parametersCatalog.map( ( p ) => ({
                propName: p.propName,
                value: p.value,
              })
            );
            appliedFilters.push( {
              type: 'parameters',
              layerColor: '#00BBD4',
              widgetName: parameterWidget.name,
              widgetId: parameterWidget.id,
              filter: formatParameterWidgetCatalog( parameterWidget.parametersCatalog ),
              column: '',
              layerId: '',
              layerIcon: '',
              parameters: [
                ...parametersCatalogParsed
              ]
            } );
          }
          return appliedFilters;
      },
      [ layers, listFilterPolygon, parametersCatalog ]
    );

    const saveReferencePointToMapState = ( referencePoint ) => {
      const updateToMapState = {
        user_id: orgId,
        scheme_id: location.pathname.split('/')[6],
        mapState: {
          pointsOfReference: referencePoint,
        },
      };
      mutate.mutateAsync( updateToMapState );
    }

    const handleClick = () => {
      dispatch( setPointsOfReference( filtersOnMap ) );
      dispatch( setActiveNotification( notificationsType.REFERENCE_POINT ) );
      saveReferencePointToMapState( filtersOnMap );
    }

    const formatInformation = ({ widgetName, filter }) => {
      return `${widgetName}${!!filter ? ':' : ''} ${filter || ''}`
    }

    return (
      <>
        <ButtonGroup style={{ right: isWidgetsPanelVisible ? '446px' : '12px'  }} className={`${classes.referencePointButton} ${classes.noHover}`} variant="contained" ref={anchorEl} aria-label="split button">
          <Button className={`${classes.commonButtonGroup} ${classes.pushPinButton}`} onClick={handleClick}>
            <PushPinIcon 
              className={classes.icon}
              classes={{
                root: classes.iconCommon,
              }}
            />
          </Button>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleClickPopover}
            className={classes.commonButtonGroup}
          >
            <ArrowDropDownIcon  
              classes={{
                root: `${ classes.iconCommon } ${ classes.arrowDropDownIcon }`,
              }} 
            />
          </Button>
        </ButtonGroup>
          <Popover
            id="split-button-menu"
            classes={{
              paper: classes.referencePointContainer
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
              <Box display='inline-flex' justifyContent='space-between' style={{width: '100%'}}>
                <Typography className={classes.referencePointTitle}>
                  { t('reference_point') }
                </Typography>
                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon className={classes.closeIcon}/>
                </IconButton>
              </Box>
              <Box className={classes.commonSubsectionProperties}>
                <Typography variant='overline' className={classes.filtersLabel}>
                  { t('map_filters') }
                </Typography>
                <Box>
                {
                  pointsOfReference.filter( pr => pr.type !== 'parameters' ).map( pointOfReference  => (
                    <AppliedFilterDisplay 
                      information={ formatInformation({ widgetName: pointOfReference.widgetName, filter: pointOfReference.filter }) }
                      iconBgColor={ pointOfReference.layerColor }
                      widgetType={ pointOfReference.type }
                      additionalClasses={{ 
                        container: classes.appliedFilterDisplayContainer
                      }}
                    />
                  ))
                }
                </Box>
              </Box>
              <Box className={classes.commonSubsectionProperties}>
                <Typography variant='overline' >
                  { t('simulation_parameters') }
                </Typography>
                <Box>
                {
                  pointsOfReference.filter( pr => pr.type === 'parameters' ).map( pointOfReference  => (
                    <AppliedFilterDisplay 
                      information={ formatInformation({ widgetName: pointOfReference.widgetName, filter: pointOfReference.filter }) }
                      iconBgColor={ pointOfReference.layerColor }
                      widgetType={ pointOfReference.type }
                      additionalClasses={{ 
                        container: classes.appliedFilterDisplayContainer
                      }}
                    />
                  ))
                }
                </Box>
              </Box>
        </Popover>
      </>
    )
  }
  
  function ReferencesPoint( props ) {
      const classes = useStyles();  
      return (
          <ReferencesPointButton />
      );
  }
  
  export default ReferencesPoint;