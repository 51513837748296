import * as React from 'react';
import {
  Grid,
  Box,
  MenuItem,
  Divider,
  Typography,
  ThemeProvider,
} from '@material-ui/core';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import _ from 'lodash';

import {
  TextField,
  SelectField,
  ColorField,
} from 'components/common/ReactHooksFormFields';
import { useTranslation } from 'components/providers/TranslationProvider';
import useDataSetsOrg from 'api/hooks/useDataSetsOrg';
import Modal from 'components/common/Modal';
import {
  deleteWidgetByLayerId,
  setLayer,
  updateLayer
} from 'store/appSlice';
import Toast from 'components/common/Toast';
import { useAuth } from 'components/providers/AuthProvider';
import { giveMeId } from 'utils/supportComponents';

import useDataSetColumns from 'api/hooks/useDataSetColumns';
import useDataSetColumnValues from 'api/hooks/useDataSetColumnValues';
import { useTheme } from 'components/providers/CustomThemeProvider';
import useRandomId from 'components/hooks/useRandomId';

import {
  arrowsCommonStyle,
  divArrowsActionStyle,
  divCommonStyle,
  divMenuItemStyle,
  divSwitchStyle,
  divValueRangeStyle,
  fontVisibilityStyle,
  gridRangeStyle,
  gridSliderStyle,
  inputColorFieldStyle,
  labelOpacityStyle, MuiBtnAction, MuiBtnClose,
  MuiCustomSkeleton,
  MuiGrid, MuiHtmlTooltip,
  MuiSlider,
  MuiStepButton, MuiSwitchFieldWithTheme,
  MuiTypography,
  selectStyle,
  textFieldCommonStyle,
  typographyByValueStyle
} from './styles/layerForm';
import Legend from './Legend';
import { ReactSVG } from 'react-svg';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import {
  containerSwitchStyle, dividerStyle,
  MuiSwitchTypography
} from '../../styles/tabsInternals';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select
} from '@mui/material';
import { iconsName } from '../../../mapsHandlers/layer/form/iconsName';
import {adornmentColorStyle} from "../../widget/form/styles/widgetForm";
import isEqual from "lodash/isEqual";

// Example function to convert HEX to RGBA and apply opacity
function adjustColorOpacity(hex, opacity) {
  // Convert hex to RGB
  let r = 0, g = 0, b = 0;
  if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
  }
  // Apply opacity
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const iconCustomDefault = 'marker.svg';
const schema = yup.object().shape({
  name: yup.string().min(4, 'min_4_characters').max(40, 'max_40_characters').required('required'),
  datasetName: yup.string().required('required'),
  datasetColumn: yup.string(),
  color: yup.string().required('required'),
  borderColor: yup.string().required('required'),
  borderWidth: yup.number(),
  borderOpacity: yup.number(),
  visible: yup.boolean(),
  opacity: yup.number(),
  iconSize: yup.number(),
  legendType: yup.string().required('required'),
  simpleIconName: yup.string()
});

const LayerForm = ({ action, open, onClose, layerId: id }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const randomId = useRandomId();
  const layers = useSelector((state) => state.app.layers);
  const layer = layers.filter((l) => l.id === id)[0];
  const steps = [t('general_info'), t('layer_type')];
  const defaultIcon = iconsName.find(i => i.name === 'marker')?.name;
  const [error, setError] = React.useState('');
  const [openToast, setOpenToast] = React.useState(false);
  const [legendItems, setLegendItems] = React.useState(action === 'update' ?  [] : [
  { id: 'other', color: localStorage.getItem('colorPrimary'), value: 'Other', icon: iconCustomDefault}]);

  const [isPointDataSet, setIsPointDataSet ] = React.useState((action === 'update' && layer?.geometry === 'Point') );
  const [valueRange, setValueRange] = React.useState(layer? layer.visibilityByZoomLevel : [1, 22]);
  const [valueIcons, setValueIcons] = React.useState(action === 'update' ? layer.customIcon : iconCustomDefault);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const { user } = useAuth();
  const { theme } = useTheme();


  const getDefaultValues = () => {
    return layer
      ? {
          name: layer.name,
          datasetName: layer.datasetName,
          color: layer.color,
          visible: layer.visible,
          opacity: layer.opacity,
          datasetColumn: layer.datasetColumn,
          legendType: layer.legendType,
          borderColor: layer.borderColor,
          borderWidth: layer.borderWidth,
          customIcon: layer.customIcon,
          iconSize: layer.iconSize,
          borderOpacity: layer.borderOpacity ? layer.borderOpacity : 0.7
      }
      : {
          color: localStorage.getItem('colorPrimary'),
          datasetName: '',
          visible: true,
          opacity: 0.3,
          legendType: 'simple',
          borderColor: localStorage.getItem('colorPrimary'),
          borderWidth: 1,
          iconSize: 25,
          borderOpacity: 0.7
        };
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    watch,
  } = methods;

  const datasetName = watch('datasetName');

  const datasetColumns = watch('datasetColumn');

  const legendType = watch('legendType');

  const color = watch('color')

  const opacity = watch('opacity')

  const borderWidth = watch('borderWidth');

  const borderColor = watch('borderColor');

  const borderOpacity = watch('borderOpacity');

  const visible = watch('visible');

  const iconSize = watch('iconSize');

  const rgbaColor = adjustColorOpacity(color, opacity);

  const { data: columns, isSuccess: isSuccessColumns } = useDataSetColumns(datasetName);

  const [refInitialOpacity, setInitialOpacity] = React.useState(null);
  const [refInitialOpacityBorder, setInitialOpacityBorder] = React.useState(null);
  const [refInitialBorder, setInitialBorder] = React.useState(null);
  const [refInitialIconSize, setInitialIconSize] = React.useState(null);
  const [, setDetectedValueChange] = React.useState(false);
  const [prevSelectedColumn, setPrevSelectedColumn] = React.useState(datasetColumns);
  const [opacityField, setOpacityField] = React.useState(opacity);
  const [borderWidthField, setBorderWidthField] = React.useState(borderWidth);
  const [borderOpacityField, setBorderOpacityField] = React.useState(borderOpacity)
  const [visibleField, setVisibleField] = React.useState(visible)
  const [iconSizeField, setIconSizeField] = React.useState(iconSize)

  const handleVisibleField = () => setVisibleField(!visibleField)

  const detectValueChange = (initial, current) =>
    setDetectedValueChange(initial !== null && initial !== current ? true : false)

  const handleIncrementOpacity = () => {
    setOpacityField(prevValue => {
      const newValue = Math.round((prevValue + 0.1) * 10) / 10;
      if(action === 'update') detectValueChange(refInitialOpacity, newValue);
      return newValue;
    });
  };

  const handleDecrementOpacity = () =>{
    setOpacityField(prevValue => {
      const newValue = Math.round((prevValue - 0.1) * 10) / 10;
      if(action === 'update') detectValueChange(refInitialOpacity, newValue);
      return newValue;
    });
  }

  const handleIncrementBorderWidth = () => {
    setBorderWidthField(prevValue => {
      const newValue = Math.round((prevValue + 0.1) * 10) / 10;
      if(action === 'update') detectValueChange(refInitialBorder, newValue);
      return newValue;
    });
  }

  const handleDecrementBorderWidth = () =>
    setBorderWidthField(prevValue => {
      const newValue = Math.round((prevValue - 0.1) * 10) / 10;
      if(action === 'update') detectValueChange(refInitialBorder, newValue);
      return newValue;
    });

  const handleIncrementBorderOpacity = () => {
    setBorderOpacityField(prevValue => {
      const newValue = Math.round((prevValue + 0.1) * 10) / 10;
      if(action === 'update') detectValueChange(refInitialOpacityBorder, newValue);
      return newValue;
    });
  };

  const handleDecrementBorderOpacity = () =>{
    setBorderOpacityField(prevValue => {
      const newValue = Math.round((prevValue - 0.1) * 10) / 10;
      if(action === 'update') detectValueChange(refInitialOpacityBorder, newValue);
      return newValue;
    });
  }

  const handleIncrementIconSize = () =>
    setIconSizeField(prevValue => {
      const newValue = Math.round((prevValue + 1) * 10) / 10;
      if(action === 'update') detectValueChange(refInitialIconSize, newValue);
      return newValue;
    });

  const handleDecrementIconSize = () =>
    setIconSizeField(prevValue => {
      const newValue = Math.round((prevValue - 1) * 10) / 10;
      if(action === 'update') detectValueChange(refInitialIconSize, newValue);
      return newValue;
    });

  const totalSteps = () => steps.length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const handleStep = (step) => () => {
    setActiveStep(step);
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const datasetColumnValues = useDataSetColumnValues({
    dataSet: datasetName,
    column: datasetColumns,
    enabled: false
  });

  const dataset = useDataSetsOrg(giveMeId(location.pathname, user));

  const findRepeatNames = (name) => {
    const foundLayerName = _.find(layers, { name });
    if (foundLayerName?.id === id) return false;
    return foundLayerName;
  };

  const newLayer = (data) => {
    if (findRepeatNames(data.name)) {
      setError(t('error_adding_new_layer'));
      setOpenToast(true);
      return;
    }

    let legend = legendItems;

    if (legendItems.length - 1 === datasetColumnValues.data?.length){
        legend = legendItems
      }

    if (legendType === 'simple') {
       legend = [];
       }
        dispatch(setLayer({
        id: randomId,
        ...data,
        visibilityByZoomLevel: valueRange,
        tooltip: {},
        legend,
        customIcon: valueIcons,
        isPointDataSet: isPointDataSet
      }));
    onClose();
  };

  const updLayer = (data) => {
    if (findRepeatNames(data.name)) {
      setError(t('error_adding_new_layer'));
      setOpenToast(true);
      return;
    }

    let legend = legendItems;

    if (legendItems?.length - 1 === datasetColumnValues.data?.length)
       legend = legendItems
    if (legendType === 'simple') legend = [];

    if (layer.datasetName === data.datasetName)
      dispatch(updateLayer({
        id,
        ...data,
        visibilityByZoomLevel: valueRange,
        tooltip: layer.tooltip,
        legend,
        customIcon: valueIcons,
        isPointDataSet: isPointDataSet
      }));
    else {
      dispatch(updateLayer({
        id,
        ...data,
        visibilityByZoomLevel: valueRange,
        tooltip: {},
        legend,
        customIcon: valueIcons
      }));
      dispatch(deleteWidgetByLayerId(id));
    }
    onClose();
  };

  const onSubmit = handleSubmit((data) => (action === 'update' ? updLayer(data) : newLayer(data)));

  React.useEffect(() => {
    setInitialOpacity(opacityField);
    setInitialBorder(borderWidthField);
    setInitialIconSize(iconSizeField);
    setInitialOpacityBorder(borderOpacityField);
  }, []);

  React.useEffect(() => {
    if (layer?.legend?.length > 0 && legendType === 'by_value') {
       let legendToSet;
       if(action !== 'update') {
           legendToSet = layer.legend
            .filter((l) => l.id !== 'other')
            .concat([{ id: 'other', color: localStorage.getItem('colorPrimary'), value: 'Other', icon: iconCustomDefault }]);
         } else {
         legendToSet = layer.legend;
       }
      setLegendItems(legendToSet);
    }
  }, [layer, legendType]);

  React.useEffect(() => {
    if (legendType === 'by_value') {
      datasetColumnValues.refetch();
    }
    if(prevSelectedColumn !== datasetColumns && legendItems.length > 1) {
      setLegendItems([{ id: 'other', color: localStorage.getItem('colorPrimary'), value: 'Other', icon: iconCustomDefault }]);
      setPrevSelectedColumn(datasetColumns);
    }
  }, [datasetColumns]);

  React.useEffect(() => {
    if (isPointDataSet && action === 'create') {
      setValueIcons('marker.svg');
    }
  }, [isPointDataSet, action]);

  const onChangeDataSetName = (value) => {
      if (value) {
         const selectDataset = dataset.data?.filter((d) => d.cartoName === value);
         setIsPointDataSet( selectDataset[0].cartoGeometryType === 'Point' )
      }

  }

  const handleCloseToast = () => setOpenToast(false);

  const handleChange = (event, newValue) => {
    if (Math.abs(newValue[1] - newValue[0]) < 1) {
      if (newValue[0] === valueRange[0]) {
        newValue[1] = newValue[0] + 1;
      } else {
        newValue[0] = newValue[1] - 1;
      }
    }
    setValueRange(newValue);
  };

  const getDirty = () => {
    if (action === 'update'){
      return !isEqual(layer.opacity, opacityField) || !isEqual(layer.borderOpacity, borderOpacityField) || !isEqual(layer.borderWidth, borderWidthField)
        || !isEqual(layer.datasetName, datasetName) || !isEqual(layer.color, color) || !isEqual(layer.borderColor, methods.getValues('borderColor'))
        || !isEqual(layer.name, methods.getValues('name')) || !isEqual(layer.visibilityByZoomLevel, valueRange) || !isEqual(layer.visible, visible)
        || !isEqual(layer.iconSize, iconSize) || !isEqual(layer.customIcon, valueIcons) || !isEqual(layer.legendType, legendType)
        || !isEqual(layer.datasetColumn, methods.getValues('datasetColumn')) || !isEqual(layer.legend, legendItems)
    }
    return true;
  };
  const handleInputIcon = (event) => {
    const iconName = event.target.value
    if (iconName !== '') {
       setValueIcons(iconName + '.svg');
    }
  }

React.useEffect(() => {
    const colorPreviewElement = document.getElementById('color-preview');
    if (colorPreviewElement) {
      colorPreviewElement.style.backgroundColor = rgbaColor;
    }
  }, [color, opacity]);

  React.useEffect(() => {
    if (!isLastStep() && completed[activeStep]) setCompleted({});
  }, [activeStep]);

  React.useEffect(() => {
    setValue('opacity', opacityField)
  }, [opacityField]);

  React.useEffect(() => {
    setIsPointDataSet(layer?.isPointDataSet || layer?.geometry === 'Point')
  }, [layer]);

  React.useEffect(() => {
    setValue('borderWidth', borderWidthField);
  }, [borderWidthField]);

  React.useEffect(() => {
    setValue('borderOpacity', borderOpacityField)
  }, [borderOpacityField]);

  React.useEffect(() => {
    setValue('visible', visibleField)
  }, [visibleField]);

  React.useEffect(() => {
    setValue('iconSize', iconSizeField)
  }, [iconSizeField]);

  const formLayer = (
    <Grid container>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep} >
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]} >
              <MuiStepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </MuiStepButton>
            </Step>
          ))}
        </Stepper>
        <div>
            <React.Fragment>
              {activeStep === 0 ? (
                <>
                  <Grid container display='flex'>
                    <MuiGrid item xs={12}>
                      <MuiTypography
                        variant='body1'
                        fontSize={16}
                        letterSpacing={0.5}
                        fontWeight={400}
                        fontFamily={'Montserrat'}>
                        {t('name_dataset')}
                      </MuiTypography>
                    </MuiGrid>
                    <Grid item xs={6} style={{ padding: '0px 12px 0px' }}>
                      <TextField
                        name='name'
                        margin='dense'
                        type='text'
                        label={t('layer_name')}
                        variant='outlined'
                        fullWidth
                        error={t(errors.name?.message)}
                        data-cy={'layer_name'}
                        InputLabelProps={{
                          style: { color: '#424242' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: '3px 0px 0px 12px' }}>
                      <MuiHtmlTooltip
                        disableFocusListener={action === 'create'}
                        disableHoverListener={action === 'create'}
                        disableTouchListener={action === 'create'}
                        title={t('change_dataset_layer')}
                      >
                        <div>
                          {dataset.isSuccess ? (
                            <SelectField
                              name='datasetName'
                              error={t(errors.datasetName?.message)}
                              variant='outlined'
                              label={t('select_dataset')}
                              fullWidth
                              onClick={(e) => onChangeDataSetName(e.target.value)}
                              data-cy={'select_dataset_layer'}
                              InputLabelProps={{
                                style: { color: '#424242' }
                              }}
                            >
                              <Divider light value=' ' />
                              {dataset.data?.map((d,index) => (
                                <MenuItem key={d._id} value={d.cartoName} data-cy={`dataset_${index}`}>
                                  {d.prosperiaName
                                    .split('_')
                                    .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
                                    .join(' ')}
                                </MenuItem>
                              ))}
                            </SelectField>
                          ) : ( <MuiCustomSkeleton />)}
                        </div>
                      </MuiHtmlTooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider/>
                    </Grid>
                  </Grid>
                  <Grid container style={{marginLeft: '8px'}}  >
                    <Grid item xs={12} style={{marginTop: 24 , marginBottom: 16}}>
                      <Typography
                        variant='body1'
                        fontSize={16}
                        letterSpacing={0.5}
                        fontWeight={400}
                        fontFamily={'Montserrat'}>
                        {t('visualization')}
                      </Typography>
                    </Grid>
                    <Typography
                      variant='overline'
                      letterSpacing={0.5}
                      fontWeight={400}
                      fontFamily={'Montserrat'}
                    >
                      {t('layer_background')}
                    </Typography>
                    <Grid container >
                      {legendType === 'simple' && (
                        <>
                          <div style={{marginRight: '16px'}}>
                              {/* This div serves as a preview for the color with the applied opacity */}
                              <TextField
                                fullWidth
                                style={textFieldCommonStyle}
                                name="color"
                                label={t('background_color')}
                                variant="outlined"
                                InputLabelProps={{
                                  style: { color: '#424242' }
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <ColorField
                                      name="color"
                                      error={errors.color?.message}
                                      style={inputColorFieldStyle}
                                    />
                                  ),
                                  endAdornment: (
                                    <Typography
                                      component="span"
                                      fontFamily='Montserrat'
                                      fontWeight={400}
                                      style={adornmentColorStyle}>
                                      HEX
                                    </Typography>
                                  ),
                                }}
                              />
                          </div>
                        </>
                      )}
                      <div style={divCommonStyle}>
                        <TextField
                          type="text"
                          variant="outlined"
                          fullWidth
                          name='opacity'
                          value={opacityField}
                          label={t('opacity')}
                          error={t(errors.opacity?.message)}
                          InputLabelProps={{ style: labelOpacityStyle }}
                          InputProps={{
                            endAdornment: (
                              <>
                                <Typography
                                  component="span"
                                  fontFamily='Montserrat'
                                  fontWeight={400}
                                  style={adornmentColorStyle}>
                                  %
                                </Typography>
                                <InputAdornment position="end">
                                  <div style={divArrowsActionStyle}>
                                    <ArrowDropUpIcon
                                      onClick={handleIncrementOpacity}
                                      aria-label="increment"
                                      fontSize='small'
                                      style={{ ...arrowsCommonStyle, marginBottom: '5px'}}/>
                                    <ArrowDropDownIcon
                                      onClick={handleDecrementOpacity}
                                      aria-label="decrement"
                                      fontSize='small'
                                      style={{ ...arrowsCommonStyle, marginTop: '-7px' }}/>
                                  </div>
                                </InputAdornment>
                              </>
                            ),
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                              setOpacityField(value);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "." && e.target.value.includes(".")) {
                              e.preventDefault();
                            }
                            if (!/[\d.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </Grid>
                    { isPointDataSet ? (
                      <Typography
                        variant='overline'
                        letterSpacing={0.5}
                        fontWeight={400}
                        fontFamily={'Montserrat'}>
                        {t('icon')}
                      </Typography>
                    ) : (
                      <Typography
                        variant='overline'
                        letterSpacing={0.5}
                        fontWeight={400}
                        fontFamily={'Montserrat'}>
                        {t('border_layer')}
                      </Typography>
                    )
                    }

                    <Grid container >
                      { !isPointDataSet && (
                        <>
                          <div style={{marginRight: '16px'}}>
                            <TextField
                              fullWidth
                              style={textFieldCommonStyle}
                              name="borderColor"
                              label={t('border_color')}
                              variant="outlined"
                              InputLabelProps={{
                                style: { color: '#424242'}
                              }}
                              InputProps={{
                                startAdornment: (
                                  <ColorField
                                    name='borderColor'
                                    error={t(errors.borderColor?.message)}
                                    style={inputColorFieldStyle}
                                  />
                                ),
                                endAdornment: (
                                  <Typography
                                    component="span"
                                    fontFamily='Montserrat'
                                    fontWeight={400}
                                    style={adornmentColorStyle}>
                                    HEX
                                  </Typography>
                                ),
                              }}
                            />
                          </div>
                          <div style={divCommonStyle}>
                            <TextField
                              type="text"
                              variant="outlined"
                              fullWidth
                              name='borderOpacity'
                              value={borderOpacityField}
                              label={t('opacity')}
                              error={t(errors.opacity?.message)}
                              InputLabelProps={{ style: labelOpacityStyle }}
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <Typography
                                      component="span"
                                      fontFamily='Montserrat'
                                      fontWeight={400}
                                      style={adornmentColorStyle}>
                                      %
                                    </Typography>
                                    <InputAdornment position="end">
                                      <div style={divArrowsActionStyle}>
                                        <ArrowDropUpIcon
                                          onClick={handleIncrementBorderOpacity}
                                          aria-label="increment"
                                          fontSize='small'
                                          style={{ ...arrowsCommonStyle, marginBottom: '5px'}}/>
                                        <ArrowDropDownIcon
                                          onClick={handleDecrementBorderOpacity}
                                          aria-label="decrement"
                                          fontSize='small'
                                          style={{ ...arrowsCommonStyle, marginTop: '-7px' }}/>
                                      </div>
                                    </InputAdornment>
                                  </>
                                ),
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value)) {
                                  setBorderOpacityField(value);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "." && e.target.value.includes(".")) {
                                  e.preventDefault();
                                }
                                if (!/[\d.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div style={divCommonStyle} >
                            <TextField
                              type="numeric"
                              variant="outlined"
                              fullWidth
                              name='borderWidth'
                              value={borderWidthField}
                              label='Border Width'
                              error={t(errors.borderWidth?.message)}
                              InputLabelProps={{
                                style: { color: '#424242' }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <Typography
                                      component="span"
                                      fontFamily='Montserrat'
                                      fontWeight={400}
                                      style={adornmentColorStyle}>
                                      PX
                                    </Typography>
                                    <InputAdornment position="end">
                                      <div style={divArrowsActionStyle}>
                                        <ArrowDropUpIcon
                                          onClick={handleIncrementBorderWidth}
                                          aria-label="increment"
                                          fontSize='small'
                                          style={{...arrowsCommonStyle, marginBottom: '5px'}}/>
                                        <ArrowDropDownIcon
                                          onClick={handleDecrementBorderWidth}
                                          aria-label="decrement"
                                          fontSize='small'
                                          style={{ ...arrowsCommonStyle, marginTop: '-7px' }}/>
                                      </div>
                                    </InputAdornment>
                                  </>
                                ),
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value)) {
                                  setBorderWidthField(value);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "." && e.target.value.includes(".")) {
                                  e.preventDefault();
                                }
                                if (!/[\d.]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                      { isPointDataSet  && legendType === 'simple' && (
                        <Grid item xs={2}>
                          <FormControl variant="outlined" fullWidth sx={{ marginTop: 1 }}>
                            <InputLabel id="icon-select-label"
                                        htmlFor="icon-select"
                                        sx={{ color: '#424242' }}
                            >{t('select')}</InputLabel>
                            <Select
                              labelId="icon-select-label"
                              id="icon-select"
                              defaultValue={
                                action === 'create' ? defaultIcon : layer?.customIcon.split('.')[0]
                              }
                              onChange={handleInputIcon}
                              input={<OutlinedInput label="Choose an Icon" />}
                              fullWidth
                              sx={selectStyle}
                            >
                            {iconsName.map((key) => (
                              <MenuItem value={key.name} key={key.name}>
                                <div style={{
                                  ...divMenuItemStyle,
                                  background: action === 'create' ? theme.palette.primary.main
                                                                  : layer.color,
                                }}>
                                  <ReactSVG
                                    src={`${process.env.PUBLIC_URL}/maki-icons/${key.name}.svg`}
                                    beforeInjection={(svg) => {
                                      svg.classList.add('svg-class-name');
                                      svg.setAttribute('style',
                                        'margin-top:6px; width: 100%; height: 100%;');
                                    }}
                                    afterInjection={(svg) => {
                                      const path = svg.querySelector('path');
                                      if (path) {
                                        path.setAttribute('fill', 'white');
                                      }
                                    }}
                                  />
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                          </FormControl>
                        </Grid>
                      )}
                      { isPointDataSet && (
                        <Grid item xs={2} style={{marginTop:'-5px', marginLeft: '16px', width:'100px'}}>
                          <TextField
                            type="numeric"
                            variant="outlined"
                            fullWidth
                            name='iconSize'
                            value={iconSizeField}
                            label='Icon Size'
                            error={t(errors.iconSize?.message)}
                            InputLabelProps={{
                              style: { color: '#424242', marginTop:4 }
                            }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  <Typography
                                    component="span"
                                    fontFamily='Montserrat'
                                    fontWeight={400}
                                    style={adornmentColorStyle}>
                                    PX
                                  </Typography>
                                  <InputAdornment position="end">
                                    <div style={divArrowsActionStyle}>
                                      <ArrowDropUpIcon
                                        onClick={handleIncrementIconSize}
                                        aria-label="increment"
                                        fontSize='small'
                                        style={{...arrowsCommonStyle, marginBottom: '5px'}}
                                      />
                                      <ArrowDropDownIcon
                                        onClick={handleDecrementIconSize}
                                        aria-label="decrement"
                                        fontSize='small'
                                        style={{ ...arrowsCommonStyle, marginTop: '-7px' }}
                                      />
                                    </div>
                                  </InputAdornment>
                                </>
                              )
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container display='flex' style={{ marginLeft: 12, marginTop:6 }}>
                    <Grid container item xs={6}>
                      <Grid item xs={12} style={{marginBottom:14}}>
                        <Typography
                          variant='caption'
                          fontSize={12}
                          style={fontVisibilityStyle}
                          letterSpacing={0.4}
                          fontWeight={400}
                          fontFamily={'Montserrat'}>
                          {t('visibility_zoom')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={gridSliderStyle}>
                        <MuiSlider
                          value={valueRange}
                          name='visibilityByZoomLevel'
                          onChange={handleChange}
                          aria-labelledby="range-slider"
                          min={1}
                          max={22}
                        />
                      </Grid>
                      <Grid item xs={3} style={gridRangeStyle}>
                        <div style={divValueRangeStyle}>
                          {valueRange[0]}<span style={{color:'#9E9E9E'}}>X</span>
                        </div>
                        <div style={divValueRangeStyle}>
                          {valueRange[1]}<span style={{color:'#9E9E9E'}}>X</span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      item xs={6}
                      display='flex'
                      direction='column'
                      style={{marginLeft:'-2rem'}}>
                      <Grid item xs={4} style={{marginBottom:14}}>
                        <Typography
                          variant='caption'
                          fontSize={12}
                          style={{ color: '#424242', marginLeft:'8px'}}
                          letterSpacing={0.4}
                          fontWeight={400}
                          fontFamily={'Montserrat'}>
                          {t('visibility')}
                        </Typography>
                      </Grid>
                      <div style={divSwitchStyle}>
                        <MuiSwitchFieldWithTheme
                          name='visible'
                          onChange={handleVisibleField}
                          checked={visibleField}
                        />
                        <div style={containerSwitchStyle}>
                          <MuiSwitchTypography
                            style={{
                              color: !visibleField ? theme.palette.primary.main : '#000000'
                            }}
                          >
                            {t('key_no')}
                          </MuiSwitchTypography>
                          <span style={dividerStyle}>/</span>
                          <MuiSwitchTypography
                            style={{
                              color: visibleField ? theme.palette.primary.main : '#000000'
                            }}
                          >
                            {t('key_yes')}
                          </MuiSwitchTypography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </>
              ):(
                <>
                  <Grid container>
                    <Grid item xs={legendType === 'by_value' ? 6 : 12}>
                      <Typography variant='body1'
                                  fontSize={16}
                                  style={typographyByValueStyle}
                                  letterSpacing={0.5}
                                  fontWeight={400}
                                  fontFamily={'Montserrat'}>
                        {t('simple_or_value_layers')}
                      </Typography>
                    </Grid>
                    {legendType === 'by_value' && (
                      <Grid item xs={6}>
                        <Typography variant='body1'
                                    fontSize={16}
                                    style={typographyByValueStyle}
                                    letterSpacing={0.5}
                                    fontWeight={400}
                                    fontFamily={'Montserrat'}>
                          {t('values')}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={6} style={{ padding: '8px 8px 0px 8px' }}>
                      <SelectField
                        name='legendType'
                        error={t(errors.legendType?.message)}
                        variant='outlined'
                        label='select a legend type'
                        fullWidth
                        defaultValue='simple'
                        InputLabelProps={{
                          style: { color: '#424242'}
                        }}
                      >
                        <Divider light value=' ' />
                        {['simple', 'by_value'].map((l) => (
                          <MenuItem key={l} value={l}>
                            {l
                              .split('_')
                              .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
                              .join(' ')}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                    {legendType === 'by_value' && (
                      <Grid item xs={6} style={{ padding: '8px 0px 0px 8px' }}>
                        {isSuccessColumns && watch('datasetName') ? (
                          <SelectField
                            name='datasetColumn'
                            error={t(errors.datasetColumn?.message)}
                            variant='outlined'
                            label={t('select_column')}
                            fullWidth
                            InputLabelProps={{
                              style: { color: '#424242' }
                            }}
                          >
                            <Divider light value=' ' />
                            {columns?.map((c) => (
                              <MenuItem key={c} value={c}>
                                {c
                                  .split('_')
                                  .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
                                  .join(' ')}
                              </MenuItem>
                            ))}
                          </SelectField>
                        ) : (<MuiCustomSkeleton/>)}
                      </Grid>
                    )}
                  </Grid>
                  {legendType === 'by_value' && (
                    <Legend
                      datasetName={watch('datasetName')}
                      items={legendItems}
                      setItems={setLegendItems}
                      watch={watch}
                      values={datasetColumnValues}
                      errors={errors}
                      datasetColumn={layer?.datasetColumn}
                      isPointDataset={ true}
                      action={action}
                    />
                  )}
                </>
              )}
            </React.Fragment>
        </div>
      </Box>
    </Grid>
  );

  const actions = (
    <div style={{marginTop:'32px'}}>
      <MuiBtnClose
        onClick={onClose}
        color='primary'
        variant='text'
      >
        <Typography
          fontFamily='Montserrat'
          fontSize={14}
          letterSpacing={0.25}>
          {t('cancel_btn')}
        </Typography>
      </MuiBtnClose>
      <MuiBtnAction
        variant='outlined'
        onClick={onSubmit}
        color='primary'
        loading={false}
        disabled={(!isValid || !getDirty())}
        data-cy={'save_layer'}
      >
        <Typography  fontFamily='Montserrat' fontSize={14}  letterSpacing={0.25}>
          {t('save_changes')}
        </Typography>
      </MuiBtnAction>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }}>
        <Modal
          open={open}
          onClose={onClose}
          title={action === 'update' ? `${t('update')} ${layer?.name}` : `${t('new_layer')}`}
          actions={actions}
          widthInPixels={700}
        >
          <ThemeProvider theme={theme}>{formLayer}</ThemeProvider>
        </Modal>
      </form>
    </FormProvider>
  );
};

export default LayerForm;
