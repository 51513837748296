import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
  useTheme
} from '@mui/material';
import {  MoreVert } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setWidgetFoldedState, setWidgetsPrevState } from 'store/appSlice';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import { GLOBAL } from 'utils/constants';
import {
  displayFlex,
  MuiBox,
  MuiBoxRoot,
  MuiButton,
  MuiExpandLess,
  MuiExpandMore,
  MuiGridActions,
  MuiGridHeader,
  MuiIconAction,
  MuiLinearProgress,
  MuiTitle,
  optionsMenuStyle, popoverContainerStyle, popoverTextStyle
} from './styles/wrapperWidgetUI';

/*
Options props must have this format:
[
  { id: 'o0', name: 'Option 1', action: null },
  ...
];

Actions props must have this format:
[
  { id: 'a0', name: 'Autostyle', icon: '/icon-content-autostyle.svg', action: null },
  ...
];
*/
function useExpandedState(layerState, widgetId) {
  const widgetsState = useSelector((state) => state.app.widgets);
  const prevWidgetsState = useSelector((state) => state.app.widgetsPrevState);
  if (layerState){
      const expanded = useSelector((state) => {
        const widget = widgetsState.find((widget) => widget.id === widgetId);
        return widget ? false : null;
      });
      return expanded;
  }else{
    if (prevWidgetsState.length >0){
      const expanded = useSelector((state) => {
        const widget = prevWidgetsState.find((widget) => widget.id === widgetId);
        return widget ? !widget.folded_state : null;
      });
      return expanded;
    }else{
      const expanded = useSelector((state) => {
        const widget = widgetsState.find((widget) => widget.id === widgetId);
        return widget ? !widget.folded_state : null;
      });
      return expanded;
    }
  }
}

function WrapperWidgetUI(props) {
  const wrapper = createRef();
  const dispatch = useDispatch();
  const {
    disabled = true,
    options = [],
    actions = [],
    optionsIcon = <MoreVert />,
    disabledWidget,
    widgetId,
  } = props;
  const expanded = useExpandedState(disabledWidget, widgetId);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const iconAction = {
    color: theme.palette.text.secondary,
    margin: theme.spacing(-0.75, 0)
  }

  const handleExpandClick = () => {
    if (props.expandable) {
      dispatch(setWidgetFoldedState({ widgetId, foldedState: expanded}));
      dispatch(setWidgetsPrevState({condition: true, id: widgetId}));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionAction = (action) => {
    if (action) {
      action();
    }

    handleClose();
  };

  const iconButtonTooltip = (action, disabled) => {
    return (
      <MuiIconAction
        key={action.id}
        aria-label={action.label}
        onClick={action.action ? action.action : null }
        disabled={action.id == 'a1' ? action.active : false}
        color={action.color ?  action.color : 'primary'   }
      >
        {action.icon}
      </MuiIconAction>
    );
  };

  if (disabled) {
    return props.children;
  }

  return (
    <MuiBoxRoot component='section' aria-label={props.title} >
      {props.isLoading ? <MuiLinearProgress /> : null}
      <MuiGridHeader container expanded={expanded} >
        <MuiButton
          expandable={props.expandable}
          endIcon={
            props.expandable && (
              <Icon>
                {expanded ? (
                  <MuiExpandLess/>
                ) : (
                  <MuiExpandMore/>
                )}
              </Icon>
            )
          }
          onClick={handleExpandClick}
          disabled={disabledWidget}
        >
          <Tooltip title={props.title} placement='top' arrow>
            <MuiTitle
              expanded={expanded}
              align='left'
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: disabledWidget ? 'rgba(180,180,180,0.8)' : GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
              }}>
              {props.title}
            </MuiTitle>
          </Tooltip>
        </MuiButton>

        <MuiGridActions item>
          {actions.length > 0 &&
          actions.map((action) => {
            if ( action.type === 'info' && action.tooltip ) {
              return <TypographyWithInfoIconTooltip
                        className={displayFlex}
                        customBtnStyles={iconAction}
                        popoverContainerStyles={popoverContainerStyle}
                        popoverTextStyles = {popoverTextStyle}
                      >
                        { action.tooltip.text }
                      </TypographyWithInfoIconTooltip>
            }
            return action.tooltip ? (
              <Tooltip
                key={action.id}
                title={action.tooltip.text}
                placement={action.tooltip.placement || 'top'}
              >
                {iconButtonTooltip(action)}
              </Tooltip>
            ) : (
              iconButtonTooltip(action, disabledWidget)
            );
          })}

          {options.length > 0 && (
            <div>
              <MuiIconAction
                aria-label='options'
                aria-controls='options-menu'
                aria-haspopup='true'
                onClick={handleClick}
              >
                {optionsIcon}
              </MuiIconAction>
              <Menu
                id='options-menu'
                elevation={3}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{ className: optionsMenuStyle }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.id}
                    selected={option.selected}
                    onClick={() => handleOptionAction(option.action)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </MuiGridActions>
      </MuiGridHeader>
      {/* TODO: check collapse error */}
      <Collapse ref={wrapper} in={expanded} timeout='auto' unmountOnExit>
        <MuiBox id={'wrapper-widget-content'}>{props.children}</MuiBox>
      </Collapse>
    </MuiBoxRoot>
  );
}

WrapperWidgetUI.defaultProps = {
  expandable: true,
  isLoading: false,
  disabled: false
};

WrapperWidgetUI.propTypes = {
  title: PropTypes.string.isRequired,
  expandable: PropTypes.bool,
  onExpandedChange: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      icon: PropTypes.element,
      action: PropTypes.func
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ]),
  margin: PropTypes.number
};

export default WrapperWidgetUI;
