/* eslint-disable no-underscore-dangle */
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import { Typography, useTheme } from '@mui/material';
import HistogramChart from '../d3-charts/Histogram';
import detectTouchScreen from '../utils/detectTouchScreen';
import { generateDynamicShades } from 'utils/colors'
import { MuiLink, MuiOptionsSelectedBar } from '../styles/histogramWidgetUI';
import { GLOBAL } from '../../../../utils/constants';

const IS_TOUCH_SCREEN = detectTouchScreen();

// eslint-disable-next-line no-underscore-dangle
function __generateDefaultConfig(
  { dataAxis, tooltip, tooltipFormatter, xAxisFormatter = (v) => v, yAxisFormatter = (v) => v.toFixed(2) },
  data,
  theme
) {
  return {
    grid: {
      left: theme.spacing(0),
      top: theme.spacing(2),
      right: theme.spacing(0),
      bottom: theme.spacing(3),
    },
    brush: {
      toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
      xAxisIndex: 0,
    },
    toolbox: {
      show: true,
    },
    tooltip: {
      show: tooltip,
      trigger: 'axis',
      padding: [theme.spacing(0.5), theme.spacing(1)],
      textStyle: {
        ...theme.typography.caption,
        fontSize: 12,
        lineHeight: 16,
      },
      backgroundColor: theme.palette,
      position(point, params, dom, rect, size) {
        const position = { top: 0 };

        if (size.contentSize[0] < size.viewSize[0] - point[0]) {
          // eslint-disable-next-line prefer-destructuring
          position.left = point[0];
        } else {
          position.right = size.viewSize[0] - point[0];
        }
        return position;
      },
      ...(tooltipFormatter ? { formatter: tooltipFormatter } : {}),
    },
    color: [theme.palette.secondary.main],
    xAxis: {
      type: 'category',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        ...theme.typography,
        padding: [theme.spacing(0.5), 0, 0, 0],
        formatter: (v) => {
          const formatted = xAxisFormatter(v);
          return typeof formatted === 'object'
            ? `${formatted.prefix || ''}${formatted.value}${formatted.suffix || ''}`
            : formatted;
        },
      },
      data: dataAxis,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        margin: 0,
        padding: [
          0,
          0,
          theme.typography.fontSize * theme.typography.lineHeight + 4,
          0,
        ],
        show: true,
        showMaxLabel: true,
        showMinLabel: false,
        inside: true,
        color: (value) => {
          // FIXME: Workaround to show only maxlabel
          let col = 'transparent';
          const maxValue = Math.max(...data.map((d) => d || Number.MIN_SAFE_INTEGER));
          if (value > maxValue) {
            col = theme.palette;
          }

          return col;
        },
        ...theme.typography,
        formatter: (v) => {
          const formatted = yAxisFormatter(v);
          return typeof formatted === 'object'
            ? `${formatted.prefix}${formatted.value}${formatted.suffix || ''}`
            : formatted;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        onZero: false,
        lineStyle: {
          color: theme.palette,
        },
      },
    },
  };
}

// eslint-disable-next-line no-unused-vars
function __generateSerie(name, data, selectedBars = [], theme) {
  return [
    {
      type: 'bar',
      name,
      colorBy: 'data',
      data: data.map((value) => {
        const bar = {
          value: value,
          color: theme.palette.secondary.main,
        };
        return bar;
      }),
      barCategoryGap: 1,
      barMinWidth: '95%',
      ...(!IS_TOUCH_SCREEN && theme
        ? {
            emphasis: {
              itemStyle: {
                color: theme.palette.secondary.dark,
              },
            },
          }
        : {}),
    },
  ];
}

function HistogramWidgetUI(props) {
  const theme = useTheme();
  const {
    id,
    name,
    ticks,
    min,
    max,
    dataSource,
    data = [],
    dataAxis,
    onSelectedBarsChange,
    selectedBars,
    tooltip,
    tooltipFormatter,
    xAxisFormatter,
    yAxisFormatter,
    height = 96,
    filterable: _filterable,
    color,
  } = props;

  const chartInstance = useRef();
  const options = useMemo(() => {
    const config = __generateDefaultConfig(
      { dataAxis, tooltip, tooltipFormatter, xAxisFormatter, yAxisFormatter },
      data,
      theme
    );
    const series = __generateSerie(name, data, selectedBars, theme);
    return { ...config, series };
  }, [
    data,
    dataAxis,
    name,
    theme,
    tooltip,
    tooltipFormatter,
    xAxisFormatter,
    yAxisFormatter,
    selectedBars,
  ]);

  const dataSources = useSelector((state) => state.carto.dataSources);
  const clearBars = () => {
    const chart = chartInstance.current;
    onSelectedBarsChange({ bars: [] });
    chart.clear();
  };
  const clearBarsForKey = (keyToClear) => {
    if (keyToClear === dataSource) {
        const chart = chartInstance.current;
        onSelectedBarsChange({ bars: [] });
        chart.clear();
    }
};

React.useEffect(() => {
    // Get the keys from dataSources.
    const allKeys = Object.keys(dataSources);
    // Find keys in dataSources that are also in the allKeys list but don't have the 'filters' property.
    const specificKeysWithoutFilters = allKeys.filter(key =>
       ( !dataSources[key].hasOwnProperty('filters') || (dataSources[key]?.filters && Object.keys(dataSources[key]?.filters).length === 0) )
    );

    // If any of these specific keys don't have filters and match dataSource.id, then clear bars.
    specificKeysWithoutFilters.forEach(keyWithoutFilter => {
        clearBarsForKey(keyWithoutFilter);
    });

}, [dataSources]);



  // eslint-disable-next-line no-unused-vars
  const clickEvent = (params) => {
    // eslint-disable-next-line no-empty
    if (onSelectedBarsChange) {
    }
  };

  const brushEvent = (params) => {
    if (onSelectedBarsChange) {
      onSelectedBarsChange(params);
    }
  };

  const onEvents = {
    click: clickEvent,
    brushSelected: brushEvent,
  };

  return (
    <div>
      {onSelectedBarsChange && (
        <MuiOptionsSelectedBar
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='caption'>
            {selectedBars && selectedBars.length ? selectedBars.length : 'All'}
            {' '}
            selected
          </Typography>
          {selectedBars && selectedBars.length > 0 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <MuiLink onClick={() => clearBars()} underline="always">
              Clear
            </MuiLink>
          )}
        </MuiOptionsSelectedBar>
      )}
      {!!options && (
        <HistogramChart
          ref={chartInstance}
          id={id}
          selectedBars={selectedBars}
          option={options}
          onEvents={onEvents}
          height={height}
          color={color}
          primaryPaletteColors={generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY )}
        />
      )}
    </div>
  );
}

HistogramWidgetUI.defaultProps = {
  tooltip: true,
  tooltipFormatter: (v) => v,
  xAxisFormatter: (v) => v,
  yAxisFormatter: (v) => v,
  dataAxis: [],
  name: null,
  onSelectedBarsChange: null,
};

HistogramWidgetUI.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  tooltip: PropTypes.bool,
  tooltipFormatter: PropTypes.func,
  xAxisFormatter: PropTypes.func,
  yAxisFormatter: PropTypes.func,
  dataAxis: PropTypes.array,
  name: PropTypes.string,
  onSelectedBarsChange: PropTypes.func,
  selectedBars: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/require-default-props
  height: PropTypes.number,
  filterable: PropTypes.bool,

};

export default HistogramWidgetUI;
