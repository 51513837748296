import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles, IconButton, Tooltip, Box } from '@material-ui/core';
import { DeleteSweep as DeleteSweepIcon } from '@material-ui/icons';
import { clearFilters, removeSpatialFilter } from 'lib/react-geo-tool/packages/react-redux/src/';
import { clearWidgetColors, setListFilterPolygonData, setListPolygonWidgetForDelete, setListRecommendationsWidgetForDelete } from 'store/appSlice';
import { useTranslation } from 'components/providers/TranslationProvider';
import {GLOBAL} from 'utils/constants';

const useStyles = makeStyles((theme) => ({
	root: {
	  marginRight: '0px',
	  top: '10px',
	  right: '0px', 
	  transform: 'scale(1)',
	  display: 'flex',
	  alignItems: 'center',
	  backgroundColor: 'transparent',
	  borderRadius: theme.spacing(0.5),
	  position: 'absolute',
	  zIndex: '100',
	  marginTop: '25px',
	},
	typography: {
	  placeSelf: 'center',
	  display: 'flex',
	  justifyContent: 'space-around',
	  alignItems: 'center',
	  backgroundColor: theme.palette.common.white,
	  minHeight: theme.spacing(4.5),
	  margin: '0 1px',
	},
	btn: {
	  '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
	},
	searchInput: {
	  width: '500px',
	  maxWidth: '100%',
	  height: '45px',
	  outline: 'none',
	  fontSize: '20px',
	  border: '1px solid lightgray',
	  boxShadow: '0px 0px 5px 2px lightgrey',
	  paddingLeft: '5px',
	  color: 'rgb(81 80 80)'
	},
	searchButton: {
	  position: 'fixed',
	  zIndex: '100',
	  left: '10px',
	  marginTop: '25px',
	  backgroundColor: '#8b878763',
	  height: '45px',
	  width:'45px',
	  borderRadius: '10px !important'
	},
	closeIconButton : {
	  right: '35px',
	  zIndex:'1000',
	  background: theme.palette.primary.main,
	  border: 'solid 1px gray',
	  height: '30px',
	  width:'30px',
	  color: theme.palette.common.white,
	  '&:hover': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	  },
	},
	imgContainer: {
	  textAlign: 'right',
	  padding: '0.25rem 0.5rem'
	},
	imgGoogleMaps: {
	  width: 'auto',
	  height: '20px'
	},
	clearAllButton: {
		width: 40,
		height: 40,
		backgroundColor: 'white',
		borderRadius: 12,
		padding: '10px 16px 10px 16px',
		'&:hover': {
			backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[100], // Cambia el color de fondo a rojo al pasar el mouse
		},
		top: -9,
		position: 'absolute',
		zIndex: 100,
		marginLeft: 6,
		boxShadow: '0px 4px 8px 0px #00000026',
	},
	clarAllIcon: {
		fill: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900],
		width: 24,
		height: 24,
	}

  }));
 function CleanFiltersAndColors () {
  const classes = useStyles();
  const dataSources = useSelector((state) => state.carto.dataSources)
  const widgetId = useSelector((state) => state.app.selectWidgetId)
  const listFilters = useSelector((state) => state.app.listFilterPolygonData);
  const isWidgetsPanelVisible = useSelector((state) => state.app.isWidgetsPanelVisible);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClean = () => {
    for (let key in dataSources) {
        if (dataSources[key].id) {
            const idValue = dataSources[key].id;
            dispatch(clearFilters(idValue));
        }
    }
	dispatch(removeSpatialFilter());
	dispatch(setListFilterPolygonData({}));
	dispatch(setListPolygonWidgetForDelete({id:widgetId, selection:[]}));
	dispatch(setListRecommendationsWidgetForDelete({id:widgetId, selection:[]}));
    dispatch(clearWidgetColors());
}
  return (
	<div className={classes.root}>
		<Tooltip title={t('clean_filters_colors')}>
		<IconButton
			onClick={() => handleClean()}
			className={classes.clearAllButton}
			style={{ right: isWidgetsPanelVisible ? '502px' : '68px' }}
      	>
	  	<DeleteSweepIcon className={classes.clarAllIcon}/>
      </IconButton>
	  </Tooltip>
	</div>
  )
 }

 export default CleanFiltersAndColors