import * as React from 'react';
import {
  Grid,
  Container,
  Toolbar,
  Typography,
  Paper,
  Tabs,
  Tab,
} from '@mui/material';
import {useTranslation} from 'components/providers/TranslationProvider';

import {useDispatch} from 'react-redux';
import Layers from './layer/Layers';
import Tooltip from './tooltip/Tooltip';
import {
  containerSwitchStyle,
  containerTabsStyle, dividerStyle, MuiGridOthersTab,
  MuiOptionsTypography, MuiSwitchOthers,
  MuiSwitchTypography
} from './styles/tabsInternals';
import CopyToClipBoard from './CopyToClipBoard';
import Widgets from './widget/group/Widgets';
import {
  setShowDownloadLayer,
  setShowActionCatalog,
  setScenarioVisibility,
  setAllowUpgradeOperator
} from '../../../../../store/appSlice';
import {GLOBAL} from 'utils/constants';
import {useAuth} from 'components/providers/AuthProvider';
import { useTheme } from '../../../../providers/CustomThemeProvider';

const TabsInternals = ({
                         id,
                         schemeId,
                         mapId,
                         watchScenarioVisibility,
                         watchShowTooltipOnClick,
                         showDownloadLayer,
                         showActionCatalog,
                         scenarioVisibility,
                         allowUpgradeOperator
                       }) => {
  const [activeTab, setActiveTab] = React.useState('layers');
  const [indicatorWidth, setIndicatorWidth] = React.useState(0);

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {user} = useAuth();
  const {theme} = useTheme()

  const createTab = (label, value) => ({label, value});

  const initializeTabs = () => [
    createTab(t('layer'), 'layers'),
    createTab(t('tooltip'), 'tooltip'),
    createTab(t('tabs_widgets'), 'tabs_widgets'),
    createTab(t('others'), 'others'),
  ];

  const tabs = initializeTabs();

  React.useEffect(() => {
    const updateIndicatorWidth = () => {
      const selectedTab = document.querySelector('.Mui-selected');
      if (selectedTab) {
        setIndicatorWidth(selectedTab.offsetWidth);
      }
    };

    updateIndicatorWidth();

    window.addEventListener('resize', updateIndicatorWidth);
    return () => window.removeEventListener('resize', updateIndicatorWidth);
  }, [activeTab]);

  return (
    <Container disableGutters>
      <Toolbar
        disableGutters
        style={{ padding: '0px 16px'}}
      >
        <Grid container component={Paper} spacing={4} elevation={0}
              style={{ borderBottom: '1px solid #A1C1CF' }}>
          <Tabs
            sx={{
              '& .Mui-selected': {
                fontSize: 14,
                fontFamily: 'Montserrat',
                fontWeight: 500
              }
            }}
            value={activeTab}
            orientation='horizontal'
            indicatorColor='secondary'
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.secondary.main,
                height: 3,
                borderRadius: '10px 10px 0 0',
                transition: 'width 0.3s',
                marginBottom: theme.spacing(0),
                width: indicatorWidth > 100 ? indicatorWidth : 55,
                marginLeft: indicatorWidth > 100 ? 0 : 20
              }
            }}
          >
            {tabs.map((i) => (
              <Tab
                style={{
                  color: activeTab === i.value ? theme.palette.primary.main : '#424242',
                  fontSize: 14,
                  fontFamily: 'Montserrat',
                  fontWeight: 500
                }}
                label={i.label}
                value={i.value}
                key={i.value}
                onClick={() => setActiveTab(i.value)}
                data-cy={i.value}
              />
            ))}
          </Tabs>
        </Grid>
      </Toolbar>
      <Grid container style={containerTabsStyle}>
        {activeTab === 'layers' && <Layers/>}
        {activeTab === 'tabs_widgets' && <Widgets mapId={schemeId !== 'create' ? schemeId : mapId}/>}
        {activeTab === 'tooltip' &&
          <Tooltip watchShowTooltipOnClick={watchShowTooltipOnClick}/>}
        {activeTab === 'others' && (
          <>
            <Grid item xs={12} >
              <Typography
                variant='subtitle1'
                component="span"
                fontSize={16}
                style={{ marginLeft: '15px', color:'#616161'}}
                letterSpacing={0.15}
                fontWeight={400}
                fontFamily={'Raleway'}
              >
                {t('other_settings')}
              </Typography>
            </Grid>
            <MuiGridOthersTab item xs={12}>
              <MuiOptionsTypography variant='caption'>
                {t('set_public_private_scenario')}
              </MuiOptionsTypography>
              <div style={{ display:'flex' }}>
                <MuiSwitchOthers
                  name='scenarioVisibility'
                  onChange={() => {dispatch(setScenarioVisibility(!scenarioVisibility))}}
                  checked={scenarioVisibility}
                />
                <div style={containerSwitchStyle}>
                  <MuiSwitchTypography style={{ color:  '#000000' }}>
                    {t('key_no')}
                  </MuiSwitchTypography>
                  <span style={dividerStyle}>/</span>
                  <MuiSwitchTypography
                    style={{ color: theme.palette.primary.main }}
                  >
                    {t('key_yes')}
                  </MuiSwitchTypography>
                </div>
              </div>
            </MuiGridOthersTab>
            {(user.type === GLOBAL.ROLES.SUPERADMIN || user.type === GLOBAL.ROLES.ADMIN) && (
              <>
                <MuiGridOthersTab item xs={12}>
                  <MuiOptionsTypography variant='caption'>
                    {t('set_operator_widget')}
                  </MuiOptionsTypography>
                  <div style={{ display:'flex' }}>
                    <MuiSwitchOthers
                      name='upgradeOperator'
                      onChange={() => {dispatch(setAllowUpgradeOperator(!allowUpgradeOperator))}}
                      checked={allowUpgradeOperator}
                    />
                    <div style={containerSwitchStyle}>
                      <MuiSwitchTypography
                        style={{ color: '#000000' }}
                      >
                        {t('key_no')}
                      </MuiSwitchTypography>
                      <span style={dividerStyle}>/</span>
                      <MuiSwitchTypography
                        style={{ color: theme.palette.primary.main }}
                      >
                        {t('key_yes')}
                      </MuiSwitchTypography>
                    </div>
                  </div>
                </MuiGridOthersTab>
                <MuiGridOthersTab item xs={12}>
                  <MuiOptionsTypography variant='caption'>
                    {t('show_widget_catalog')}
                  </MuiOptionsTypography>
                  <div style={{ display:'flex' }}>
                    <MuiSwitchOthers
                      name='showActionCatalog'
                      onChange={() => {dispatch(setShowActionCatalog(!showActionCatalog))}}
                      checked={showActionCatalog}
                    />
                    <div style={containerSwitchStyle}>
                      <MuiSwitchTypography
                        style={{ color: '#000000' }}
                      >
                        {t('key_no')}
                      </MuiSwitchTypography>
                      <span style={dividerStyle}>/</span>
                      <MuiSwitchTypography
                        style={{ color: theme.palette.primary.main }}
                      >
                        {t('key_yes')}
                      </MuiSwitchTypography>
                    </div>
                  </div>
                </MuiGridOthersTab>
                <MuiGridOthersTab item xs={12}>
                  <MuiOptionsTypography variant='caption'>
                    {t('show_download_layer')}
                  </MuiOptionsTypography>
                  <div style={{ display:'flex' }}>
                    <MuiSwitchOthers
                      name='showDownloadLayer'
                      onChange={() => {dispatch(setShowDownloadLayer(!showDownloadLayer))}}
                      checked={showDownloadLayer}
                    />
                    <div style={containerSwitchStyle}>
                      <MuiSwitchTypography
                        style={{ color: '#000000' }}
                      >
                        {t('key_no')}
                      </MuiSwitchTypography>
                      <span style={dividerStyle}>/</span>
                      <MuiSwitchTypography
                        style={{ color: theme.palette.primary.main }}
                      >
                        {t('key_yes')}
                      </MuiSwitchTypography>
                    </div>
                  </div>
                </MuiGridOthersTab>
              </>
            )}
            {schemeId !== 'create' && watchScenarioVisibility && (
              <CopyToClipBoard id={id} schemeId={schemeId} type='custom'/>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default TabsInternals;
