import * as React from 'react';
import { Autocomplete, Grid, Paper, TextField, Chip, Typography } from '@mui/material';
import _ from 'lodash';

import ItemSortList from './ItemSortList';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from '../../../../../../../providers/TranslationProvider';
import {
  chipStyles, MuiCheckCircleIconDisabled,
  MuiGridItemContainer,
  MuiGridItemSort
} from '../styles/list';
import { MuiGridHeaders } from '../styles/stat';

const List = ({ columns, setNewOrder, realColumns, joinColunmToFormula=false,
                formula='', alias, deselectCounter, disabled, action, setIdForm,
                isFormula = false, calculator, setSelectedValues, columnSelect }) => {

const { t } = useTranslation();

return (
    <MuiGridItemContainer container justifyContent='center'>
      {!calculator && (
        <Grid item xs={12} justifyContent='center' style={{ marginBottom: 12}}>
          <AddItem  setNewOrder={setNewOrder} onChange={joinColunmToFormula}
                    formula={formula} realColumns={realColumns} alias={alias}
                    columns={columns} deselectCounter={deselectCounter}
                    disabled={disabled} isFormula={isFormula}/>
        </Grid>
      )}
      <MuiGridHeaders container item xs={12}>
        <Grid item xs={6} style={{marginLeft: '30px'}}>
          <Typography
            variant='caption'
            fontSize={12}
            letterSpacing={0.4}
            fontWeight={400}
            fontFamily={'Montserrat'}
          >
            {t('columns')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant='caption'
            fontSize={12}
            letterSpacing={0.4}
            fontWeight={400}
            fontFamily={'Montserrat'}
            style={{marginLeft: '-6.5rem'}}
          >
            {t('name')}
          </Typography>
        </Grid>
      </MuiGridHeaders>
      <MuiGridItemSort component={Paper}>
        <ItemSortList
          items={columns}
          setIdForm={setIdForm}
          calculator={calculator}
          setSelectedValues={setSelectedValues}
          action={action}
          setItems={setNewOrder}
          formula={formula}
          columns={columnSelect}
        />
      </MuiGridItemSort>
    </MuiGridItemContainer>
  );
};

const AddItem = ({ setNewOrder, realColumns = [], columns, onChange,
                   formula, alias, deselectCounter, disabled, isFormula}) => {

  const [selectedValues, setSelectedValues] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const filteredRealColumns =  React.useMemo( () => {
    const filteredValues = realColumns?.filter(( item ) => {
      return ( item !== 'the_geom_webmercator' &&  item !== 'geom' && item !== 'cluster_id' )
    });
    return filteredValues;
  }, [realColumns]);

  const handleDeselectAll = () => {
    setSelectedValues([]);
    setNewOrder([]);
  };

  React.useEffect(() => {
    if (deselectCounter) {
      handleDeselectAll();
    }
  }, [deselectCounter]);

  React.useEffect(() => {
    if (formula != '') {
      let gridContainer = document.getElementById('gridContainer');
      let divsElements = gridContainer.getElementsByTagName('div');
      for (let i = 0; i < divsElements.length; i++) {
        divsElements[i].addEventListener('click', function(e) {
          if (e.target.textContent == selectedValues[i]) {
            onChange(e.target.textContent);
          }
        });
      }
    }
  }, [formula]);

  const handleChange = (event, values) => {
    if(values[selectedValues.length] !== undefined && onChange !== false) onChange(values[selectedValues.length])
    const addOrRemove = (prevState) => {
      const valuesObj = values.map((value) => ({ field: value }));

      const difference = _.differenceBy(prevState, valuesObj, 'field');
      if (prevState.length > values.length) {

        return prevState.filter(({ name }) => !difference.find((n) => name === n.name));
      }

      return prevState?.length
        ? [
            ...prevState,
            {
              name:  event.target.textContent.match(/^(formula\d+)_/) ? event.target.textContent.match(/^(formula\d+)_/)[1] : event.target.textContent,
              field: event.target.textContent,
              visible: true,
              id: Date.now().toString(36) + Math.random().toString(36),
            },
          ]
        : [
            {
              name:  event.target.textContent.match(/^(formula\d+)_/) ? event.target.textContent.match(/^(formula\d+)_/)[1] : event.target.textContent,
              field: event.target.textContent,

              visible: true,
              id: Date.now().toString(36) + Math.random().toString(36),

            },
          ];
    };
    setNewOrder((prevVal) => addOrRemove(prevVal));
    if(!isFormula){
      setSelectedValues(values);
    }
  };

  React.useEffect(() => {
    setSelectedValues(columns?.map(({ field }) => field) || []);
  }, [columns]);

  return (
      <Grid container id='gridContainer'>
        <Autocomplete
          id='organizations-id'
          multiple
          disabled={disabled}
          options={ filteredRealColumns.map((option) => option) || []}
          getOptionLabel={(option) => option}
          getOptionSelected={(option, value) => option === value}
          filterSelectedOptions
          value={selectedValues}
          onChange={(event, values) => {
            handleChange(event, values);
          }}
          inputValue={inputValue}
          onInputChange={(__, newInputValue) => {
            setInputValue(newInputValue);
          }}
          fullWidth
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option}
                label={option}
                {...getTagProps({ index })}
                deleteIcon={<CloseIcon style={{ color: '#254957'}} />}
                sx={chipStyles}
              />
            ))
          }
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                style={{width:'890px'}}
                label='Please select one or more columns to add'
                name='columns'
                variant='outlined'
                fullWidth
              />
              <MuiCheckCircleIconDisabled fontSize='medium' />
            </>
          )}
        />
      </Grid>
  );
};

export default List;
