import {
  Box,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import {
  AccessTime, Landscape,
  LocationCity,
  SquareFoot
} from '@mui/icons-material';
import { generateDynamicShades } from '../../../../utils/colors';
import { GLOBAL } from '../../../../utils/constants';

export const MuiCommonTypography = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  color: '#325D6C',
  fontWeight:400
}));

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  width: '312px',
  height: '32px',
  backgroundColor: `${lighten( GLOBAL.COLOR_THEME.PRIMARY , 0.9)}`,
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center'
}));

export const MuiToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: `1px solid ${generateDynamicShades(GLOBAL.COLOR_THEME.PRIMARY)[500]}`,
  borderRadius: '14px !important',
  backgroundColor:`${lighten( GLOBAL.COLOR_THEME.PRIMARY , 0.8)}`,
  '& .MuiToggleButtonGroup-grouped':{
    height: 26,
    border: 0,
    color: GLOBAL.COLOR_THEME.PRIMARY,
  },
  '& .Mui-selected':{
    borderRadius: '12px !important',
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonGroupLocation = styled(ToggleButtonGroup)(({ theme }) => ({
  border: `1px solid ${generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY )[500]}`,
  backgroundColor:`${lighten( GLOBAL.COLOR_THEME.PRIMARY , 0.8)}`,
  borderRadius: '14px !important',
  '& .MuiToggleButtonGroup-grouped':{
    height: 25,
    border: 0,
    color: GLOBAL.COLOR_THEME.PRIMARY,
  },
  '& .Mui-selected':{
    borderRadius: '12px !important',
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonGroupRouting = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '312px',
  height: '62px',
  border: `1px solid ${generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY )[500]}`,
  backgroundColor:`${lighten( GLOBAL.COLOR_THEME.PRIMARY , 0.8)}`,
  '& .MuiToggleButtonGroup-grouped':{
    height: '60px !important',
    border: 0,
    color: GLOBAL.COLOR_THEME.PRIMARY,
  },
  '& .Mui-selected':{
    height: '60px !important',
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonDistance = styled(ToggleButton)(({ theme }) => ({
  width: '150px',
  height: '26px',
  borderRadius: '12px',
  marginLeft: '0px !important',
  color: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  fontFamily: 'Montserrat',
  fontSize: '12px',
  '& .Mui-selected':{
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonTime = styled(ToggleButton)(({ theme }) => ({
  width: '150px',
  height: '26px',
  borderRadius: '12px',
  color: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  fontFamily: 'Montserrat',
  fontSize: '12px',
  marginRight: '0px !important',
  '& .Mui-selected':{
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonItem = styled(ToggleButton)(({ theme }) => ({
  color: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  borderColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  fontFamily: 'Montserrat',
  fontSize: '12px',
  marginRight: '0px !important',
  marginLeft: '0px !important',
  '& .Mui-selected':{
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonUrban = styled(ToggleButton)(({ theme }) => ({
  width: '150px',
  height: '26px',
  borderRadius: '12px',
  color: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  fontFamily: 'Montserrat',
  fontSize: '12px',
  marginLeft: '3px !important',
  marginTop: '2px !important',
  '& .Mui-selected':{
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonRural = styled(ToggleButton)(({ theme }) => ({
  width: '150px',
  height: '26px',
  borderRadius: '12px',
  color: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  fontFamily: 'Montserrat',
  fontSize: '12px',
  marginRight: '3px !important',
  marginTop: '2px !important',
  '& .Mui-selected':{
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiSquareFoot = styled(SquareFoot)(({ theme }) => ({
  width: '16px' ,
  height: '16px',
  marginRight:'5px !important'
}));

export const MuiAccessTime = styled(AccessTime)(({ theme }) => ({
  width: '16px' ,
  height: '16px',
  marginRight:'5px !important'
}));

export const MuiLocationCity = styled(LocationCity)(({ theme }) => ({
  width: '16px' ,
  height: '16px',
  marginRight:'5px !important'
}));

export const MuiLandscape = styled(Landscape)(({ theme }) => ({
  width: '16px' ,
  height: '16px',
  marginRight:'5px !important'
}));

export const toggleGroup = {
  width: '312px',
  height: '62px'
}

export const toggle3 = {
  width: '104px',
  height: '62px',
}
