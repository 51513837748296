import * as React from 'react';
import {
  TableContainer,
  Grid,
  Typography
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useLocation, useNavigate } from 'react-router-dom';

import useSchemes from 'api/hooks/useSchemes';
import { usePermissions } from 'components/hooks/usePermissions';
import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { giveMeId } from 'utils/supportComponents';
import {
  MuiCommonTypography,
  MuiContainer,
  MuiGridContainer,
  MuiGridTitle,
  MuiTypographyTitle
} from '../styles/scenario.js';
import NewScenario from './NewScenario.js';
import Table from '../common/Table';
import DeleteScenario from './DeleteScenario';
import CopyModal from './CopyModal';
import Toast from 'components/common/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import TabsCustoms from '../common/TabCustoms.js';
import {MuiButton} from "../styles/dataset";
import TPagination from "../common/TPagination";

const Scenarios = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const query = new URLSearchParams(location.search);

  const { user } = useAuth();
  const { t } = useTranslation();
  const { hasPermissionFor } = usePermissions();

  const id = giveMeId(location.pathname, user);
  const { data, isLoading } = useSchemes(id);
  const dispatch = useDispatch();
  const openToast= useSelector((state) => state.app.openToast);
  const messageToast = useSelector((state) => state.app.messageToast);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [dateRange, setDateRange] = React.useState('')
  const columns = [t('name'),t('created_at'), t('actions')];

  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));

  const row = ['name', 'createdAt'];

  const handleDelete = (idScheme, scheme) =>
    navigate(`${location.pathname}?delete=${idScheme}`, { replace: true, state: scheme });

  const handleForward = (idScheme, scheme) => {
    switch (scheme.type) {
      case 'embedded':
        navigate(`${location.pathname}/view/public/${idScheme}`);
        break;
      case 'descriptive-spatial-analysis':
        navigate(`${location.pathname}/view/custom/${idScheme}`, { state: scheme });
        break;
      default:
        navigate(`${location.pathname}/view/custom/${idScheme}`, { state: scheme });
        break;
    }
  };

  const handleSettings = (idScheme, scheme) => {
      navigate(`${location.pathname}/${idScheme}/specification`, { state: scheme });
  };

  const handleCopyScheme = () =>
    navigate(`${location.pathname}?copy=map`, {
      replace: true,
    });

  React.useEffect(() => {
    const createQuery = query.get('copy');
    setOpen(createQuery === 'map');
  }, [query]);

  const handleClose = (event, reason) => {
    if ( reason === 'backdropClick' || reason === 'escapeKeyDown' ) {
      setOpen(true);
    } else {
      query.delete('copy');
      navigate(`${location.pathname}${query && ''}${query}`, { replace: true });    }
  };

  const closeToast = () =>{
    dispatch(setOpenToast(false));
    dispatch(setMessageToast(''));
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));
  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));

  const handleFirstPage = () => setPage(0);
  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };

  const filteredData = data
    ? data?.schemes.filter((item) => {
      let matchesSearchTerm = true;
      let matchesDateRange = true;

      if (dateRange !== '' && item.createdAt !== '') {
        const [date] = item.createdAt.split(',');
        const [itemDay, itemMonth, itemYear] = date.split('/').map(Number);
        const [initDay, initMonth, initYear] = dateRange.initDate.split('/').map(Number);
        const [endDay, endMonth, endYear] = dateRange.endDate.split('/').map(Number);

        const itemDate = new Date(itemYear, itemMonth - 1, itemDay);
        const startDate = new Date(initYear, initMonth - 1, initDay);
        const endDate = new Date(endYear, endMonth - 1, endDay);

        matchesDateRange = itemDate >= startDate && itemDate <= endDate;
      }else if (dateRange !== '' && item.createdAt === ''){
        return false;
      }

      const lowerCaseSearchTerm = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.name} ${item.createdAt}`.toLowerCase();
      matchesSearchTerm = lowerCaseSearchTerm.every((term) => itemString.includes(term));
      return matchesSearchTerm && matchesDateRange;
    })
    : [];
  const handleSearch = (event, index) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[index] = event.target.value;
    setSearchTerm(newSearchTerms);
    setPage(0);
  };

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <MuiContainer>
      <Toast
        severity='error'
        message={messageToast}
        open={openToast}
        handleClose={() => closeToast()}
        horizontal='right'
        vertical='top'
      />
      <TabsCustoms/>
      <MuiGridContainer container spacing={2}>
        <MuiGridTitle item xs={5}>
          <MuiTypographyTitle variant='h6'>
            {t('scenarios')}
          </MuiTypographyTitle>
        </MuiGridTitle>
        <Grid item xs={7}>
          {hasPermissionFor('update_schemes') && (
            <MuiButton
              variant='outlined'
              sx={{marginRight:'4px'}}
              color='primary'
              startIcon={<FileCopyIcon sx={{fontSize: '3px'}}/>}
              onClick={handleCopyScheme}
            >
              <MuiCommonTypography variant='body2'>
                {t('copy_schemes')}
              </MuiCommonTypography>
            </MuiButton>
          )}
          {hasPermissionFor('create_schemes') && <NewScenario  />}
        </Grid>
      </MuiGridContainer>
      <DeleteScenario />
      <TableContainer >
        <Table
          data={ paginatedData }
          row={row}
          columns={columns}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          onDelete={handleDelete}
          showDelete={hasPermissionFor('delete_schemes')}
          onSettings={handleSettings}
          showSettings={hasPermissionFor('update_schemes')}
          onForward={handleForward}
          isLoading={isLoading}
          setDateRange={setDateRange}
        />
      </TableContainer>
      <Grid container direction="column" alignItems="flex-start">
        <TPagination
          data={filteredData}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleFirstPage={handleFirstPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleLastPage={handleLastPage}
        />
      </Grid>
      {open && <CopyModal action='create' open={open} onClose={handleClose} />}
    </MuiContainer>
  );
};

export default Scenarios;
