import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';


import { useTranslation } from 'components/providers/TranslationProvider';
import { usePermissions } from 'components/hooks/usePermissions';

import FormModal from './FormModal';

import { MuiButton } from '../styles/user';

const NewUser = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('create');
    setOpen(createQuery === 'user');
  }, [query]);

  const handleClose = () => {
    query.delete('create');
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  return (
    <>
      {usePermissions('create_users') && (
        <MuiButton
          variant='outlined'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() =>
            navigate(`${location.pathname}?create=user`, {
              replace: true,
            })
          }
        >
          {t('new_user')}
        </MuiButton>
      )}
      {open && <FormModal action='create' open={open} onClose={handleClose} />}
    </>
  );
};
export default NewUser;
