import { 
    makeStyles ,
    Grid,
    Divider,
    MenuItem,
    Box,
    Typography,
    Switch,
} from '@material-ui/core';
import {
    ColorField,
    TextField as TextFieldCustom,
    CheckboxField,
  } from 'components/common/ReactHooksFormFields';
import {ReactSVG} from 'react-svg';
import styles from './styles/recommendations';
import { useTranslation } from 'components/providers/TranslationProvider';
import { switchCommonStyles } from '../styles/category';
import {
  inputColorFieldStyle,
  textFieldCommonStyle
} from '../../../layer/form/styles/layerForm';

import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput
} from '@mui/material';
import { iconsName } from '../../../../mapsHandlers/layer/form/iconsName';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTheme } from 'components/providers/CustomThemeProvider';

const useStyles = makeStyles(styles);
const useSwitchStyles = makeStyles(switchCommonStyles);

const RecommendationsVisualization = ( props ) => {
    const {
        isFormulaFieldSelected,
        setValue,
        isOfferSelected,
        recommendedPointLayerColor,
        action,
        defaultIcon,
        valueRecommendedIcons,
        handleInputIcon,
        isGlobal,
        allowsRegenerateServicePoint,
        progress,
      } = props;

    const { t } = useTranslation();
    const classes = useStyles({ isFormulaFieldSelected, t });
    const switchCommon = useSwitchStyles();
    const { theme } = useTheme();

    const selectStyle = {
      height: '56px',
      width: '90px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#757575',
      },
      '& .MuiListItem-root': {
        minHeight: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    };

    const divMenuItemStyle = {
      borderRadius: '16px',
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 4,
    };

    return (
      <Grid item xs={12} className={ `${classes.elementContainer} ${classes.removePaddingsX }`}>
                    <Grid container spacing={1} alignItems='center' className={classes.removePaddingsX}>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <TextFieldCustom
                              name='recommendedPointLayerName'
                              type='text'
                              margin='dense'
                              label={t('recommendations_points_layer_name')}
                              fullWidth
                              variant='outlined'
                              className={classes.layerName}
                              color='primary'/>
                          </Box>
                          <Box>
                            <TextFieldCustom
                                fullWidth
                                style={{ ...textFieldCommonStyle, marginTop: 17}}
                                className={classes.itemsSeparator}
                                name="recommendedPointLayerColor"
                                label={t('background_color')}
                                variant="outlined"
                                InputLabelProps={{
                                  style: { color: '#424242' }
                                }}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <ColorField
                                      name="recommendedPointLayerColor"
                                      style={inputColorFieldStyle}
                                    />
                                  ),
                                  endAdornment: (
                                    <Typography className={classes.colorSystem}>HEX</Typography>
                                  ),
                                }}
                              />
                          </Box>
                          <Box>
                            <TextFieldCustom
                              name='recommendedPointLayerOpacity'
                              type='number'
                              min={0}
                              max={100}
                              margin='dense'
                              label={t('recommendations_layer_opacity')}
                              fullWidth
                              variant='outlined'
                              className={classes.layerOpacity}
                              style={{ marginTop: 17}}
                              InputProps={{
                                endAdornment: (
                                  <Typography className={classes.colorSystem}>%</Typography>
                                ),
                                min: 0,
                                max: 100,
                                defaultValue: 100,
                              }}
                              color='primary'/>
                          </Box>
                          <Box>
                            <FormControl variant="outlined" fullWidth style={{ marginTop: 13 }} >
                                <InputLabel id="icon-select-label"
                                            htmlFor="icon-select"
                                            sx={{ color: '#424242' }}
                                >{t('select')}</InputLabel>
                                <Select
                                  labelId="icon-select-label"
                                  id="icon-select"
                                  defaultValue={ action === 'create' ? defaultIcon : valueRecommendedIcons.split('.')[0] }
                                  onChange={handleInputIcon}
                                  input={<OutlinedInput label={ t('choose_an_icon') } />}
                                  fullWidth
                                  sx={selectStyle}
                                  label="Icono"
                                >
                                {iconsName.map((key) => (
                                  <MenuItem value={key.name}>
                                    <div
                                    style={{
                                      ...divMenuItemStyle,
                                      background: action === 'create' ? theme.palette.primary.main
                                                                      : recommendedPointLayerColor,
                                    }}
                                    >
                                      <ReactSVG
                                        src={`${process.env.PUBLIC_URL}/maki-icons/${key.name}.svg`}
                                        beforeInjection={(svg) => {
                                          svg.classList.add('svg-class-name');
                                          svg.setAttribute('style',
                                            'margin-top:6px; width: 100%; height: 100%;');
                                        }}
                                        afterInjection={(svg) => {
                                          const path = svg.querySelector('path');
                                          if (path) {
                                            path.setAttribute('fill', 'white');
                                          }
                                        }}
                                      />
                                    </div>
                                  </MenuItem>
                                ))}
                              </Select>
                              </FormControl>
                            </Box>
                        </Box>
                      </Grid>
                    </Grid>


                    <Grid container spacing={1} alignItems='center'>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Box className={classes.layerName} display="flex" alignItems="center">
                              <Typography
                            className={`${classes.typography} ${classes.recommendedAreaLayerLabel}`}> {t('recommendations_layer_area')}</Typography>
                          </Box>
                          <Box>
                          <TextFieldCustom
                                fullWidth
                                style={textFieldCommonStyle}
                                className={classes.itemsSeparator}
                                name="recommendedAreaLayerColor"
                                label={t('background_color')}
                                variant="outlined"
                                InputLabelProps={{
                                  style: { color: '#424242' }
                                }}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <ColorField
                                      name="recommendedAreaLayerColor"
                                      style={inputColorFieldStyle}
                                    />
                                  ),
                                  endAdornment: (
                                    <Typography className={classes.colorSystem}>HEX</Typography>
                                  ),
                                }}
                              />
                          </Box>
                          <Box justifyContent="center">
                            <TextFieldCustom
                              name='recommendedAreaLayerOpacity'
                              type='number'
                              margin='dense'
                              label={t('recommendations_layer_opacity')}
                              fullWidth
                              variant='outlined'
                              className={classes.layerOpacity}
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              InputProps={{
                                endAdornment: (
                                  <Typography className={classes.colorSystem}>%</Typography>
                                ),
                              }}
                              color='primary'/>
                          </Box>
                          <Box>
                            <CheckboxField
                              name='recommendedAreaLayerVisibility'
                              icon={<VisibilityOffIcon classes={{ root: classes.visibilityIconSize }}/>}
                              checkedIcon={<VisibilityIcon classes={{ root: classes.visibilityIconSize }}/>}
                              style={{ borderRadius: '50%', border: `1px solid ${theme.palette.primary.main}` }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item xs={12}>
                        <Divider style={{ marginBottom: 32, marginTop: 32 }} />
                      </Grid>
                    </Grid>
                    {isOfferSelected && (
                      <Grid container spacing={1} alignItems='center'>
                        <Grid item xs={12}>
                        <Typography className={classes.offerSubHeader}>{ t('offer_sub_header') }</Typography>
                          <Box display="flex" alignItems="center">
                            <Box className={classes.layerName} display="flex" alignItems="center">
                              <Typography
                                  className={`${classes.typography} ${classes.currentOfferAreaLayerLabel}`}>{ t('current_offer_layer_area_label') }</Typography>
                            </Box>
                            <Box>
                            <TextFieldCustom
                                fullWidth
                                style={textFieldCommonStyle}
                                className={classes.itemsSeparator}
                                name="currentOfferAreaLayerColor"
                                label={t('background_color')}
                                variant="outlined"
                                InputLabelProps={{
                                  style: { color: '#424242' }
                                }}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <ColorField
                                      name="currentOfferAreaLayerColor"
                                      style={inputColorFieldStyle}
                                    />
                                  ),
                                  endAdornment: (
                                    <Typography className={classes.colorSystem}>HEX</Typography>
                                  ),
                                }}
                              />
                            </Box>
                            <Box justifyContent="center">
                              <TextFieldCustom
                                name='currentOfferAreaLayerOpacity'
                                type='number'
                                margin='dense'
                                label={t('recommendations_layer_opacity')}
                                fullWidth
                                variant='outlined'
                                className={classes.layerOpacity}
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                InputProps={{
                                  endAdornment: (
                                    <Typography className={classes.colorSystem}>%</Typography>
                                  ),
                                }}
                                color='primary'
                              />
                            </Box>
                            <Box>
                            <CheckboxField
                              name='currentOfferAreaLayerVisibility'
                              icon={<VisibilityOffIcon classes={{ root: classes.visibilityIconSize }} />}
                              checkedIcon={<VisibilityIcon classes={{ root: classes.visibilityIconSize }} />}
                              style={{ borderRadius: '50%', border: `1px solid ${theme.palette.primary.main}` }}
                            />
                            </Box>
                          </Box>
                          <Divider style={{ marginBottom: 32, marginTop: 32 }} />
                        </Grid>
                      </Grid>)}
                    <Grid container
                          spacing={1} alignItems='center'>
                      <Grid xs={6} item>
                        <Box display="flex" alignItems="center">
                          <Typography
                            className={classes.isGlobalSwitchSpacing}>{t('recommendations_is_global')} </Typography>
                          <Switch
                            name='isGlobal'
                            checked={isGlobal}
                            onChange={(e) => setValue("isGlobal", e.target.checked)}
                            inputProps={{'aria-label': 'controlled'}}
                            color='primary'
                            disabled={action === 'update' || progress > 0}
                            defaultChecked
                            className={`${classes.isGlobalSwitch} ${classes.isGlobalSwitchSpacing}`}
                            classes={{
                              root: switchCommon.root,
                              switchBase: switchCommon.switchBase,
                              thumb: switchCommon.thumb,
                              track: switchCommon.track,
                              checked: switchCommon.checked,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={6} item>
                      <Box display="flex" alignItems="center">
                      <Typography
                          className={`${classes.isGlobalSwitchSpacing} ${classes.allowsRegenerateInput}`}>{t('recomendation_allows_regenerate_service_point')} </Typography>
                        <Switch
                          name='allowsRegenerateServicePoint'
                          checked={allowsRegenerateServicePoint}
                          onChange={(e) => setValue("allowsRegenerateServicePoint", e.target.checked)}
                          inputProps={{'aria-label': 'controlled'}}
                          color='primary'
                          defaultChecked
                          className={`${classes.isGlobalSwitch} ${classes.isGlobalSwitchSpacing}`}
                          classes={{
                            root: switchCommon.root,
                            switchBase: switchCommon.switchBase,
                            thumb: switchCommon.thumb,
                            track: switchCommon.track,
                            checked: switchCommon.checked,
                          }}
                        />
                      </Box>
                      </Grid>
                    </Grid>
                  </Grid>
    );
};

export default RecommendationsVisualization;