import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

const CustomSkeleton = ({ className, width, height, variant = 'text' }) => (
  <Skeleton
    className={className}
    variant={variant}
    animation='wave'
    width={width}
    style={{
      width: width || '100%',
      height: height || 96,
    }}
  />
);

export default CustomSkeleton;
