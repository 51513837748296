import { ToggleButtonGroup, styled, ToggleButton } from '@mui/material';
import { generateDynamicShades } from '../../../utils/colors';
import { GLOBAL } from '../../../utils/constants';
import { lighten } from '@mui/material/styles';

export const MuiToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  height: 26,
  width: '120px',
  borderRadius: 16,
  border: `1px solid ${generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY )[500]}`,
  backgroundColor: `${generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY )[50]}`,
  '& .MuiToggleButtonGroup-grouped':{
    height: 22,
    borderRadius: 16,
    padding: '0px 1px 0px 1px',
    backgroundColor: `${generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY )[50]}`,
  },
  '& .Mui-selected':{
    borderRadius: '12px !important',
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '32px',
  height: '20px',
  borderRadius: '12px',
  color: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  '& .Mui-selected':{
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const groupedHorizontalStyle = {
  borderRadius: '16px !important'
};

export const selectedStyle = {
  backgroundColor: `${generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY )[500]} !important`,
  color: `${GLOBAL.COLOR.WHITE} !important`,
};

export const toggleButtonStyle = {
  borderRadius: '16px',
  width: 31.71,
  height: 24,
  margin: 'auto',
  border: 0,
};
