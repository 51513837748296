import * as React from 'react';
import { makeStyles, Grid, IconButton, Popover, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { hexToRgb } from 'utils/colorConverter';

const stylesTabCell = makeStyles(() => ({
  text: {
    fontSize: 15,
    minWidth: 100,
    marginTop: 3,
    marginBottom: 3,
    borderRadius: 2,
    '&:hover': {
      background: '#efefef',
    },
  },
}));

const TabCell = ({ row, tabs, setRows, setWidgets }) => {
  const classes = stylesTabCell();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (tab, active) => {
    if (!active) {
      setRows((prevS) =>
        prevS.map((r) => (row.id === r.id ? { ...r, active_tab: tab.id, tab: tab.name } : r))
      );
      setWidgets((prevS) =>
        prevS.map((w) => (row.id === w.id ? { ...w, active_tab: tab.id, tab: tab.name } : w))
      );
      setAnchorEl(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Grid container>
      <Grid
        item
        xs={10}
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {row.tab}
      </Grid>
      <Grid item xs={2}>
        <IconButton
          aria-owns={open ? 'on-click-popover' : undefined}
          onClick={handlePopoverOpen}
          style={{ transform: 'scale(0.7)' }}
          disabled={!row.visible}
        >
          <ArrowDropDownIcon color={row.visible ? 'secondary' : 'disabled'} />
        </IconButton>
        <Popover
          id='on-click-popover'
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Grid
            container
            style={{
              padding: 8,
              display: 'grid',
              justifyContent: 'center',
              minWidth: 100,
            }}
          >
            {tabs?.map((tab) => (
              <Typography
                key={tab.id}
                disabled={tab.id === row.active_tab}
                className={classes.text}
                align='center'
                noWrap
                onClick={() => handleSelect(tab, tab.id === row.active_tab)}
                style={{
                  cursor: tab.id === row.active_tab ? 'default' : 'pointer',
                  background:
                    tab.id === row.active_tab &&
                    `rgb(${
                      hexToRgb(localStorage.getItem('colorPrimary'))?.join(', ') || '128, 128, 128'
                    },0.1)`,
                }}
              >
                {tab.name}
              </Typography>
            ))}
          </Grid>
        </Popover>
      </Grid>
    </Grid>
  );
};
export default TabCell;
