import * as React from 'react';
import { TableContainer, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import Toast from 'components/common/Toast';
import { useQueryClient } from 'react-query';
import useDataSetsOrg from 'api/hooks/useDataSetsOrg';
import { usePermissions } from 'components/hooks/usePermissions';
import { useTranslation } from 'components/providers/TranslationProvider';

import { giveMeId } from 'utils/supportComponents';
import { useAuth } from 'components/providers/AuthProvider';
import useSyncDataset from 'api/hooks/useSyncDataset';

import Table from '../common/Table';
import FormModal from './FormModal';
import DeleteDataset from './DeleteDataset';
import {
  MuiButton,
  MuiContainer, MuiGrid, MuiGridContainer,
  MuiGridTitle,
  MuiTypographyTitle
} from '../styles/dataset';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import TabsCustoms from '../common/TabCustoms';
import TPagination from "../common/TPagination";
const row = ['prosperiaName', 'description', 'createdAt', 'usageIncidencesNumber', 'cartoGeometryType'];

const NewDataset = (props) => {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('create');
    setOpen(createQuery === 'map');
  }, [query]);

  const handleClose = () => {
    query.delete('create');
    queryClient.invalidateQueries(['schemes', props.id])
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  return (
    <>
      <MuiButton
        variant='outlined'
        color='primary'
        startIcon={<AddIcon />}
        data-cy='upload_dataset'
        onClick={() =>
          navigate(`${location.pathname}?create=map`, {
            replace: true,
          })
        }
      >
        {t('upload_new_dataset')}
      </MuiButton>
      {open && <FormModal action='create' open={open} onClose={handleClose}/>}
    </>
  );
};

const Dataset = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { mutateAsync, isSuccess } = useSyncDataset();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [toastSeverity, setToastSeverity] = React.useState('error');

  const dispatch = useDispatch();
  const openToast= useSelector((state) => state.app.openToast);
  const messageToast = useSelector((state) => state.app.messageToast);

  const columns = [t('name'), t('local_filename'), t('created_at'), t('dependencies'), t('type'), t('actions')];
  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [dateRange, setDateRange] = React.useState('')

  const handleSync = (id, value) => {
    const data = {
      name: value,
    };
    mutateAsync(data);
  };


  const handleDelete = (id, data) =>
    navigate(`${location.pathname}?delete=${id}`, { replace: true, state: data });
    const handleSearch = (event, index) => {
      const newSearchTerms = [...searchTerm];
      newSearchTerms[index] = event.target.value;
      setSearchTerm(newSearchTerms);
    };
  React.useEffect(() => {
    if (isSuccess) {
      dispatch(setOpenToast(true));
      dispatch(setMessageToast(t('carto_sync_success')));
      setToastSeverity('success');
    }
  }, [isSuccess]);

  const { data, isLoading, refetch: refetchDatasets } = useDataSetsOrg(giveMeId(location.pathname, user));
  const { hasPermissionFor } = usePermissions();

  const closeToast = () =>{
    dispatch(setOpenToast(false));
    dispatch(setMessageToast(''));
    setToastSeverity('error');
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));
  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));

  const handleFirstPage = () => setPage(0);
  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };
  const filteredData = data
    ? data?.filter((item) => {
      let matchesSearchTerm = true;
      let matchesDateRange = true;

      if (dateRange !== '' && item.createdAt !== '') {
        const [date] = item.createdAt.split(' ')
        const [itemDay, itemMonth, itemYear] = date.slice(0, -1).split('/').map(Number);
        const [initDay, initMonth, initYear] = dateRange.initDate.split('/').map(Number);
        const [endDay, endMonth, endYear] = dateRange.endDate.split('/').map(Number);

        const itemDate = new Date(itemYear, itemMonth - 1, itemDay);
        const startDate = new Date(initYear, initMonth - 1, initDay);
        const endDate = new Date(endYear, endMonth - 1, endDay);

        matchesDateRange = itemDate >= startDate && itemDate <= endDate;
      }else if (dateRange !== '' && item.createdAt === ''){
        return false;
      }

      const lowerCaseSearchTerm = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.prosperiaName} ${item.description} ${item.createdAt}`.toLowerCase();
      matchesSearchTerm = lowerCaseSearchTerm.every((term) => itemString.includes(term));
      return matchesSearchTerm && matchesDateRange;
    })
    : [];

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <MuiContainer>
        <Toast
          severity={toastSeverity}
          message={messageToast}
          open={openToast}
          handleClose={() => closeToast()}
          horizontal='right'
          vertical='top'
        />
        <TabsCustoms />
        <MuiGridContainer>
          <MuiGrid container>
            <MuiGridTitle item xs={8}>
              <MuiTypographyTitle variant='h6'>
                {t('datasets_list')}
              </MuiTypographyTitle>
            </MuiGridTitle>
            <Grid item xs={4}>
              {hasPermissionFor('create_datasets') &&
              <NewDataset onUpdateDatasets={refetchDatasets} />}
            </Grid>
          </MuiGrid>
          <TableContainer>
            <DeleteDataset />
            <Table
              data={paginatedData}
              row={row}
              searchTerm={searchTerm}
              handleSearch={handleSearch}
              setDateRange={setDateRange}
              columns={columns}
              onSync={handleSync}
              onDelete={handleDelete}
              showDelete={hasPermissionFor('delete_datasets')}
              isLoading={isLoading}
            isDataset={true}
            />
            <Grid container direction='column' alignItems='flex-start'>
              <TPagination
                data={filteredData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleFirstPage={handleFirstPage}
                handlePreviousPage={handlePreviousPage}
                handleNextPage={handleNextPage}
                handleLastPage={handleLastPage}
              />
            </Grid>
          </TableContainer>
        </MuiGridContainer>
      </MuiContainer>
    </>
  );
};

export default Dataset;
