const styles = (theme) => ({
  btn: {
    backgroundColor: theme.palette.common.white,
    padding: 10,
    '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
    borderRadius: 12,
    width: '40px',
    height: '40px',
  },
  popoverContainer: {
    padding: 12,
    '& .MuiPopover-paper': {
      display: 'inline-table' ,
      borderRadius: '12px !important' },
  },
  title: {
    margin: theme.spacing(0.5),
    fontSize: 14,
  },
  divDivider: {
    width: theme.spacing(25),
    backgroundColor: 'gray',
    height: 1,
    margin: theme.spacing(0),
  },
  containerTitle: {
    justifyContent: 'left',
    marginLeft: 12,
    marginTop: 12
  },
  baseMapContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 12,
    border: '2px solid transparent',
    padding: 4,
    '&.selected': {
      borderColor: theme.palette.primary.main,
      width: '175px !important'
    },
    cursor: 'pointer',
  },
  baseMapImage: {
    width: 48,
    height: 48,
    borderRadius: 8,
    marginRight: 8
  },
  closeIconButton: {
    borderRadius: 25,
    position: 'absolute',
    right: -0,
    transform: 'scale(0.8)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
export default styles;
