import React from 'react';
import { 
    makeStyles ,
    Grid,
    Divider,
    MenuItem,
    Box,
    Typography,
} from '@material-ui/core';
import {
    SelectField,
    SwitchField,
  } from 'components/common/ReactHooksFormFields';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import {ReactSVG} from 'react-svg';
import styles from './styles/recommendations';
import { FormulaGenerator } from '../formula/FormulaGenerator';
import { useTranslation } from 'components/providers/TranslationProvider';
import { switchCommonStyles } from '../styles/category';
import CustomSkeleton from 'components/common/CustomSkeleton';

const useStyles = makeStyles(styles);
const useSwitchStyles = makeStyles(switchCommonStyles);

const RecommendationsDemand = ( props ) => {
  const {
    errors,
    onChangeLayerName,
    layers,
    demandDatasetName,
    isFormulaFieldSelected,
    setIsFormulaFieldSelected,
    setValue,
    isTargetDemandsFieldSelected,
    setIsTargetDemandsFieldSelected,
    tdform,
    wform,
    demandDatasetColumns,
    demandDatasetColumnsIsSuccess,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles({ isFormulaFieldSelected, t });
  const switchCommon = useSwitchStyles();

  return (
    <Grid item xs={12} className={` ${classes.elementContainer} ${classes.removeGridPadding} `}>
                    <Grid container justifyContent='center'
                          style={{alignContent: 'flex-start'}}>
                      <Grid container spacing={3} justifyContent='center'
                            directio={'row'}>
                        <Grid item xs={12}
                              className={classes.elementContainer}>
                          <Grid item container
                                justifyContent='space-between'
                                direction={'row'}>
                            <Grid item xs={6}
                                  className={classes.elementContainer}>
                              <div className={classes.displayFlex}>
                                <SelectField
                                  name='layer'
                                  error={t(errors.layer?.message)}
                                  variant='outlined'
                                  label={t('recomendation_demand_layer')}
                                  fullWidth
                                  InputLabelProps={{
                                    style: { color: '#424242' }
                                  }}
                                  onClick={(e) => onChangeLayerName(e.target.value)}
                                >

                                  <Divider light/>
                                  {layers?.map((l, index) => (
                                    <MenuItem key={l.id} value={l.id}
                                              data-cy={'layerDemand' + index}>
                                      <div style={{display:'flex', flexDirection:'row'}} >
                                        <ReactSVG
                                          src={process.env.PUBLIC_URL + '/maki-icons/' + l.customIcon}
                                          afterInjection={(svg) => {
                                            svg.getElementsByTagName('path')[0]
                                              .setAttribute('fill', l.color);
                                          }}
                                          beforeInjection={(svg) => {
                                            svg.classList.add('svg-class-name')
                                          }} style={{width: 20 , height: 20, marginLeft:3 }}/>
                                        <span style={{marginLeft:4, marginTop:-2}}>{l.name}</span>
                                      </div>
                                    </MenuItem>
                                  ))}
                                </SelectField>
                                <TypographyWithInfoIconTooltip
                                  className={classes.typographyWithInfoIconTooltipStyles}>
                                  {t('recommendation_layer_info')}
                                </TypographyWithInfoIconTooltip>
                              </div>
                            </Grid>
                            <Grid xs={6}
                                  className={classes.elementContainer}>
                              <div className={classes.displayFlex}>
                                {demandDatasetColumnsIsSuccess ? (
                                  <SelectField
                                    name='densityVariable'
                                    variant='outlined'
                                    label={t('recommendations_demand_density')}
                                    fullWidth
                                    disabled={!demandDatasetName}
                                    error={t(errors.densityVariable?.message)}
                                  >
                                    <Divider light value=' '/>
                                    {demandDatasetColumns?.map((ddc) => (
                                      <MenuItem key={ddc} value={ddc}>
                                        {ddc}
                                      </MenuItem>
                                    ))}
                                  </SelectField>
                                ) : (
                                  <CustomSkeleton
                                    className={classes.selectSkeleton}/>
                                )}
                                <TypographyWithInfoIconTooltip
                                  className={classes.typographyWithInfoIconTooltipStyles}>
                                  {t('recommendations_demand_density_info')}
                                </TypographyWithInfoIconTooltip>
                              </div>
                            </Grid>
                            <Grid xs={12}
                                  className={classes.elementContainer}>
                              <Box
                                display="flex"
                                alignItems="center"
                                style={{
                                  marginBottom: 10,
                                  marginTop: 23,
                                }}
                              >
                                <Box display="flex">
                                <Typography className={classes.sectionTitle}>{t('priority_variable_main_header')}</Typography>
                                <TypographyWithInfoIconTooltip>
                                        {t('recommendations_priorization_variable_info')}
                                      </TypographyWithInfoIconTooltip>
                                </Box>
                                <Typography className={classes.priorityVariable}>{t('recommendations_specify_welfare_formula')}</Typography>
                                <SwitchField
                                  name='isFormulaFieldSelected'
                                  checked={isFormulaFieldSelected}
                                  onChange={(e) => {setIsFormulaFieldSelected(e.target.checked), setValue('calculator', e.target.checked)}}
                                  inputProps={{'aria-label': 'controlled'}}
                                  color='primary'
                                  classes={{
                                    root: classes.root,
                                    switchBase: classes.switchBase,
                                    thumb: classes.thumbIsFormulaFieldSelected,
                                    track: classes.track,
                                    checked: classes.checked,
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12}
                                  className={classes.elementContainer}
                                  style={{marginTop: 15}}>
                              {isFormulaFieldSelected ? (
                                  <FormulaGenerator
                                        methodsForFormula={[wform.methods, wform.makeid, wform.setFormulaWithAlias, wform.setColumnListOrderFormula]}
                                        variablesForFormula={[[...wform?.columns,...(wform.definedParametersCatalog?.map(p => p.propName) || [])], wform.action, wform.isFormulaFieldSelected, wform.errors,
                                          wform.datasetColumn, wform.widget, wform.formulaWithAlias, wform.type, wform.alias, wform.columnsListOrder, 'recos_math', isFormulaFieldSelected
                                        ]}
                                        setValidFormula={wform.setValidFormula}
                                        formulaErrors={wform.formulaErrors}
                                        setFormulaErrors={wform.setFormulaErrors}
                                        formula={wform.formula}
                                        setFormula={wform.setFormula}
                                        tableName={wform.datasetName}
                                        setOpenToast={wform.setOpenToast}
                                        setIsFormulaValidate={wform.setIsFormulaValidate}
                                      />
                              ) : (
                                <>
                                  {demandDatasetColumnsIsSuccess ? (
                                    <div className={classes.displayFlex}>
                                      <SelectField
                                        name='welfareVariable'
                                        variant='outlined'
                                        label={t('variable_welfare')}
                                        fullWidth
                                        error={t(errors.welfareVariable?.message)}
                                      >
                                        {demandDatasetColumns?.map((ddc) => (
                                          <MenuItem key={ddc} value={ddc}>
                                            {ddc}
                                          </MenuItem>
                                        ))}

                                      </SelectField>
                                    </div>
                                  ) : (
                                    <CustomSkeleton
                                      className={classes.selectSkeleton}/>
                                  )}
                                </>
                              )}
                            </Grid>
                            <Grid
                              item xs={12}
                              className={classes.elementContainer}
                            >
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 30,
                              }}>
                                <Box display="flex" alignItems="center">
                                <Typography>{t('demand_filters_main_header')}</Typography>
                                <TypographyWithInfoIconTooltip
                                        className={classes.marginAuto}>
                                        {t('recommendations_demand_density_info')}
                                      </TypographyWithInfoIconTooltip>
                                </Box>
                                <SwitchField
                                  name='isTargetDemandsFieldSelected'
                                  checked={isTargetDemandsFieldSelected}
                                  onChange={(e) => {setIsTargetDemandsFieldSelected(e.target.checked), setValue('calculator', e.target.checked)}}
                                  inputProps={{'aria-label': 'controlled'}}
                                  color='primary'
                                  classes={{
                                    root: switchCommon.root,
                                    switchBase: switchCommon.switchBase,
                                    thumb: switchCommon.thumb,
                                    track: switchCommon.track,
                                    checked: switchCommon.checked,
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12}
                                  className={classes.elementContainer}
                                  style={{marginTop: 15}}>
                              {
                                isTargetDemandsFieldSelected && (
                                <div
                                className={`${classes.displayFlex} ${classes.boxSizingContentBox}`}>
                                <FormulaGenerator
                                  methodsForFormula={[tdform.methods, tdform.makeid, tdform.setFormulaWithAlias, tdform.setColumnListOrderFormula]}
                                  variablesForFormula={[[...tdform.columns,...(tdform.definedParametersCatalog?.map(p => p.propName) || [])], tdform.action, tdform.isTargetDemandsFieldSelected, tdform.errors,
                                    tdform.datasetColumn, tdform.widget, tdform.formulaWithAlias, tdform.type, tdform.alias, tdform.columnsListOrder, 'recos_logic', isTargetDemandsFieldSelected
                                  ]}
                                  setValidFormula={ tdform.setValidFormula }
                                  formulaErrors={ tdform.formulaErrors }
                                  setFormulaErrors={ tdform.setFormulaErrors }
                                  formula={ tdform.targetDemandsFormula }
                                  setFormula={ tdform.setTargetDemandsFormula }
                                  tableName={ tdform.datasetName }
                                  setOpenToast={ tdform.setOpenToast }
                                  setIsFormulaValidate={tdform.setIsFormulaValidate}
                                />
                              </div>)
                            }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
  );
};

export default RecommendationsDemand;
