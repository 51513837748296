import React from 'react';

const UploadIcon = ({ color = 'black', size = 24 }) => (
<svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_394_6345)">
        <path d="M9 16H15V10H19L12 3L5 10H9V16ZM5 18H19V20H5V18Z" fill={color}/>
    </g>
    <defs>
        <clipPath id="clip0_394_6345">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>
);

export default UploadIcon;
