import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { useTranslation } from 'components/providers/TranslationProvider';
import {
  iconCommonAddStyles,
  MuiCommonButtonAdd,
  MuiCommonTypographyAdd
} from '../styles/common';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography,
  Grid,
  Paper,
  TableContainer,
} from '@mui/material';
import { fuenteStyle } from '../../../common/styles/table';
import { ReactSVG } from 'react-svg';
import { MuiDeleteIcon, MuiEditIcon, } from '../layer/styles/layersSort';
import {
  MuiColumns,
  MuiDiv, MuiLayerName,
  MuiTypographyName,
  tblBodyStyle, tblBorderCommonStyle,
  tblCellStyle,
  tblContainerStyle,
  tblHeadStyle
} from '../styles/tooltip';

const Tooltip = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [tooltips, setTooltips] = React.useState([]);

  const layers = useSelector((state) => state.app.layers);
  const columns = [t('name'), t('layer'), t('columns'), ''];

  const handleAddTooltip = () => {
    navigate(`${location.pathname}?create=tooltip`, {
      replace: true,
    });
  }

  const handleDeleteTooltip = (id, tooltip) =>
    navigate(`${location.pathname}?delete-tooltip=${id}`, { replace: true, state: tooltip });

  const handleEditTooltip = (id, tooltip) =>
    navigate(`${location.pathname}?edit-tooltip=${id}`, {
      replace: true,
      state: tooltip,
    });

  React.useEffect(() => {
    const tlt = layers.reduce(
      (prevVal, current) => {
        if (current.tooltip?.name) {
          const tooltipItem = {
            id: current.tooltip.id,
            name: current.tooltip.name,
            label: current.tooltip.label || null,
            columns: current.tooltip.columns || null,
            layerId: current.id,
            layerName: current.name,
            layerIcon: current.customIcon,
            layerColor: current.color,
          };
          return [...prevVal, tooltipItem];
        } else {
          return prevVal;
        }
      },
      []
    );
    setTooltips(tlt);
  }, [layers]);

  return (
    <>
      <Grid container justifyContent='flex-end' alignContent='center' style={{ margin: '8px 0px' }}>
        <Grid item xs={10}>
          <Typography
            variant='subtitle1'
            fontSize={16}
            color={'#616161'}
            marginTop={1}
            letterSpacing={0.15}
            fontWeight={400}
            fontFamily={'Raleway'}
          >
            {t('tooltips_list')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <MuiCommonButtonAdd
            variant='outlined'
            color='primary'
            startIcon={<AddIcon style={iconCommonAddStyles}/>}
            onClick={()=>handleAddTooltip()}
            disabled={layers.length < 1 || layers.length === tooltips.length}
          >
            <MuiCommonTypographyAdd> {t('add_tooltip')} </MuiCommonTypographyAdd>
          </MuiCommonButtonAdd>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={tblContainerStyle} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={tblHeadStyle}>
            <TableRow>
              {columns.map((c) => (
                <TableCell sx={tblBorderCommonStyle} key={c} component='th' scope='row'>
                  <Typography variant='caption' sx={fuenteStyle}>{c}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={tblBodyStyle}>
            {tooltips.map((tooltip, index) => (
              <TableRow
                key={tooltip.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{borderTop: index === 0 ? 'none' : '1px solid transparent'}}
              >
                <TableCell component="th" scope="row" sx={tblBorderCommonStyle}>
                  <MuiTypographyName variant='caption'>{tooltip.name}</MuiTypographyName>
                </TableCell>
                <TableCell sx={tblBorderCommonStyle} align="right">
                  <MuiDiv>
                    <ReactSVG
                      src={process.env.PUBLIC_URL + '/maki-icons/' + tooltip.layerIcon}
                      afterInjection={(svg) => {
                        svg.getElementsByTagName('path')[0]
                          .setAttribute('fill', tooltip.layerColor);
                      }}
                      beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                      }} style={{width: 20 , height: 20 }}/>
                    <MuiLayerName>{tooltip.layerName}</MuiLayerName>
                  </MuiDiv>
                    </TableCell>
                <TableCell sx={tblBorderCommonStyle} align='left' style={tblCellStyle}>
                  {tooltip.columns.filter(item => item.show).map((column) => (
                    <React.Fragment key={column.id}>
                      <MuiColumns>{column.name}</MuiColumns>
                    </React.Fragment>
                  ))}
                </TableCell>
                <TableCell sx={tblBorderCommonStyle} align="right">
                  <MuiEditIcon onClick={() => handleEditTooltip(tooltip.id, tooltip)} />
                  <MuiDeleteIcon onClick={() => handleDeleteTooltip(tooltip.id, tooltip)}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Tooltip;
