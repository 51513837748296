import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useAuth } from 'components/providers/AuthProvider';
import logoStatic from 'assets/img/logo.0c7a85ba.png';
import { useTheme } from 'components/providers/CustomThemeProvider';
import useOrganization from 'api/hooks/useOrganization';
import { useSelector } from 'react-redux';
import { GLOBAL } from 'utils/constants';
import {
  containerStyle, MuiImg, MuiImgLogo, MuiTypography, MuiTypographyName,
  organizationlogoNameContainer
} from './styles/orgInfo';

const OrgInfo = () => {
  const [display, setDisplay] = React.useState(false);
  const [logo, setLogo] = React.useState('');
  const { user } = useAuth();
  const { setColors, setSwitchColor, isCustomColor } = useTheme();

  const refetchIsPublicMap = useSelector((state) => state.app.refreshOrgInfoPublicEmbeddedMap);

  const location = useLocation();

  const hashPublic = location.pathname.split('/')[1] === 'public';
  const hashOrgAndScenario = Boolean(
    location.pathname.split('/')[1] === 'orgs' && location.pathname.split('/')[2]
  );

  const id =
    location.pathname.split('/')[2] === 'workspace' ? user.org_id : location.pathname.split('/')[2];

  const { refetch } = useOrganization(id, hashOrgAndScenario);

  const queryClient = useQueryClient();

  const orgData = queryClient.getQueryData(['organization', id]);
  const schemePublicData = queryClient.getQueryData([
    'scheme-public',
    location.pathname.split('/')[4],
  ]);
  const schemeData = queryClient.getQueryData(['scheme', location.pathname.split('/')[6]]);

  React.useEffect(() => {
    if (schemeData && !hashPublic) refetch();
    else if (hashPublic) refetch();
  }, [schemeData, refetch, hashPublic]);

  React.useEffect(() => {
    if (hashPublic && orgData){
      setColors(orgData?.attrs.primary_color, orgData?.attrs.secondary_color );
      setLogo(orgData?.attrs.logo_url);
      setDisplay(true);
    }
  },[orgData])

  React.useEffect(() => {
    if ((hashOrgAndScenario || hashPublic) && (orgData?.attrs || schemePublicData?.org)) {
      if (hashOrgAndScenario) {
        setLogo(orgData.attrs.logo_url);
        setDisplay(true);
      }
      if (hashPublic && schemePublicData?.org) {
        setLogo(schemePublicData.org.logo_url);
        if (schemePublicData.org.primary_color && schemePublicData.org.secondary_color) {
          setColors(schemePublicData.org.primary_color, schemePublicData.org.secondary_color);
          if (!isCustomColor) setSwitchColor(true);
        }
        setDisplay(true);
      }
    } else {
      setDisplay(false);
    }
  }, [
    hashOrgAndScenario,
    hashPublic,
    isCustomColor,
    location.pathname,
    orgData?.attrs,
    setColors,
    setSwitchColor,
    schemePublicData,
    refetchIsPublicMap,
  ]);

  return (
    display && (
      <div style={containerStyle}>
        {hashOrgAndScenario || hashPublic ? (
          <div style={organizationlogoNameContainer}>
            <MuiImg src={logo} alt={GLOBAL.ALT.LOGO} />
            <MuiTypography variant="subtitle1">
              { orgData?.shortName }
            </MuiTypography>
          </div>
        ) : (
          <MuiImg src={logoStatic} alt={GLOBAL.ALT.LOGO}/>
        )}
      </div>
    )
  ) ||
  (
    <div style={organizationlogoNameContainer}>
      <MuiImgLogo src="/img/logo.png" alt={GLOBAL.ALT.LOGO}/>
      <MuiTypographyName variant="subtitle1" >
        INCLUIA
      </MuiTypographyName>
    </div>
  )
};

export default OrgInfo;
