import { GLOBAL } from 'utils/constants';

const styles = (theme) => ({
  tabs: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    '& .Mui-selected': {
      fontSize: 14,
      fontWeight: 500,
      color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[800],
      transition: 'font-size 10ms, font-weight 10ms',
    },
  },
  indicator: {
    backgroundColor: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900],
    height: 3,
    borderRadius: '100px 100px 0px 0px',
  },
  nonSelectedTab: {
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
    fontSize: 14,
    fontWeight: 500,
  },
  toolbar: {
    margin: '0',
    width: '100%',
    zIndex: 1201,
  },
  onHover: {
    '&:hover': {
      backgroundColor: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[50],
      transition: 'background-color 0.3s',
      borderRadius: '12px 12px 0px 0px',
    },
  },
  widgetContainer: {
    overflowY: 'auto',
  },
});
export default styles;
