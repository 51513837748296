import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  addFilter,
  removeFilter,
  selectSourceById
} from 'lib/react-geo-tool/packages/react-redux/src/';
import  WrapperWidgetUI  from '../WrapperWidgetUI';
import { _FilterTypes as FilterTypes, AggregationTypes } from 'lib/react-geo-tool/packages/react-core/src/';
import { getHistogram } from 'lib/react-geo-tool/packages/react-widgets/src/models';
import { useWidgetFilterValues } from '../sources-carto/useWidgetFilterValues';
import useWidgetFetch from 'lib/react-geo-tool/packages/react-widgets/src/hooks/useWidgetFetch';
import WidgetWithAlert from '../sources-carto/WidgetWithAlert';
import useStats from '../sources-carto/useStats';
import HistogramWidgetUI from './HistogramWidgetUI'
import {
  setWidgets,
} from "../../../../store/appSlice";
import * as _ from 'lodash';
const EMPTY_ARRAY = [];

/**
 * Renders a <HistogramWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {number} props.min - Min value of the indicated column
 * @param  {number} props.max - Max value of the indicated column
 * @param  {string} [props.operation] - Operation to apply to the column. Must be one of those defined in `AggregationTypes` object.
 * @param  {number[]} [props.ticks] - Array of thresholds for the X axis.
 * @param  {number} [props.bins] - Number of bins to calculate the ticks.
 * @param  {Function} [props.xAxisformatter] - Function to format X axis values.
 * @param  {Function} [props.formatter] - Function to format Y axis values.
 * @param  {boolean} [props.tooltip=true] - Whether to show a tooltip or not
 * @param  {Function} [props.tooltipFormatter] - Function to return the HTML of the tooltip.
 * @param  {boolean} [props.animation] - Enable/disable widget animations on data updates. Enabled by default.
 * @param  {boolean} [props.filterable] - Enable/disable widget filtering capabilities. Enabled by default.
 * @param  {boolean} [props.global] - Enable/disable the viewport filtering in the data fetching.
 * @param  {Function} [props.onError] - Function to handle error messages from the widget.
 * @param  {Object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default)
 * @param  {Object} [props.noDataAlertProps] - Extra props to pass to [NoDataAlert]()
 * @param  {Object} [props.droppingFeaturesAlertProps] - Extra props to pass to [NoDataAlert]() when dropping feature
 */
function HistogramWidget({
                           id,
                           title,
                           dataSource,
                           column,
                           operation,
                           // ticks: _ticks = [],
                           min: _min,
                           max: _max,
                           xAxisFormatter,
                           dataAxis,
                           bins,
                           formatter,
                           tooltip,
                           tooltipFormatter,
                           global,
                           onError,
                           wrapperProps,
                           noDataAlertProps,
                           droppingFeaturesAlertProps,
                           color
                         }) {
  const dispatch = useDispatch();
  const columnToFilter = useSelector((state)=> state.app.widgets.filter((w) => w.id === id)).map((w) =>{
    return w.params.calculator ? w.params.alias : w.params.field
  });

  const widgets= useSelector((state)=> state.app.widgets);
  const source = useSelector((state) => selectSourceById(state, dataSource) || {});
  const { filters } = source;
  const [[min, max], setMinMax] = useState([_min, _max]);
  const [ticks, setTicks] = useState([]);
  const ticksBeforeParametersRecomputation = useRef(null);
  const parametersCatalog = useSelector((state) => state.app.parametersCatalog);
  const [localParameters, setLocalParameters] = useState(parametersCatalog);
  const diferents = _.isEqual(parametersCatalog, localParameters);

  let localParametersCatalog = null;
  if (parametersCatalog?.length > 0) {
    localParametersCatalog = {};
    parametersCatalog.forEach(p => {
      if (column.includes(p.propName) || (filters && Object.keys(filters).length >0)) {
        localParametersCatalog[p.propName] = p.value
      }
    });
  }
  const hasMinMax =
    Number.isFinite(min) &&
    min !== Number.MIN_SAFE_INTEGER &&
    Number.isFinite(max) &&
    max !== Number.MAX_SAFE_INTEGER;

  const arraysEqual = (firstArray, secondArray) => {
    if (firstArray?.length !== secondArray?.length) {
      return false;
    }
    for (let i = 0; i < firstArray.length; i++) {
      if ( firstArray[i] !== secondArray[i].tick_id ) {
        return false;
      }
    }
    return true;
  }

  let {
    data = EMPTY_ARRAY,
    isLoading,
    warning = _warning
  } = useWidgetFetch(getHistogram, {
    id,
    dataSource,
    params: {
      column,
      operation,
      // ticks,
      parametersCatalog: localParametersCatalog
    },
    global,
    onError,
    enabled: wrapperProps.expand,
    attemptRemoteCalculation: global
  });
  const removeFiltersWhenTicksChange = () => {
      if ( !arraysEqual( ticksBeforeParametersRecomputation.current, data )) {
        dispatch(
          removeFilter({
            id: dataSource,
            column: columnToFilter
          })
        );
      }
    ticksBeforeParametersRecomputation.current = data.map( d => d.tick_id );
  }

  useEffect(() => {
    if(data?.length>0){
      if ( ticksBeforeParametersRecomputation.current === null ) {
        const transformedData = data.map( d => d.tick_id );
        ticksBeforeParametersRecomputation.current = transformedData;
      }
      removeFiltersWhenTicksChange();

      setTicks(data.map(d=>d.binend));
      let localMin=data[0].min;
      let localMax=data[data.length-1].max;
      setMinMax([localMin,localMax]);
      const updatedWidgetIdx=widgets.findIndex((w) => w.id === id);
      if(updatedWidgetIdx>=0){
        const updatedWidget= _.cloneDeep(widgets[updatedWidgetIdx]);
        const widgetColors=updatedWidget.params?.customHistogramColors;
        if(widgetColors?.length>0){
          for (let i = 0; i < data.length; i++) {
            widgetColors[i].start=data[i].binstart;
            widgetColors[i].end=data[i].binend;
          }
          updatedWidget.params.customHistogramColors=widgetColors;
          const updatedWidgets=[...widgets];
          updatedWidgets[updatedWidgetIdx]=updatedWidget;
          dispatch(setWidgets(updatedWidgets));
        }
      }
    }

  }, [data]);

  const thresholdsFromFilters = useWidgetFilterValues({
    dataSource,
    id,
    column : columnToFilter,
    type: FilterTypes.BETWEEN
  });

  const selectedBars = useMemo(() => {
    return (thresholdsFromFilters || EMPTY_ARRAY)
      .map(([from, to]) => {
        if (typeof from === 'undefined' || from === null) {
          return 0;
        } else if (typeof to === 'undefined' || to === null) {
          return ticks.length;
        } else {
          const idx = ticks.indexOf(from);
          return idx !== -1 ? idx + 1 : 0;
        }
      })
      .filter((v) => v !== null);
  }, [thresholdsFromFilters, ticks]);

  const handleSelectedBarsChange = useCallback(
    (selectedBars) => {
      selectedBars = selectedBars.bars;
      if (selectedBars?.length) {
        const thresholds = selectedBars.map((i) => {
          let left = ticks[i-1];
          let right = ticks.length !== i ? ticks[i] : parseFloat(max.toFixed(4));

          return [left, right];
        });
        dispatch(
          addFilter({
            id: dataSource,
            column: columnToFilter,
            type: FilterTypes.BETWEEN,
            values: thresholds,
            owner: id
          })
        );
      } else {
        dispatch(
          removeFilter({
            id: dataSource,
            column: columnToFilter
          })
        );
      }
    },
    [column, dataSource, id, dispatch, ticks]
  );

  return (
    <WrapperWidgetUI title={title} widgetId={id} {...wrapperProps} isLoading={isLoading}>
      <WidgetWithAlert
        dataSource={dataSource}
        warning={warning}
        global={global}
        droppingFeaturesAlertProps={droppingFeaturesAlertProps}
        noDataAlertProps={noDataAlertProps}
      >
        {(!!data.length || isLoading) && (
          <HistogramWidgetUI
            id={id}
            data={data.map(d=>d.value)}
            dataSource={dataSource}
            min={min}
            max={max}
            ticks={ticks}
            dataAxis={ticks}
            onSelectedBarsChange={handleSelectedBarsChange}
            selectedBars={selectedBars}
            tooltip={tooltip}
            formatter={formatter}
            tooltipFormatter={tooltipFormatter}
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={formatter}
            color={color}
          />
        )}
      </WidgetWithAlert>
    </WrapperWidgetUI>
  );
}

HistogramWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  ticks: PropTypes.arrayOf(PropTypes.number),
  bins: PropTypes.number,
  operation: PropTypes.oneOf(Object.values(AggregationTypes)),
  xAxisFormatter: PropTypes.func,
  formatter: PropTypes.func,
  tooltip: PropTypes.bool,
  tooltipFormatter: PropTypes.func,
  animation: PropTypes.bool,
  filterable: PropTypes.bool,
  global: PropTypes.bool,
  onError: PropTypes.func,
  wrapperProps: PropTypes.object,
  noDataAlertProps: PropTypes.object,
  droppingFeaturesAlertProps: PropTypes.object
};

HistogramWidget.defaultProps = {
  bins: 15,
  ticks: [],
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  operation: AggregationTypes.COUNT,
  tooltip: true,
  animation: true,
  filterable: true,
  global: false,
  wrapperProps: {},
  noDataAlertProps: {}
};

export default HistogramWidget;
