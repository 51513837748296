import * as React from 'react';
import {
  Drawer,
  Divider,
  Hidden,
  Grid,
  Toolbar,
  Link
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink, useLocation } from 'react-router-dom';

import UserMenu from 'components/views/login/UserMenu';
import { useAuth } from 'components/providers/AuthProvider';
import { useTranslation } from 'components/providers/TranslationProvider';
import { useTheme } from 'components/providers/CustomThemeProvider';

import LanguageSelector from './LanguageSelector';
import OrgDescription from './OrgDescription';
import NavigationMenu from './NavigationMenu';
import OrgInfo from './OrgInfo';
import {
  linkHeaderStyle,
  MuiAppBar, MuiButton,
  MuiContainer, MuiDivContainer,
  MuiIconButton, MuiMapIconButton, MuiPublicIcon, MuiSelectorContainer,
  MuiToolbar
} from './styles/header';
import SettingsIcon from 'components/common/SettingsIcon';
import SettingsIconHover from 'components/common/SettingsIconHover';

const Header = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const { isAuthenticated , user} = useAuth();
  const { setSwitchColor, isCustomColor } = useTheme();
  const urlParams = location.pathname.split('/');
  const schemeId = location.pathname.split('/')[4];
  const { t } = useTranslation();
  const { theme } = useTheme();

  React.useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const getNavigateSettings = () => {
    if (urlParams.includes('view') && urlParams.includes('public'))
      return `${urlParams.slice(0, 3).join('/')}/maps/${urlParams[6]}/specification`;

    if (urlParams.includes('view') && urlParams.includes('custom'))
      return `${urlParams.slice(0, 3).join('/')}/maps/${urlParams[6]}/specification`;

    if (urlParams[6] === 'custom' && urlParams[7]) return `${urlParams.slice(0, 7).join('/')}`;

    return `${location.pathname}`;
  };

  const getLastElement = () => {
    if (urlParams[6] === 'custom' && urlParams[8]) return 5;
    if (urlParams[6] === 'custom' && urlParams[7]) return 4;
    if (urlParams.includes('view')) return 3;
    if (urlParams.includes('specification')) return 2;
    if (urlParams[2]) return 1;
    if (urlParams[1]) return 0;
    return null;
  };

  return (
    <MuiAppBar position='static' sx={{backgroundColor:theme.palette.primary.main}}>
      <MuiContainer>
        <MuiToolbar variant='dense'>
          <Hidden smUp>
            <MuiIconButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={handleDrawerToggle}
            >
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </MuiIconButton>
            <Divider orientation='vertical' light />
          </Hidden>
          <OrgInfo />
          {isAuthenticated && user.type !== 'level_0' && (
            <Hidden xsDown>
              <MuiDivContainer>
                <Divider variant='middle' orientation='vertical' style={{border: '1px solid #ffffff'}} light />
              </MuiDivContainer>
              <NavigationMenu location={location} />
            </Hidden>
          )}
          {isAuthenticated && (
            <Hidden smUp>
              <Drawer
                variant='temporary'
                anchor='left'
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                  className: { minWidth: 260 },
                }}
              >
                <Toolbar variant='dense' />
                <Grid container direction='column' justifyContent='space-between' item xs>
                  <NavigationMenu location={location} column />
                </Grid>
              </Drawer>
            </Hidden>
          )}
          <Grid container item xs justifyContent='flex-end'>
            {location.pathname.split('/')[1] !== 'public' ||
              (user?.type !== 'level_0' && location.pathname.split('/')[1] === 'public' )  ? (
              <MuiSelectorContainer container >
                {getLastElement() === 3 && ( <Link style={linkHeaderStyle}  to={getNavigateSettings()} component={NavLink} underline='none'>
                  <button
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{ cursor: 'pointer', border: 'none', background: 'none' }}
                    >
                      { isHovered ? <SettingsIconHover/> : <SettingsIcon/> }
                  </button>
                </Link>)}
                {getLastElement() === 2 && ( <Link style={linkHeaderStyle}  to={`${location.pathname.split('/').splice(0, 4).join('/')}/view/custom/${schemeId}`} component={NavLink} underline='none'>
                  <MuiMapIconButton>
                    <MuiPublicIcon/>
                  </MuiMapIconButton>
                </Link>)}
                <LanguageSelector />
                {isAuthenticated ? (
                  <UserMenu />
                ) : (
                  !location.pathname.split('/').includes('public') && (
                    <MuiButton component={NavLink} to='/login'>
                      {t('login')}
                    </MuiButton>
                  )
                )}
                {urlParams.includes('view') && <OrgDescription />}
              </MuiSelectorContainer>
            ): (
              <LanguageSelector />
            )}
          </Grid>
        </MuiToolbar>
      </MuiContainer>
    </MuiAppBar>
  );
};

export default Header;
