import {useState} from 'react';
import { Box, FormControl } from '@mui/material';
import Popover from '@mui/material/Popover';
import { Tune as TuneIcon, } from '@mui/icons-material';
import { useTranslation } from 'components/providers/TranslationProvider';
import CustomToggleButton from 'components/common/CustomToggleButton';
import SortByAlphaIconInverted from 'components/common/icons/SortByAlphaIconInverted'
import {
  MuiArrowDownwardIcon,
  MuiArrowUpwardIcon,
  MuiBoxButtonsContainer,
  MuiBoxContainer,
  MuiBoxFilter,
  MuiBoxIdentifier,
  MuiButtonCommon,
  MuiIconButton,
  MuiLabelCommons,
  MuiMenuItem,
  MuiOpenInFullIcon,
  MuiPinDropIcon,
  MuiSelect,
  MuiSortByAlphaIcon,
  MuiThumbUpIcon,
  MuiTypographyHeader,
  popoverStyle,
  selectedStyle,
  toggleButtonLargeStyle
} from './styles/filterRecommendationsPopover';

function FilterRecommendationsPopover( props ) {
const [anchorEl, setAnchorEl] = useState(null);
const { t } = useTranslation();

const {
  offerTypes,
  setOfferTypes,
  idSortedType,
  setIdSortedType,
  selectedColumnToOrder,
  setSelectedColumnToOrder,
  columnsToOrder,
  columnSortedType,
  setColumnSortedType,
  runFilterHandler,
} = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOfferTypesValues = (event, selectedValues) => {
    setOfferTypes( selectedValues );
  };

  const handleOrderByIdValues = (event, selectedValues) => {
    setIdSortedType( selectedValues );
  };

  const handleOrderBySelectedColumn = ( event ) => {
    setSelectedColumnToOrder( event.target.value );
  }

  const handleOrderSelectedColumnValues = (event, selectedValues) => {
    setColumnSortedType( selectedValues );
  };

  const defaultActionsAfterRunFilterHandler = () => {
    runFilterHandler();
    handleClose();
  }

  const offerTypesItems = [
    {
      value: 'recommendation',
      icon: <MuiThumbUpIcon/>
    },
    {
      value: 'current_offer',
      icon: <MuiPinDropIcon/>
    },
    {
      value: 'recommended_expansion',
      icon: <MuiOpenInFullIcon/>
    }
  ];

  const orderByIdItems = [
    {
      value: 'ASC',
      icon: <MuiSortByAlphaIcon/>,
      classes: {
        root: toggleButtonLargeStyle,
        selected: selectedStyle,
      }
    },
    {
      value: 'DESC',
      icon: <SortByAlphaIconInverted />,
      classes: {
        root: toggleButtonLargeStyle,
        selected: selectedStyle,
      }
    },
  ];

  const orderBySelectedColumn = [
    {
      value: 'ASC',
      icon: <MuiArrowUpwardIcon />,
      classes: {
        root: toggleButtonLargeStyle,
        selected: selectedStyle,
      }
    },
    {
      value: 'DESC',
      icon: <MuiArrowDownwardIcon />,
      classes: {
        root: toggleButtonLargeStyle,
        selected: selectedStyle,
      }
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? 'filter-recommendations-popover' : undefined;

  return (
    <>
        <MuiIconButton onClick={handleClick}>
          <TuneIcon />
        </MuiIconButton>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={popoverStyle}
      >
        <MuiBoxContainer>
            <Box display='flex' flexDirection='column'>
              <MuiTypographyHeader>{ t('filter_by') }</MuiTypographyHeader>
              <MuiBoxFilter>
                <MuiLabelCommons>{ t('type_of_service') }</MuiLabelCommons>
                <CustomToggleButton
                  toggleItems={offerTypesItems}
                  handleValues = {handleOfferTypesValues}
                  values={offerTypes}
                />
              </MuiBoxFilter>
              <MuiBoxIdentifier display='flex' justifyContent='space-between'>
                <MuiLabelCommons variant='caption'>{t('identifier')}</MuiLabelCommons>
                <CustomToggleButton
                    toggleItems={orderByIdItems}
                    handleValues = {handleOrderByIdValues}
                    values={idSortedType}
                    exclusive
                  />
              </MuiBoxIdentifier>
              <Box display='flex' justifyContent='space-between'>
                  <FormControl variant="outlined" >
                    <MuiSelect
                      id="recommendations-popover-coloumns"
                      value={selectedColumnToOrder}
                      onChange={handleOrderBySelectedColumn}
                      defaultValue='quant_var_covered'
                    >
                      {
                        columnsToOrder.map( (col) => (
                          <MuiMenuItem value={col.value}>
                            { col.label }
                          </MuiMenuItem>
                        ))
                      }
                    </MuiSelect>
                    </FormControl>
                    <CustomToggleButton
                      toggleItems={orderBySelectedColumn}
                      handleValues = {handleOrderSelectedColumnValues}
                      values={columnSortedType}
                      exclusive
                    />
              </Box>
              <MuiBoxButtonsContainer display='flex' justifyContent='end'>
                  <MuiButtonCommon
                    color='primary'
                    variant='text'
                    onClick={ handleClose }>
                      {t('cancel_btn')}
                  </MuiButtonCommon>
                  <MuiButtonCommon
                    color='primary'
                    variant='outlined'
                    onClick={ defaultActionsAfterRunFilterHandler }>
                      {t('filter_btn')}
                  </MuiButtonCommon>
              </MuiBoxButtonsContainer>
          </Box>
        </MuiBoxContainer>
      </Popover>
    </>
  );
}

export default FilterRecommendationsPopover;
