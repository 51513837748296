import { Grid, styled } from '@mui/material';

export const MuiGridFather = styled(Grid)(({ theme }) => ({
  display: 'flex',
  border:'1px solid',
  marginTop:'16px',
  borderColor:'#E0E0E0',
  borderRadius:'12px'
}));

export const MuiGridWidget = styled(Grid)(({ theme }) => ({
  background: '#FAFAFA',
  borderTopLeftRadiusRadius:'12px'
}));

export const MuiGridAddWidget = styled(Grid)(({ theme }) => ({
  background: '#FAFAFA',
  borderTopRightRadius:'12px',
  borderColor:'#E0E0E0'
}));
