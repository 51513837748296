import * as React from 'react';
import { TableContainer, Grid } from '@mui/material';
import Toast from 'components/common/Toast';
import { useLocation, useNavigate } from 'react-router-dom';

import useUsers from 'api/hooks/useUsers';
import { usePermissions } from 'components/hooks/usePermissions';
import { MuiContainer } from '../styles/user';

import Table from '../common/Table';
import DeleteUser from './DeleteUser';
import AddOrUpdateUser from './AddOrUpdateUser';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import ActionBar from './ActionBar';
import TabsCustoms from '../common/TabCustoms';
import TPagination from "../common/TPagination";
import {useTranslation} from "../../../providers/TranslationProvider";

const columns = ['Email', 'Type', 'Organization', 'Admin Rights', 'Related organizations', 'Actions'];
const row = [ 'email', 'type', 'myOrg', 'myOrgs', 'relatedOrgs' ];

const Users = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const columns = [t('email'), t('type'), t('organization'), t('admin_rights'), t('related_organizations'), t('actions')];

  const orgId = location.pathname.split('/')[2];
  const { data, isLoading } = useUsers(orgId);
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();
  const openToast= useSelector((state) => state.app.openToast);
  const messageToast = useSelector((state) => state.app.messageToast);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [, setDateRange] = React.useState('')
  const handleDelete = (id, d) =>
    navigate(`${location.pathname}?delete=${id}`, { replace: true, state: d });

  const handleEdit = (id, user) =>
    navigate(`${location.pathname}?edit=${id}`, { replace: true, state: user });
  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));

  const closeToast = () =>{
    dispatch(setOpenToast(false));
    dispatch(setMessageToast(''));
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));
  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));

  const handleFirstPage = () => setPage(0);
  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };
  const handleSearch = (event, index) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[index] = event.target.value;
    setSearchTerm(newSearchTerms);
    setPage(0);
  };
  const filteredData = data
    ? data?.filter((item) => {
      const lowerCaseSearchTerms = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.email} ${item.type} ${item.myOrgs} ${item.myOrgsLevel}`.toLowerCase();
      return lowerCaseSearchTerms.every((term) => itemString.includes(term));
    })
    : [];

  const transformedData = filteredData ? filteredData.map(
    (d) => (
        {
            ...d,
            myOrgs: d.type === 'admin' || d.type === 'super_admin' ? d.myOrgs.join(', ') : '',
            relatedOrgs: d.type !== 'admin' && d.type !== 'super_admin' ? d.myOrgs.join(', ') : ''
        }
    )
  ) : [];

  const paginatedData = transformedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <MuiContainer>
      <TabsCustoms/>
      <Toast
        severity='error'
        message={messageToast}
        open={openToast}
        handleClose={() => closeToast()}
        horizontal='right'
        vertical='top'
      />

      <ActionBar />
      <Grid sx={{width: '150px !important'}}>
      <AddOrUpdateUser />
      <DeleteUser />
      </Grid>
      <TableContainer sx={{background:'#FAFAFA'}}>
        <Table
          data={ paginatedData }
          row={row}
          columns={columns}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          onDelete={handleDelete}
          showDelete={hasPermissionFor('delete_users')}
          onEdit={handleEdit}
          showEdit={hasPermissionFor('update_users')}
          isLoading={isLoading}
          data-cy="usersList"
          setDateRange={setDateRange}
        />
      </TableContainer>
      <Grid container direction="column" alignItems="flex-start">
        <TPagination
          data={filteredData}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleFirstPage={handleFirstPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleLastPage={handleLastPage}
        />
      </Grid>
    </MuiContainer>
  );
};

export default Users;
