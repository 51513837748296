import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'components/providers/TranslationProvider';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import {
  filterDifferenceContainerStyle, filterDifferenceStyle,
  MuiFilterInfo,
  MuiFilterUnit,
  MuiFilterValue,
  MuiInfoCard,
  MuiInfoCardData,
  MuiInfoCardHeader,
  MuiVariableLabel,
  MuiVariableName,
  MuiVariableValue,
  negativePercentageStyle,
  positivePercentageStyle
} from './styles/vaiableInfo';

const VariableInfo = ( props ) => {
  const {
    prefix,
    label,
    data,
    name,
    information,
    filterData,
    filterUnit,
  } = props;
  const { t } = useTranslation();

  function safeDivide(numerator, denominator) {
    if (denominator === 0) {
      return 0;
    }
    const result = numerator / denominator;
    if (Number.isNaN(result) || !Number.isFinite(result)) {
      return 0;
    }
    return result;
  }

  const growthRate = React.useMemo(() => {
    if (data && filterData) {
      const parseData = parseFloat(data?.replace(/,/g, ''));
      const parseFilterData = parseFloat(filterData.replace(/,/g, ''));
      if (!Number.isNaN(parseData) && !Number.isNaN(parseFilterData)) {
        const rate = safeDivide((parseData - parseFilterData), parseData) * 100;
        return -1 * Math.round(rate);
      }
    }
    return 0;
  }, [ data, filterData]);

  return (
    <MuiInfoCard>
      <MuiInfoCardHeader>
        <MuiVariableLabel variant="caption" >{ label }</MuiVariableLabel>
        { information && <TypographyWithInfoIconTooltip >
          { t( information ) }
        </TypographyWithInfoIconTooltip>}
      </MuiInfoCardHeader>
      <MuiInfoCardData>
        {(!!prefix)&&(
          <MuiVariableName variant='overline'>
            {prefix||''}
          </MuiVariableName>
        )}
        <MuiVariableValue>
          { filterData || 0 }
        </MuiVariableValue>
        {(!!name)&&(
          <MuiVariableName
            sx={{marginTop:'0px !important'}}
            variant='overline'>
            {`${name}`}
          </MuiVariableName>
        )}
      </MuiInfoCardData>
      { data && (
        <MuiFilterInfo>
          <Box display='inline-flex' alignItems='center'>
            <MuiFilterValue>
              {data || 0}
            </MuiFilterValue>
            <MuiFilterUnit variant='overline' >
              {filterUnit}
            </MuiFilterUnit>
          </Box>
          <Box display='inline-flex' alignItems='center' justifyContent='center'
               sx={`${filterDifferenceContainerStyle} ${growthRate >= 0 ? positivePercentageStyle : negativePercentageStyle}`}>
            <Typography
              sx={filterDifferenceStyle}>{`${growthRate > 0 ? '+' : ''}${growthRate}`}%</Typography>
          </Box>
        </MuiFilterInfo>
      )}
    </MuiInfoCard>
  );
};

export default VariableInfo;
