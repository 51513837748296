import { styled, Typography } from '@mui/material';

export const MuiTypographyName = styled(Typography)(({ theme }) => ({
  color:'#212121',
  fontFamily:'Montserrat',
  fontSize:'12px',
  fontWeight:400,
  letterSpacing:'0.4px'
}));

export const MuiDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection:'row'
}));

export const MuiLayerName = styled('span')(({ theme }) => ({
  marginTop:'3px',
  fontSize:'10px',
  color:'#616161',
  fontFamily: 'Montserrat',
  fontWeight:400,
  letterSpacing:'1.5px'
}));

export const MuiColumns = styled('span')(({ theme }) => ({
  display: 'inline-block',
  textTransform: 'uppercase',
  backgroundColor: '#EEEEEE',
  borderRadius: '15px',
  fontSize:'10px',
  fontFamily: 'Montserrat',
  fontWeight:400,
  color:'#254957',
  padding: '4px 8px 4px 6px',
  marginRight: '2px',
  marginBottom: '2px'
}));

export const tblContainerStyle = {
  height: 280,
  borderRadius: '12px'
}

export const tblCellStyle = {
  maxWidth: '250px'
}

export const tblHeadStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  background: '#FFFFFF'
}

export const tblBodyStyle = {
  background:'#FAFAFA'
}

export const tblBorderCommonStyle = {
  borderBottom: '1px solid #EEEEEE'
}
