import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCategories } from 'lib/react-geo-tool/packages/react-widgets/src/models';
import { _FilterTypes as FilterTypes, AggregationTypes } from 'lib/react-geo-tool/packages/react-core/src/';
import {
  addFilter,
  removeFilter,
  selectSourceById
} from 'lib/react-geo-tool/packages/react-redux/src/';
import  CategoryWidgetUI  from './CategoryWidgetUI';
import { useWidgetFilterValues } from '../sources-carto/useWidgetFilterValues';
import useWidgetFetch from 'lib/react-geo-tool/packages/react-widgets/src/hooks/useWidgetFetch';
import { columnAggregationOn } from '../sources-carto/utils/propTypesFns';
import  WrapperWidgetUI  from '../WrapperWidgetUI';
import WidgetWithAlert from '../sources-carto/WidgetWithAlert';

const EMPTY_ARRAY = [];

/**
 * Renders a <CategoryWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {string | string[]} [props.operationColumn] - Name of the data source's column to operate with. If not defined it will default to the one defined in `column`. If multiples are provided, they will be merged into a single one using joinOperation property.
 * @param  {AggregationTypes} [props.joinOperation] - Operation applied to aggregate multiple operation columns into a single one.
 * @param  {string} props.operation - Operation to apply to the operationColumn. Must be one of those defined in `AggregationTypes` object.
 * @param  {Function} [props.formatter] - Function to format each value returned.
 * @param  {Object} [props.labels] - Overwrite category labels.
 * @param  {string} props.order - order of the elements that will be displayed
 * @param  {boolean} [props.animation] - Enable/disable widget animations on data updates. Enabled by default.
 * @param  {boolean} [props.filterable] - Enable/disable widget filtering capabilities. Enabled by default.
 * @param  {boolean} [props.searchable] - Enable/disable widget searching capabilities. Enabled by default.
 * @param  {boolean} [props.global] - Enable/disable the viewport filtering in the data fetching.
 * @param  {Function} [props.onError] - Function to handle error messages from the widget.
 * @param  {Object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default)
 * @param  {Object} [props.noDataAlertProps] - Extra props to pass to [NoDataAlert]()
 * @param  {Object} [props.droppingFeaturesAlertProps] - Extra props to pass to [NoDataAlert]() when dropping feature
 */
function CategoryWidget(props) {
  const {
    id,
    type,
    title,
    dataSource,
    column,
    operationColumn,
    joinOperation,
    operation,
    formatter,
    labels,
    order,
    animation,
    filterable,
    searchable,
    global,
    onError,
    wrapperProps,
    noDataAlertProps,
    droppingFeaturesAlertProps,
    colorInMap
  } = props;
  const source = useSelector((state) => selectSourceById(state, dataSource) || {});
  const { filters } = source;
  const dispatch = useDispatch();
  const columnToFilter = useSelector((state)=> state.app.widgets.filter((w) => w.id === id)).map((w) =>{
    return w.params.calculator ? w.params.alias : w.params.field
  });
  const parametersCatalog=useSelector((state)=>state.app.parametersCatalog);
  let localParametersCatalog = null;
  if (parametersCatalog?.length > 0) {
    localParametersCatalog = {};
    parametersCatalog.forEach(p => {
      if (column.includes(p.propName) || (filters && Object.keys(filters).length >0)){
        localParametersCatalog[p.propName] = p.value
      }
    });
  }
  const selectedCategories =
    useWidgetFilterValues({ dataSource, id, column: columnToFilter , type: FilterTypes.IN }) ||
    EMPTY_ARRAY;

  const {
    data = [],
    isLoading,
    warning
  } = useWidgetFetch(getCategories, {
    id,
    dataSource,
    params: {
      column,
      operationColumn,
      joinOperation,
      operation,
      parametersCatalog: localParametersCatalog
    },
    global,
    onError,
    enabled: wrapperProps.expand
  });
  const handleSelectedCategoriesChange = useCallback(
    (categories) => {
      if (categories && categories.length) {
        dispatch(
          addFilter({
            id: dataSource,
            column: columnToFilter,
            type: FilterTypes.IN,
            values: categories,
            owner: id,
          })
        );
      } else {
        dispatch(
          removeFilter({
            id: dataSource,
            column : columnToFilter
          })
        );
      }
    },
    [column, dataSource, id, dispatch]
  );

  return (
    <WrapperWidgetUI title={title} widgetId={id} isLoading={isLoading} {...wrapperProps} >
      <WidgetWithAlert
        dataSource={dataSource}
        warning={warning}
        global={global}
        droppingFeaturesAlertProps={droppingFeaturesAlertProps}
        noDataAlertProps={noDataAlertProps}
      >
        {(!!data.length || isLoading) && (
          <CategoryWidgetUI
            data={data}
            formatter={formatter}
            labels={labels}
            order={order}
            colorInMap={colorInMap}
            selectedCategories={selectedCategories}
            onSelectedCategoriesChange={handleSelectedCategoriesChange}
            animation={animation}
            filterable={filterable}
            searchable={searchable}
            color={props.color}
            widgetId={id}
            widgetType={type}
          />
        )}
      </WidgetWithAlert>
    </WrapperWidgetUI>
  );
}

CategoryWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  operationColumn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  joinOperation: columnAggregationOn('operationColumn'),
  operation: PropTypes.oneOf(Object.values(AggregationTypes)).isRequired,
  formatter: PropTypes.func,
  labels: PropTypes.object,
  animation: PropTypes.bool,
  filterable: PropTypes.bool,
  searchable: PropTypes.bool,
  global: PropTypes.bool,
  onError: PropTypes.func,
  wrapperProps: PropTypes.object,
  noDataAlertProps: PropTypes.object,
  droppingFeaturesAlertProps: PropTypes.object,
};

CategoryWidget.defaultProps = {
  labels: {},
  animation: true,
  filterable: true,
  searchable: true,
  global: false,
  wrapperProps: {},
  noDataAlertProps: {}
};

export default CategoryWidget;
